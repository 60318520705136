import React from "react";
import { Route, Switch } from "react-router-dom";

import AllComponents from "./views/AllComponents";
import Login from "./views/Login/Login";
import Logout from "./views/Login/components/Logout";

import Dashboard from "./views/Frontpage/Dashboard";

import PlacementsPage from "./views/Placements/PlacementsPage";
import ContentPage from "./views/Content/ContentPage";
import ContentEdit from "./views/Content/ContentEdit";

import PlacementsEdit from "./views/Placements/PlacementsEdit";

import OddsTipsPage from "./views/Oddstips/OddsTipsPage";
import CollectionsPage from "./views/Collections/CollectionsPage";
import CollectionsDetails from "./views/Collections/CollectionsDetails";
import TippetipsPage from "./views/Tippetips/TippetipsPage";
import TippetipsDetails from "./views/Tippetips/TippetipsDetails";

import LinksPage from "./views/Links/LinksPage";
import InfoPage from "./views/Info/InfoPage";
import HelpPage from "./views/Help/HelpPage";
import SettingsPage from "./views/Settings/SettingsPage";

import AdminPage from "./views/Admin/AdminPage/AdminPage";
import AdminUsers from "./views/Admin/AdminUsers/AdminUsers";
import AdminFrontpage from "./views/Admin/AdminFrontpage/AdminFrontpage";
import AdminFiles from "./views/Admin/AdminFiles/AdminFiles";
import FeedbackPage from "./views/Feedback/FeedbackPage";
import Placeholder from "./views/Placeholder";
import Rapports from "./views/Rapports/Rapports";

export default (props) => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/forgot" component={Login} />
    <Route exact path="/logout" component={Login} />

    <Route exact path="/feedback" component={FeedbackPage} />

    <Route exact path="/reset/:token" component={Login} />

    <Route exact path="/components" component={AllComponents} />

    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/placements" component={PlacementsPage} />
    <Route exact path="/placements/:contenttype" component={PlacementsPage} />

    <Route exact path="/content" component={ContentPage} />
    <Route exact path="/content/oddstips" component={OddsTipsPage} />
    <Route exact path="/collections/new" component={CollectionsDetails} />
    <Route exact path="/collections/:id" component={CollectionsDetails} />
    <Route exact path="/collections" component={CollectionsPage} />
    <Route exact path="/content/:contenttype" component={ContentPage} />
    <Route exact path="/content/edit/:id" component={ContentEdit} />
    <Route exact path="/content/new/:id" component={ContentEdit} />
    <Route exact path="/newplacement" component={PlacementsEdit} />
    <Route exact path="/newplacement/t/:id" component={PlacementsEdit} />
    <Route exact path="/newplacement/:id" component={PlacementsEdit} />
    <Route exact path="/placements/edit/:id" component={PlacementsEdit} />
    <Route exact path="/lenker" component={LinksPage} />
    <Route exact path="/newlink" component={Placeholder} />

    <Route exact path="/tippetips/new" component={TippetipsDetails} />
    <Route exact path="/tippetips/:id" component={TippetipsDetails} />
    <Route exact path="/tippetips" component={TippetipsPage} />

    <Route exact path="/hjelp" component={Placeholder} />
    <Route exact path="/innstillinger" component={Placeholder} />
    <Route exact path="/info" component={InfoPage} />
    <Route exact path="/help" component={HelpPage} />
    <Route exact path="/settings" component={SettingsPage} />

    <Route exact path="/admin" component={AdminPage} />
    <Route exact path="/admin/users" component={AdminUsers} />
    <Route exact path="/admin/front" component={AdminFrontpage} />
    <Route exact path="/admin/files" component={AdminFiles} />

    <Route exact path="/rapports" component={Rapports} />
  </Switch>
);
