import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  win: {
    minWidth: "800px"
  }
}));
export default function AddPartnerDialog(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          {"Legg til ny partner"}
        </DialogTitle>
        <DialogContent className={classes.win}>
          <TextField
            id="filled-select-fornavn"
            label="Navn på partner"
            className={classes.textField}
            value={props.values.newpartner}
            onChange={props.handleChange("newpartner")}
            margin="normal"
            variant="filled"
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Avbryt
          </Button>
          <Button
            onClick={props.handleClose}
            color="primary"
            autoFocus
            variant="contained"
            className={classes.button}
          >
            LAGRE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
