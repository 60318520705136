/* eslint-disable no-script-url */

import React, { Fragment } from "react";

import ElementItem from "../../../components/ElementItem";
import { makeStyles } from "@material-ui/core/styles";

import StatsContext from "../../../context/StatsContext";

const useStyles = makeStyles(theme => ({
  emptyMsg: {
    paddingLeft: 76 // keep right padding when drawer closed
  }
}));

export default function TableFrontpage(props) {
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;
  const classes = useStyles();
  return (
    <Fragment>
      {props.items.length < 1 && (
        <div className={classes.emptyMsg}>Her er det tomt.</div>
      )}
      {props.items.map(row => {
        let innhold = "";
        let plassering = "";
        let schedule = 0;
        if (!row.content) {
          plassering = row.title;
          innhold = row.placement;
        } else {
          plassering = row.placement;
          innhold = row.content[0].name;
          schedule = row.content.length;
        }

        let clicks = 0;
        let clicks_trend = "";
        let displays = 0;
        let displays_trend = "";
        let ctr = 0;
        let ctr_trend = "";

        if (row.stats[statsInterval]) {
          clicks = row.stats[statsInterval].clicks;
          clicks_trend = row.stats[statsInterval].clicks_trend;
          displays = row.stats[statsInterval].views;
          displays_trend = row.stats[statsInterval].views_trend;
          ctr = row.stats[statsInterval].ctr;
          ctr_trend = row.stats[statsInterval].ctr_trend;
        }

        return (
          <ElementItem
            key={row.id}
            type={row.type}
            placement={plassering}
            content={innhold}
            contentNr={schedule}
            clicknr={clicks}
            clicks_trend={clicks_trend}
            ctr={ctr}
            ctr_trend={ctr_trend}
            displays={displays}
            displays_trend={displays_trend}
            tag={row.tag}
            url={props.editurl}
            preview_url={row.url}
            id={row.id}
            favourite={row.favourite}
            zeroMinWidth
            handleDelete={props.handleDelete}
            handleDeleteLink={props.handleDeleteLink}
            handleOpenDialog={props.handleOpenDialog}
            addToFavorites={props.addToFavorites}
          />
        );
      })}
    </Fragment>
  );
}
