import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ArrowDropUp";
import ExpandMore from "@material-ui/icons/ArrowDropDown";
import AccountCircle from "@material-ui/icons/AccountCircleOutlined";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import { withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import StarIconOutlined from "@material-ui/icons/GradeOutlined";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import ContentIcon from "@material-ui/icons/TextFields";
import SportIcon from "@material-ui/icons/Rowing";
import LotteryIcon from "@material-ui/icons/MonetizationOn";
import InstaGameIcon from "@material-ui/icons/TouchApp";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import LinkIcon from "@material-ui/icons/Link";
import HelpOutlined from "@material-ui/icons/HelpOutline";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import AdminIcon from "@material-ui/icons/GroupOutlined";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

import NTIcon from "../icons/NT";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "198px",
    maxWidth: 360,
    backgroundColor: "transparent",
    marginLeft: theme.spacing(1),
  },
  container: {
    marginTop: 40,
    [theme.breakpoints.down("md")]: {
      /* display: "none"*/
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: "transparent",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    borderRight: "none",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: "61px",
      overflowX: "hidden",
    },
    [theme.breakpoints.down("sm")]: {
      width: "240px",
      overflowX: "hidden",
      position: "fixed",
      backgroundColor: "#FFFFFF",
      zIndex: 2000,
    },
  },
  drawerPaperClose: {
    backgroundColor: "transparent",
    overflowX: "hidden",
    borderRight: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: "61px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "0",
    },
  },
  submenu: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

function LeftMenu(props) {
  const classes = useStyles();
  const [openContent, setOpen] = React.useState(false);

  const [openSpecialContent, setOpenSpecialContent] = React.useState(false);

  const [openAdmin, setOpenAdmin] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");

  function handleClick() {
    setOpen(!openContent);
    props.history.push("/content");
  }

  function handleClickAdmin() {
    setOpenAdmin(!openAdmin);
    props.history.push("/admin");
  }

  function handleClickSpecialContent() {
    setOpenSpecialContent(!openSpecialContent);
    // props.history.push("/content/oddstips");
  }

  function handleMobileClick() {
    if (isMobile) {
      props.setOpenMobile(false);
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.appBarSpacer} />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !props.isOpen && classes.drawerPaperClose
          ),
        }}
        open={props.isOpen}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem
            selected={props.currentPath === "/dashboard"}
            component={Link}
            to={{ pathname: "/dashboard" }}
            onClick={handleMobileClick}
            button
          >
            <ListItemIcon>
              <StarIconOutlined />
            </ListItemIcon>
            <ListItemText primary="Forsiden" />
          </ListItem>
          <ListItem
            selected={props.currentPath.indexOf("/placements") !== -1}
            component={Link}
            to={{ pathname: "/placements" }}
            button
            onClick={handleMobileClick}
          >
            <ListItemIcon>
              <PlacementIcon />
            </ListItemIcon>
            <ListItemText primary="Plasseringer" />
          </ListItem>
          <ListItem
            button
            selected={props.currentPath === "/content"}
            onClick={handleClick}
          >
            <ListItemIcon>
              <ContentIcon />
            </ListItemIcon>
            <ListItemText primary="Innhold" />
            {openContent ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openContent} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.submenu}>
              <ListItem
                selected={props.currentPath === "/content/generell"}
                component={Link}
                to={{ pathname: "/content/generell" }}
                onClick={handleMobileClick}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <NTIcon fillcolor={"#222"} />
                </ListItemIcon>
                <ListItemText primary="Generell" />
              </ListItem>
              <ListItem
                selected={props.currentPath === "/content/sport"}
                component={Link}
                to={{ pathname: "/content/sport" }}
                onClick={handleMobileClick}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <SportIcon />
                </ListItemIcon>
                <ListItemText primary="Sport" />
              </ListItem>
              <ListItem
                selected={props.currentPath === "/content/lotteri"}
                component={Link}
                to={{ pathname: "/content/lotteri" }}
                onClick={handleMobileClick}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <LotteryIcon />
                </ListItemIcon>
                <ListItemText primary="Lotteri" />
              </ListItem>
              <ListItem
                selected={props.currentPath === "/content/instaspill"}
                component={Link}
                to={{ pathname: "/content/instaspill" }}
                onClick={handleMobileClick}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <InstaGameIcon />
                </ListItemIcon>
                <ListItemText primary="Instaspill" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={handleClickSpecialContent}>
            <ListItemIcon>
              <ViewCompactIcon />
            </ListItemIcon>
            <ListItemText primary="Sider" />
            {openSpecialContent ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openSpecialContent} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.submenu}>
              <ListItem
                selected={props.currentPath === "/content/oddstips"}
                component={Link}
                to={{ pathname: "/content/oddstips" }}
                onClick={handleMobileClick}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <NTIcon fillcolor={"#222"} />
                </ListItemIcon>
                <ListItemText primary="Oddstips" />
              </ListItem>
              <ListItem
                selected={props.currentPath === "/collections"}
                component={Link}
                to={{ pathname: "/collections" }}
                onClick={handleMobileClick}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <FolderOutlinedIcon fillcolor={"#222"} />
                </ListItemIcon>
                <ListItemText primary="Samlesider" />
              </ListItem>

              <ListItem
                selected={props.currentPath === "/tippetips"}
                component={Link}
                to={{ pathname: "/tippetips" }}
                onClick={handleMobileClick}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <SportsSoccerIcon fillcolor={"#222"} />
                </ListItemIcon>
                <ListItemText primary="Tippetips" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            selected={props.currentPath === "/lenker"}
            component={Link}
            to={{ pathname: "/lenker" }}
            onClick={handleMobileClick}
            button
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Lenker" />
          </ListItem>
          <Divider />
          <ListItem
            selected={props.currentPath === "/help"}
            component={Link}
            to={{ pathname: "/help" }}
            onClick={handleMobileClick}
            button
          >
            <ListItemIcon>
              <HelpOutlined />
            </ListItemIcon>
            <ListItemText primary="Hjelp" />
          </ListItem>
          <ListItem
            selected={props.currentPath === "/settings"}
            component={Link}
            to={{ pathname: "/settings" }}
            onClick={handleMobileClick}
            button
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Innstillinger" />
          </ListItem>
          <ListItem
            selected={props.currentPath === "/info"}
            component={Link}
            to={{ pathname: "/info" }}
            onClick={handleMobileClick}
            button
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Info" />
          </ListItem>
          {props.ver2 && (
            <>
              <ListItem
                selected={props.currentPath === "/admin"}
                component={Link}
                to={{ pathname: "/admin" }}
                button
                onClick={handleClickAdmin}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Admin" />
                {openAdmin ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    selected={props.currentPath === "/admin/front"}
                    component={Link}
                    to={{ pathname: "/admin/front" }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <StarIconOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Forside" />
                  </ListItem>
                  <ListItem
                    selected={props.currentPath === "/admin/files"}
                    component={Link}
                    to={{ pathname: "/admin/files" }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Retningslinjer" />
                  </ListItem>
                  <ListItem
                    selected={props.currentPath === "/admin/users"}
                    component={Link}
                    to={{ pathname: "/admin/users" }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <AdminIcon />
                    </ListItemIcon>
                    <ListItemText primary="Brukere" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          <ListItem
            component={Link}
            to={{ pathname: "/feedback" }}
            button
            onClick={handleMobileClick}
          >
            <ListItemIcon>
              <FeedbackOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Tilbakemelding" />
          </ListItem>

          <ListItem
            component={Link}
            to={{ pathname: "/logout" }}
            button
            onClick={handleMobileClick}
          >
            <ListItemIcon>
              <AdminIcon />
            </ListItemIcon>
            <ListItemText primary="Logg ut" />
          </ListItem>
          {props.ver2 && (
            <ListItem
              component={Link}
              to={{ pathname: "/rapports" }}
              selected={props.currentPath === "/rapports"}
              button
              onClick={handleMobileClick}
            >
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary="Rapport" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </div>
  );
}
export default withRouter(LeftMenu);
