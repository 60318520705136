/* eslint-disable no-script-url */

import React from "react";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  iconStyles: {
    verticalAlign: "middle",
  },
  smallIcon: {
    verticalAlign: "middle",
    width: 16,
    height: 16,
  },
  noPadd: {
    padding: "0 !important",
    color: palette.common.white,
    borderBottom: "none",
    verticalAlign: "middle",
    fontWeight: 600,
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  bigIcon: {
    width: 24,
    height: 24,
  },
  bigIconBlack: {
    width: 24,
    height: 24,
    color: palette.common.black,
  },
}));

export default function FormTextItem(props) {
  const classes = useStyles();
  const item = props.item;

  return (
    <>
      {!props.hideGrid && (
        <Grid item xs={12} sm={4} key={item.name}>
          <TextField
            id={"filled-" + item.name}
            label={item.label}
            className={classes.textField}
            value={props.value}
            onChange={props.onChange(item.paramname)}
            margin="normal"
            variant="filled"
            disabled={props.isDisabled}
          />
        </Grid>
      )}
      {props.hideGrid && (
        <TextField
          id={"filled-" + item.name}
          label={item.label}
          className={classes.textField}
          value={props.value}
          onChange={props.onChange(item.paramname)}
          margin="normal"
          variant="filled"
          disabled={props.isDisabled}
        />
      )}
    </>
  );
}
