// Palette
import palette from '../palette';

export default {
  root: {},
  colorPrimary: {
    backgroundColor: palette.primary.main
  },
  colorSecondary: {
    backgroundColor: palette.primary.main
  }
};
