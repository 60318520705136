/* eslint-disable no-script-url */

import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";

import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import Button from "@material-ui/core/Button";

import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";

import palette from "../../../theme/palette";
const useStyles = makeStyles(theme => ({
  /* seeMore: {
    marginTop: theme.spacing(3),
  },*/
  textField: {
    width: "100%"
  },
  formContainer: {
    padding: "0px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0)
    }
  },
  loginBtn: {
    width: "100%",
    marginTop: "4.5rem"
  },
  links: {
    marginTop: "4rem",
    display: "flex",
    justifyContent: "center"
  },
  boldLink: {
    fontWeight: 600,
    color: palette.primary.dark
  },
  errormsg: {
    textAlign: "center",
    color: palette.error.main
  }
}));

function LoginForgotPassword(props) {
  const classes = useStyles();

  const [error, setError] = React.useState("");
  const [mailStatus, setMailStatus] = React.useState(null);
  const [values, setValues] = React.useState({
    email: ""
  });

  const [
    { data: loginData, loading: isLoading, error: isError },
    doLogin
  ] = useAxios(
    {
      url: "https://nt-ekom-2.herokuapp.com/user/requestpassword",
      method: "POST"
    },
    { manual: true }
  );

  const handleSubmit = e => {
    doLogin({
      data: { ...values }
    });

    //props.history.push('/dashboard');
  };

  useEffect(() => {
    if (!isLoading && loginData && loginData.status === "OK") {
      setMailStatus(true);
    } else if (loginData && loginData.status === "EMAIL_NOT_FOUND") {
      setError("Finner ikke bruker");
    }
  }, [isLoading, loginData]);

  const handleChange = name => event => {
    setError("");
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <React.Fragment>
      <Typography variant="h2" component="h2" align="center" gutterBottom>
        Glemt passord
      </Typography>
      {!mailStatus && (
        <>
          <Typography variant="body2" align="center" gutterBottom>
            Skriv inn din epostadresse, så sender vi deg ett nytt passord.
          </Typography>
          <ValidatorForm
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
            className={classes.formContainer}
            autoComplete="off"
          >
            <TextValidator
              id="email"
              label="Brukernavn"
              className={classes.textField}
              value={values.email}
              onChange={handleChange("email")}
              margin="normal"
              variant="filled"
              validators={["required", "isEmail"]}
              errorMessages={[
                "dette feltet er obligatorisk",
                "e-posten er ikke gyldig"
              ]}
            />

            <Button
              variant="contained"
              className={classes.loginBtn}
              type="submit"
            >
              SEND NYTT PASSORD
            </Button>

            <div className={classes.links}>
              <Link
                component={RouterLink}
                variant="body2"
                className={classes.boldLink}
                to="/"
              >
                Logg inn
              </Link>
            </div>
          </ValidatorForm>
        </>
      )}
      {mailStatus && (
        <>
          <Typography variant="body2" align="center" gutterBottom>
            Vi har sendt deg mail med en lenke. Vennligst sjekk mailboksen din.
          </Typography>
          <div className={classes.links}>
            <Link
              component={RouterLink}
              variant="body2"
              className={classes.boldLink}
              to="/"
            >
              Logg inn
            </Link>
          </div>
        </>
      )}
      <div className={classes.errormsg}>{error}</div>
    </React.Fragment>
  );
}
export default withRouter(LoginForgotPassword);
