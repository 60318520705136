import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import palette from "../../../theme/palette";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  },
  odds: {
    padding: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    marginTop: "6px",
    marginBottom: "6px",
    border: "1px solid",
    minWidth: "54px",
    textAlign: "center",
    cursor: "pointer"
  },
  selectedodds: {
    padding: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    border: "1px solid",
    minWidth: "54px",
    textAlign: "center",
    color: palette.common.white,
    backgroundColor: "#0069E2",
    cursor: "pointer"
  },
  icons: {
    width: "80%",
    height: "80%",
    margin: "10%"
  },
  noBreak: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

function OddsChooser(props) {
  let oddsArray = props.chosenValues;

  function markSelected(value) {
    props.updateOdds(value.eventId,
      value.marketId,
      value.selectionId);
  }

  function translateHUB(val){
    if(val==="D"){
      return "U";
    }else if(val==="A"){
      return "B";
    }
    return val;
  }

  const classes = useStyles();

  function renderHUB(value, clsArr) {
    return (
      <div
        key={value.selectionId}
        className={clsx(clsArr.join(", "))}
        onClick={markSelected.bind(null, value)}
      >
           {translateHUB(value.selectionValue)} {parseFloat(value.selectionOdds).toFixed(2)}
      </div>
    );
  }

  function renderVinner(value, clsArr) {
    return (
      <div
        className={classes.noBreak}
        key={value.selectionId}
        onClick={markSelected.bind(null, value)}
      >
        {value.selectionShortName}
        <div className={clsx(clsArr.join(", "))}>
          {parseFloat(value.selectionOdds).toFixed(2)}
        </div>
      </div>
    );
  }

  function renderChooser(value, clsArr) {

    if (
      props.betType === "MONEY" && (
      value.selectionValue === "H" ||
      value.selectionValue === "D" ||
      value.selectionValue === "A"
      )
    ) {

      return renderHUB(value, clsArr);
    } else {
      return renderVinner(value, clsArr);
    }
  }

  return (
    <div className={classes.container}>
      {oddsArray &&
        oddsArray.map((value, index) => {
          const clsArr = [];

          if (props.selectionId == value.selectionId) {
            clsArr.push(classes.selectedodds);
          } else {
            clsArr.push(classes.odds);
          }

          return renderChooser(value, clsArr);
        })}
    </div>
  );
}

export default OddsChooser;
