import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import LeftMenu from "../components/LeftMenu";
import AppBarTop from "../components/AppBar";
import StatsContext from "../context/StatsContext";
import { useCookies } from "react-cookie";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";
const Layout = (props) => {
  const currentPath = props.location.pathname;

  const isMobile = useMediaQuery("(max-width:767px)");

  const isOpen = isMobile ? false : true;

  const [cookies, setCookie, removeCookie] = useCookies(["sid"]);
  const startInt = cookies.timeint ? cookies.timeint : 24;
  const [statsInterval, setStatsInterval] = useState(startInt);

  const [sesKey, setSesKey] = useState(cookies.sid);
  const [partnerName, setPartnerName] = useState(cookies.pname);
  const [partnerCode, setPartnerCode] = useState(cookies.pcode);
  const [partnerId, setPartnerId] = useState(cookies.pid);

  const [logTime, setLogTime] = useState(null);

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isMobile]);

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  function handleMobileOpenClose() {
    if (open && isMobile) {
      setOpen(false);
    }
  }

  const useStyles = makeStyles((theme) => ({
    root: {},
    main: {
      display: "flex",
    },
  }));

  function hasExpired() {
    if (
      cookies &&
      !cookies.sid &&
      props.location.pathname &&
      props.location.pathname !== "/" &&
      props.location.pathname !== "/logout" &&
      props.location.pathname !== "/forgot" &&
      props.location.pathname.indexOf("/reset/") === -1
    ) {
      props.history.push("/");
    }
  }

  hasExpired();

  function setOpenMobile() {
    setOpen();
  }

  function updateSid(val) {
    hasExpired();
    setSesKey(val);
    updateCookie(val);
  }

  function addMoreMin() {
    var dt = new Date();
    dt.setMinutes(dt.getMinutes() + 120);
    setLogTime(dt);
    return dt;
  }
  function updatePartner(val) {
    hasExpired();
    setPartnerName(val);
    setCookie("pname", val, { path: "/", expires: addMoreMin() });
  }

  function updatePartnerId(val) {
    hasExpired();
    setPartnerId(val);
    console.log("COOKIE", val);
    setCookie("pid", val, { path: "/", expires: addMoreMin() });
  }

  function updatePartnerCode(val) {
    hasExpired();
    setPartnerCode(val);
    setCookie("pcode", val, { path: "/", expires: addMoreMin() });
  }

  function updateTime(val) {
    hasExpired();
    setStatsInterval(val);
    setCookie("timeint", val, { path: "/", expires: addMoreMin() });
  }
  function updateCookie(val) {
    hasExpired();
    setCookie("sid", val, { path: "/", expires: addMoreMin() });
  }

  function deleteCookie() {
    removeCookie("sid", { path: "/" });
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StatsContext.Provider
        value={{
          state: statsInterval,
          updateValue: setStatsInterval,
          sid: sesKey,
          updateTime: updateTime,
          updateSID: updateSid,
          updatePartner: updatePartner,
          updatePartnerCode: updatePartnerCode,
          updatePartnerId: updatePartnerId,
          partnerName: partnerName,
          partnerCode: partnerCode,
          partnerId: partnerId,
          deleteCookie: deleteCookie,
        }}
      >
        <AppBarTop
          path={currentPath}
          handleDrawerOpenClose={handleDrawerOpenClose}
          handleMobileOpenClose={handleMobileOpenClose}
        />
        <div className={classes.main}>
          {currentPath !== "/" &&
            currentPath !== "/logout" &&
            currentPath !== "/components" &&
            currentPath.indexOf("/reset/") === -1 &&
            currentPath !== "/forgot" && (
              <LeftMenu
                currentPath={currentPath}
                isOpen={open}
                setOpenMobile={setOpenMobile}
              />
            )}
          {props.children}
        </div>
      </StatsContext.Provider>
    </div>
  );
};

export default withRouter(Layout);
