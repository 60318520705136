import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  chip: {
    margin: theme.spacing(1)
  }
}));

export default function SmallChips() {
  const classes = useStyles();

  function handleClick() {
    alert("You clicked the Chip.");
  }

  return (
    <div className={classes.root}>
      <Typography variant="h1" component="h2" gutterBottom>
        Tags
      </Typography>

      <Chip
        label="Aktiv"
        className={classes.chip}
        onClick={handleClick}
        color="primary"
      />
      <Chip label="Inaktiv" className={classes.chip} color="secondary" />
    </div>
  );
}
