export default {
  root: {
    borderBottom: '1px solid #CCCCCC',
    padding: '10px 40px 10px 16px'
  },
  head: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#222222'
  },
  footer: {
    borderBottom: 'none'
  }
};
