import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import LinksListHolder from "./components/LinksListHolder";
import StatsContext from "../../context/StatsContext";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";
import OddsProgrammet from "./components/OddsProgrammet";
import MobileNotSupported from "../../components/MobileNotSupported";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  circleProg: {
    marginTop: "48px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

function OddsTipsPage(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: "",
    title: "",
    id: "",
    url: "",
    itemId: "",
    placement: "",
    placement_id: "",
    placement_type: "",
    placement_source: "",
    placement_medium: "",
    placement_code: "",
    placement_campaign: "",
    product: "",
    extras: "",
    campaign: "",
    isValidated: 0,
    finalURL: "",
    gameURL: "",
    leaguesURL: "",
    errors: [],
  });

  const [linksData, setLinksData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert!");
  const [snackVar, setSnackVar] = React.useState("success");
  const isMobile = useMediaQuery("(max-width:769px)");

  const statsRange = React.useContext(StatsContext);
  function handleClose(event) {
    setOpen(false);
  }

  function showAlert(msg, msgtype) {
    setSnackVar(msgtype);
    setSnackMsg(msg);
    setOpen(true);
  }

  const updateGameURL = (gameURL) => {
    /* if (props.values.gameURL === gameURL) return;
    props.setValues({
      ...props.values,
      gameURL: gameURL,
    });
    */
  };

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={0}>
          {!isMobile && <OddsProgrammet updateGameURL={updateGameURL} />}
          {isMobile && <MobileNotSupported />}
        </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}

export default withRouter(OddsTipsPage);
