// Palette
import palette from '../palette';

export default {
  root: {
    padding: '10px 16px',
    borderRadius: '3px'
  },
  text: {
    textTransform: 'uppercase',
    color: palette.primary.dark
  },
  textPrimary: {
    textTransform: 'uppercase',
    color: palette.primary.dark,
    '&:disabled': {
      color: palette.greys.middle
    }
  },
  textSecondary: {
    textTransform: 'uppercase',
    color: palette.text.primary,
    '&:disabled': {
      color: palette.greys.middle
    }
  },
  contained: {
    textTransform: 'uppercase',
    backgroundColor: palette.primary.dark,
    '&:hover': {
      backgroundColor: palette.primary.dark
    },

    color: palette.common.white
  },
  containedPrimary: {
    backgroundColor: palette.primary.dark,
    '&:hover': {
      backgroundColor: palette.primary.dark
    },
    '&:disabled': {
      backgroundColor: palette.background.disabled,
      color: palette.greys.middle
    },
    color: palette.common.dark
  },
  containedSecondary: {
    padding: '8px 14px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: palette.background.secondary
    },

    border: '1px solid ' + palette.greys.middle,
    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.greys.middle
    },
    color: palette.primary.dark
  },
  outlinedPrimary: {
    color: palette.text.primary,
    border: '1px solid ' + palette.greys.light,
    padding: '9px 16px',
    '&:hover': {
      color: 'inherit',
      border: '1px solid ' + palette.greys.light,
      padding: '9px 16px'
    }
  }
};
