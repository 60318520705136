import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import palette from '../../../../theme/palette';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  win: {
    minWidth: '800px'
  },
  buttonUpload: {
    marginTop: theme.spacing(2),
    alignSelf: 'flex-end',
    color: palette.common.white,
    backgroundColor: palette.secondary.main
  }
}));
export default function AddFilesDialog(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">{'Legg til ny'}</DialogTitle>
        <DialogContent className={classes.win}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonUpload}
          >
            LAST OPP FIL
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Avbryt
          </Button>
          <Button
            onClick={props.handleClose}
            color="primary"
            autoFocus
            variant="contained"
            className={classes.button}
          >
            LAGRE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
