/* eslint-disable no-script-url */

import React, { Fragment } from "react";
import TablePagination from "@material-ui/core/TablePagination";

import ElementItem from "../../../components/ElementItem";
import StatsContext from "../../../context/StatsContext";

export default function TablePlacements(props) {
  const rows = props.rows;

  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;

  return (
    <Fragment>
      {rows
        .slice(
          props.page * props.rowsPerPage,
          props.page * props.rowsPerPage + props.rowsPerPage
        )
        .map(row => {
          const statObj = row.stats[statsInterval];

          const sClicks = statObj ? statObj.clicks : 0;
          const sClicksTrend = statObj ? statObj.clicks_trend : "";
          const sCtr = statObj ? statObj.ctr : 0;
          const sCtrTrend = statObj ? statObj.ctr_trend : "";
          const sViews = statObj ? statObj.views : 0;
          const sViewsTrend = statObj ? statObj.views_trend : 0;

          return (
            <ElementItem
              key={row.id}
              type={row.type}
              placement={row.placement}
              content={row.content[0].name}
              contentNr={row.content.length}
              clicknr={sClicks}
              clicks_trend={sClicksTrend}
              ctr={sCtr}
              ctr_trend={sCtrTrend}
              displays={sViews}
              displays_trend={sViewsTrend}
              tag={row.tag}
              url="/placements/edit/"
              preview_url={row.url}
              id={row.id}
              favourite={row.favourite}
              zeroMinWidth
              handleDelete={props.handleDelete}
              handleOpenDialog={props.handleOpenDialog}
              addToFavorites={props.addToFavorites}
            />
          );
        })}

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        backIconButtonProps={{
          "aria-label": "forrige side"
        }}
        nextIconButtonProps={{
          "aria-label": "neste side"
        }}
        labelRowsPerPage="Antall per side:"
        onChangePage={props.handleChangePage}
        onChangeRowsPerPage={props.handleChangeRowsPerPage}
      />
    </Fragment>
  );
}
