import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import OddsChooser from "./OddsChooser";
import ExpandMore from "@material-ui/icons/ArrowDropDown";
import ExpandLess from "@material-ui/icons/ArrowDropUp";
import moment from "moment";
import { marketNamesArr } from "../../../common/consts";
import TextField from "@material-ui/core/TextField";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Start",
    sortable: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Match",
    sortable: true,
  },
  {
    id: "sportname",
    numeric: false,
    disablePadding: false,
    label: "Sport",
    sortable: true,
  },
  { id: "Type", numeric: false, disablePadding: false, label: "Type" },
  { id: "Utfall", numeric: false, disablePadding: false, label: "Utfall" },
  { id: "arrow", numeric: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={
              headCell.sortable && orderBy === headCell.id ? order : false
            }
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Tooltip title="Filter list" align="right">
        <TextField
          id="filled-name"
          label="Filter"
          className={classes.textField}
          value={props.filterText}
          onChange={props.handleFilterText}
          margin="normal"
          variant="filled"
        />
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "24px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  betsCell: {
    maxWidth: "400px",
    minWidth: "240px",
    paddingRight: "0px",
  },
  arrowEx: {
    width: "15px",
    cursor: "pointer",
  },
  hasCursor: {
    cursor: "pointer",
  },
  hideRow: {
    display: "none",
  },
  subCells: {
    backgroundColor: "rgba(0, 0, 0, 0.07)",
  },
  isDisabled: {
    opacity: 0.2,
  },
  noCursor: {
    cursor: "default",
  },
}));

export default function OddsProgramTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("time");
  const [selected, setSelected] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [showMatches, setShowMatches] = React.useState([]);


  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.eventsData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function translateMarketName(val){
    if(marketNamesArr[val] ){
      return marketNamesArr[val];
    }
    return val;
  }

  function matchWord(item) {
    if(!item) return;
    return item.toLowerCase().indexOf(filterText.toLowerCase()) != -1;
  }
  function matchDate(item) {
    return (
      moment(item)
        .format("DD.MM.YY HH:mm")
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) != -1
    );
  }
  function filterRows(arr) {
    if (arr.length > 0) {
      const filteredArray = arr.filter(function (item) {
        return (
          matchWord(item.eventName) ||
          matchWord(item.sportName) ||
          matchDate(item.startTime) ||
          filterText === ""
        );
      });

      return filteredArray;
    }
  }

  const handleClick = (event, name) => {
    if (props.editMode) {
      return;
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function showHideMatches(id) {
    setShowMatches(id);
  }
  function chooseWObet(e, eventId, marketId) {
    if (props.editMode) {
      return;
    }

    props.updateOdds(eventId, marketId, 4000, props.id, true);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function renderSubTable(tablerow) {

    const TblRow = tablerow.markets.map((bet, index) => {
      const labelId = `enhanced-table-checkbox-${index}`;
      const clsArr = [classes.betsCell];
      if (
        tablerow.markets.length > 1 &&
        index > 0 &&
        showMatches !== tablerow.eventId
      ) {
        return;
      }
      if (index > 0 || showMatches === tablerow.eventId) {
        clsArr.push(classes.subCells);
      }
      //var eventMatch = tablerow.homeParticipant + " - " + tablerow.awayParticipant;
      var eventMatch = tablerow.eventName;

      if (eventMatch.indexOf(filterText) === "-1") {
        return;
      }
      const mouseCursor = props.editMode ? classes.noCursor : classes.hasCursor;
      return (
        <TableRow
          hover
          onClick={(event) => handleClick(event, tablerow.startTime)}
          role="checkbox"
          tabIndex={-1}
          key={labelId}
          className={clsx(clsArr.join(", "))}
        >
          <TableCell
            id={labelId}
            scope="row"
            onClick={(event) => chooseWObet(event, tablerow.eventId, bet.marketId)}
            className={mouseCursor}
          >
            {moment(tablerow.startTime).format("DD.MM.YY HH:mm")}
          </TableCell>
          <TableCell
            align="left"
            onClick={(event) => chooseWObet(event, tablerow.eventId, bet.marketId)}
            className={mouseCursor}
          >
            {tablerow.eventName}
          </TableCell>
          <TableCell
            align="left"
            onClick={(event) => chooseWObet(event, tablerow.eventId, bet.marketId)}
            className={mouseCursor}
          >
            {tablerow.sportName}
          </TableCell>
          <TableCell
            align="left"
            onClick={(event) => chooseWObet(event, tablerow.eventId, bet.marketId)}
            className={mouseCursor}
          >
            {translateMarketName(bet.marketName)}
          </TableCell>
          <TableCell align="left" className={classes.betsCell}>
            <OddsChooser
              key={bet.selectionId}
              chosenValues={bet.selections}
              id={tablerow.eventId}
              selectionId={bet.selectionId}
              eventId={tablerow.eventId}
              name={bet.marketName}
              betType={bet.marketLineType}
              updateOdds={props.updateOdds}
              editMode={props.editMode}
            />
          </TableCell>

          <TableCell align="left" className={classes.arrowEx}>
            {tablerow.markets.length > 1 &&
              showMatches != tablerow.eventId && (
                <ExpandMore
                  onClick={(e) => showHideMatches(tablerow.eventId)}
                />
              )}

            {tablerow.markets.length > 1 &&
              showMatches === tablerow.eventId &&
              index === 0 && <ExpandLess onClick={(e) => showHideMatches(0)} />}
          </TableCell>
        </TableRow>
      );
    });
    return TblRow;
  }

  function handleFilterText(e) {
    setFilterText(e.target.value);
  }
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.eventsData.length - page * rowsPerPage);

  const disablClass = props.editMode ? classes.isDisabled : "";

  return (
    <div className={classes.root}>
      <Paper className={clsx(classes.paper, disablClass)}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          filterText={filterText}
          handleFilterText={handleFilterText}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.eventsData.length}
            />
            <TableBody>
              {stableSort(
                filterRows(props.eventsData),
                getSorting(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const Tbl = renderSubTable(row);
                  return Tbl;
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={props.eventsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Antall per side:"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
