// Palette
import palette from "../palette";

export default {
  root: {
    fontSize: "1.4rem",
    lineHeight: "1.3",
    color: palette.common.black,
    textAlign: "left",
  },

  selectMenu: {},
};
