/* eslint-disable no-script-url */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";

import NTIcon from "../../../icons/NT";
const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  cardBig: {
    position: "relative",
    boxShadow: "none",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
    },
  },
  mediaBig: {
    minHeight: 286,
    [theme.breakpoints.down("sm")]: {
      minHeight: 225,
    },
  },
  absHeader: {
    position: "absolute",
    backgroundColor: "rgba(38, 20, 64, 0.8)",
    width: "100%",
    zIndex: 100,
  },
  subheader: {
    fontSize: "1.6rem",
  },
  avatar: {
    backgroundColor: "transparent",
  },
}));

export default function DashInfoBig() {
  const classes = useStyles();
  return (
    <Card className={classes.cardBig}>
      <CardHeader
        classes={{
          subheader: classes.subheader,
        }}
        avatar={
          <Avatar className={classes.avatar}>
            <NTIcon fillcolor={"#fff"} />
          </Avatar>
        }
        className={classes.absHeader}
        subheader="Ny kom-igang modul"
      />
      <CardActionArea>
        <CardMedia
          className={classes.mediaBig}
          image="./dummy-big.png"
          title="Contemplative Reptile"
        />
      </CardActionArea>
    </Card>
  );
}
