import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ContentIcon from '@material-ui/icons/TextFields';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import palette from '../../theme/palette';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  bigIcon: {
    verticalAlign: 'middle',
    width: 24,
    height: 24,
    color: palette.text.secondary
  },
  bigIndicator: {
    height: 4
  },
  tabHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px'
  },
  tabHeadingText: {
    marginLeft: '16px'
  }
});

export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div>
      <Typography variant="h1" component="h2" gutterBottom>
        Tabs
      </Typography>
      <Paper className={classes.root}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          classes={{ indicator: classes.bigIndicator }}
          centered
        >
          <Tab
            label={
              <>
                <div className={classes.tabHeading}>
                  <ContentIcon />
                  <span className={classes.tabHeadingText}>
                    Egendefinert innhold
                  </span>
                </div>
              </>
            }
            variant="fullWidth"
          />
          <Tab
            label={
              <>
                <div className={classes.tabHeading}>
                  <AddIcon />
                  <span className={classes.tabHeadingText}>
                    Lag nytt innhold
                  </span>
                </div>
              </>
            }
            variant="fullWidth"
          />
        </Tabs>
      </Paper>
    </div>
  );
}
