import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinksChooseCat from "./LinksChoseCat";
import LinksTracking from "./LinksTracking";
import LinksCode from "./LinksCode";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepperRoot: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: "#F6F6F6"
    }
  },
  stepLabel: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  stepContent: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px"
    }
  }
}));

function getStepContent(props) {
  switch (props.activeStep) {
    case 0:
      return (
        <LinksChooseCat values={props.values} setValues={props.setValues} />
      );
    case 1:
      return (
        <>
          <LinksTracking values={props.values} setValues={props.setValues} />
        </>
      );
    case 2:
      return (
        <LinksCode
          values={props.values}
          setValues={props.setValues}
          saveNewRow={props.saveNewRow}
        />
      );
    default:
      return "En feil har oppstått";
  }
}

export default function LinksStepper(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={props.activeStep} className={classes.stepperRoot}>
        {props.steps.map(label => (
          <Step key={label}>
            <StepLabel className={classes.stepLabel}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container spacing={0} className={classes.stepContent}>
        {props.activeStep === props.steps.length ? (
          <div>
            <Typography className={classes.instructions}>Ferdig</Typography>
          </div>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={0}>
              {getStepContent(props)}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
