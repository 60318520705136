import React, { useState } from "react";

import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles(theme => ({
  /* seeMore: {
    marginTop: theme.spacing(3),
  },*/
  textField: {
    width: "100%"
  },
  passwordHolder: {
    position: "relative"
  },
  showHideIcon: {
    position: "absolute",
    top: "20px",
    right: "10px"
  }
}));
function PasswordShowHide(props) {
  const classes = useStyles();

  const [values, setValues] = useState({
    hidden: true,
    password: props.password
  });

  function toggleShow(e) {
    e.preventDefault();
    setValues({ ...values, hidden: !values.hidden });
  }

  return (
    <div className={classes.passwordHolder}>
      <TextValidator
        id="password"
        label={props.title}
        className={classes.textField}
        value={props.password}
        onChange={props.onChange}
        margin="normal"
        variant="filled"
        type={values.hidden ? "password" : "text"}
        validators={["required"]}
        errorMessages={["dette feltet er obligatorisk"]}
      />

      <IconButton onClick={toggleShow} className={classes.showHideIcon}>
        {values.hidden === true ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </div>
  );
}

export default PasswordShowHide;
