import React from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { withRouter } from "react-router-dom";

import ContentIcon from "@material-ui/icons/TextFields";
import palette from "../../../theme/palette";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles(theme => ({
  cardMedium: {
    display: "flex",
    marginLeft: "8px",
    marginRight: "8px",
    marginBottom: "16px",
    flexDirection: "column",
    justifyContent: "space-between",

    "&:nth-child(3n-1)": {
      marginLeft: "calc(2% - 3px)",
      marginRight: "calc(2% - 3px)"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginRight: "0px"
    }
  },
  mediaMedium: {
    height: 204,
    opacity: 0.9,
    backgroundColor: "#311856"
  },

  cardList: {
    maxWidth: 1083
  },
  cardListActions: {
    padding: 0
  },
  cardListIconSmall: {
    marginRight: "5px",
    color: palette.text.secondary,
    width: 16,
    height: 16
  },
  cardListIcon: {
    marginRight: "5px",
    color: palette.text.secondary,
    width: 22,
    height: 22
  },
  absHeader: {
    position: "absolute",
    backgroundColor: "rgba(38, 20, 64, 0.8)",
    width: 716,
    zIndex: 100
  },
  cardMenu: {
    display: "flex",

    justifyContent: "flex-end",
    alignItems: "center"
  },
  cardContent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#fff",
    padding: 0,
    paddingBottom: "0 !important"
  },
  circleNumber: {
    border: "1px solid " + palette.text.secondary,
    borderRadius: "50%",
    padding: "5px",
    margin: "0 16px",
    width: "24px",
    height: "24px",
    textAlign: "center",
    fontSize: "1.2rem",
    lineHeight: 1
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  flexHeading: {
    display: "flex",
    alignItems: "center",
    flex: "1",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "1.6rem"
  },
  mediumIcon: {
    width: 20,
    height: 20,
    marginRight: "16px"
  },
  largeIcon: {
    width: 22,
    height: 22,
    color: palette.greys.dark
  },
  bigIcon: {
    width: 24,
    height: 24,
    color: palette.primary.main,
    marginRight: theme.spacing(2)
  },
  listIcon: {
    width: 22,
    height: 22,
    color: palette.greys.dark
  },
  actionsBar: {
    justifyContent: "space-between",
    padding: "10px",
    height: "56px",
    borderTop: "1px solid #D8D8D8"
  },
  hasCursor: {
    cursor: "pointer"
  },
  contentHolder: {
    position: "relative",
    height: "268px"
  },
  descText: {
    width: "100%",
    padding: "16px 16px",
    borderTop: "1px solid #C6C6C6"
  },
  padTxt: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

function ContentTemplateListItem(props) {
  const classes = useStyles();
  const [showDescription, setShowDescription] = React.useState(false);

  function handleClick() {
    if (props.isModal !== true) {
      props.history.push("/content/new/" + props.id);
    } else {
      props.updateContent(props.row);
    }
  }

  function handleInfoClick(e) {
    e.stopPropagation();
    setShowDescription(!showDescription);
  }
  return (
    <Card className={classes.cardMedium} onClick={handleClick}>
      <div className={classes.contentHolder}>
        <CardMedia
          className={classes.mediaMedium}
          image={"/" + props.img}
          title="Contemplative Reptile"
        />
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h4"
            component="h4"
            className={clsx(classes.flexHeading, classes.padTxt)}
          >
            <ContentIcon className={classes.bigIcon} />
            {props.heading}
          </Typography>
          {showDescription && (
            <div className={classes.descText}>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.description}
              </Typography>
            </div>
          )}
        </CardContent>
      </div>
      <CardActions className={classes.actionsBar}>
        <Button color="primary">LAG NY</Button>
        <div
          className={clsx(classes.cardMenu, classes.hasCursor)}
          onClick={handleInfoClick}
        >
          <InfoOutlinedIcon className={classes.bigIcon} />
        </div>
      </CardActions>
    </Card>
  );
}

export default withRouter(ContentTemplateListItem);
