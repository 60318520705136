import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import LinksListHolder from "./components/LinksListHolder";
import StatsContext from "../../context/StatsContext";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  circleProg: {
    marginTop: "48px"
  }
}));

function LinksPage(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: "",
    title: "",
    id: "",
    url: "",
    itemId: "",
    placement: "",
    placement_id: "",
    placement_type: "",
    placement_source: "",
    placement_medium: "",
    placement_code: "",
    placement_campaign: "",
    product: "",
    extras: "",
    campaign: "",
    isValidated: 0,
    finalURL: "",
    gameURL: "",
    leaguesURL: "",
    errors: []
  });

  const [linksData, setLinksData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert!");
  const [snackVar, setSnackVar] = React.useState("success");

  const statsRange = React.useContext(StatsContext);
  function handleClose(event) {
    setOpen(false);
  }

  function showAlert(msg, msgtype) {
    setSnackVar(msgtype);
    setSnackMsg(msg);
    setOpen(true);
  }

  useEffect(() => {
    const loadPlacements = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/links/" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.links;
      setLinksData(results);
    };
    loadPlacements();
  }, [statsRange.sid]);

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {!linksData && (
        <Grid item xs={12} className={classes.circleProg} align="center">
          <CircularProgress disableShrink />
        </Grid>
      )}
      {linksData && (
        <LinksListHolder
          values={values}
          setValues={setValues}
          items={linksData}
          setLinksData={setLinksData}
          showAlert={showAlert}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}

export default withRouter(LinksPage);
