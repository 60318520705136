/* eslint-disable no-script-url */

import React from "react";
import Grid from "@material-ui/core/Grid";

import ContentListItem from "./ContentListItem";
import ContentTemplateListItem from "./ContentTemplateListItem";

export default function TableTippetips(props) {
  const rows = props.rows;
  const type = props.type;

  let itemList = "";

  if (props.type === "egen") {
    itemList = rows.map((row) => (
      <Grid item lg={4} md={6} xs={12} key={row.id}>
        <ContentListItem
          id={row.id}
          doOpenModal={props.clickToOpenModal}
          img={row.contentImg}
          updated={row.updated.replace(/[^\w\s]/gi, "")}
          heading={row.name}
          description={row.description}
          placements={row.numTimesUsed}
          posList={row.positionsList}
          type={type}
          isModal={props.isModal}
          updateContent={props.updateContent}
          row={row}
        />
      </Grid>
    ));
  } else {
    itemList = rows.map((row) => (
      <Grid item lg={4} md={6} xs={12} key={row.template_id}>
        <ContentTemplateListItem
          id={row.template_id}
          doOpenModal={props.clickToOpenModal}
          img={row.image}
          heading={row.name}
          description={row.description}
          type={type}
          isModal={props.isModal}
          updateContent={props.updateContent}
          row={row}
        />
      </Grid>
    ));
  }
  return <React.Fragment>{itemList}</React.Fragment>;
}
