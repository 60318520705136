import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import dummydata from "./data";

import CollectionsListHolder from "./components/CollectionsListHolder";
import StatsContext from "../../context/StatsContext";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";

import { withRouter } from "react-router-dom";
import RenameCollectionDialog from "./components/RenameCollectionDialog";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  circleProg: {
    marginTop: "48px",
  },
}));

function CollectionsPage(props) {
  const classes = useStyles();

  const [collectionsData, setCollectionsData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert!");
  const [snackVar, setSnackVar] = React.useState("success");
  const [deleteURL, setDeleteURL] = useState("");
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const statsRange = React.useContext(StatsContext);
  function handleClose(event) {
    setOpen(false);
  }

  function showAlert(msg, msgtype) {
    setSnackVar(msgtype);
    setSnackMsg(msg);
    setOpen(true);
  }

  useEffect(() => {
    const loadPlacements = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/collections/" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.collections;
      setCollectionsData(results);
      //setCollectionsData(dummydata);
    };
    loadPlacements();
  }, [statsRange.sid]);

  React.useEffect(() => {
    const deleteData = async () => {
      const result = await axios.delete(deleteURL);
    };
    if (deleteURL != "") {
      deleteData();
    }
  }, [deleteURL]);

  function doDelete(collectionId) {
    if (!collectionId) return;
    setDeleteURL(
      "https://nt-ekom-2.herokuapp.com/collection/" +
        collectionId +
        "?session_key=" +
        statsRange.sid
    );
    setSnackMsg("Tippset ble slettet!");

    setOpen(true);
  }

  function handleOpenRenameDialog() {
    setRenameDialogOpen(!renameDialogOpen);
    console.log("open");
  }

  function handleChange() {
    console.log("handleChange");
  }

  function renameCollection() {
    console.log("renameCollection");
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {!collectionsData && (
        <Grid item xs={12} className={classes.circleProg} align="center">
          <CircularProgress disableShrink />
        </Grid>
      )}
      {collectionsData && (
        <CollectionsListHolder
          items={collectionsData}
          setCollectionsData={setCollectionsData}
          showAlert={showAlert}
          doDelete={doDelete}
        />
      )}
      {renameDialogOpen && (
        <RenameCollectionDialog
          open={true}
          handleClose={handleOpenRenameDialog}
          handleChange={handleChange}
          renameCollection={renameCollection}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}

export default withRouter(CollectionsPage);
