import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";

import AddIcon from "@material-ui/icons/Add";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AlertDialog from "../../../components/AlertDialog";
import update from "immutability-helper";

import TableCollections from "./TableCollections";
import palette from "../../../theme/palette";
import { withRouter } from "react-router-dom";
import StatsContext from "../../../context/StatsContext";
import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  buttonMob: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "7px 10px",
    },
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(1),
  },
  placementlist: {
    marginBottom: "32px",
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary,
  },
  buttonMenuLeft: {
    marginRight: "10px",
  },
  outerPadding: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  centerVert: {
    alignItems: "center",
  },
  buttonTopMargin: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
}));

function getSteps() {
  return ["Velg landingsside", "Sporingsvalg", "Vis lenke"];
}

function CollectionsListHolder(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);
  const isMobile = useMediaQuery("(max-width:767px)");

  const [open, setOpenDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [delItem, setDelItem] = useState(false);

  const [deleteURL, setDeleteURL] = useState("");

  function handleOpenDialog() {
    setOpenDialog(!open);
  }

  function handleOpenAlert() {
    setAlertOpen(!alertOpen);
  }

  const [displayCategory, setCategory] = useState("Alle");

  function doDelete(itemId) {
    setDeleteURL(
      "https://nt-ekom-2.herokuapp.com/collection/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
    props.showAlert("Slettet!", "success");
  }

  React.useEffect(() => {
    const deleteData = async () => {
      const result = await axios.delete(deleteURL);
    };
    if (deleteURL != "") {
      deleteData();
    }
  }, [deleteURL]);

  function handleNewPage() {
    props.history.push("/collections/new");
  }

  function handleUpdateTitle(id, newTitle) {
    var updatedrow = {
      title: newTitle,
      id: id,
    };
    props.showAlert("Oppdatert!", "success");
  }

  function handleDelete(id) {
    const delObj = {};
    delObj.id = id;
    setDelItem(delObj);

    handleOpenAlert();
  }

  function confirmDelete() {
    const items = props.items.filter((item) => item.id !== delItem.id);
    doDelete(delItem.id);

    props.setCollectionsData(items);
    handleOpenAlert();
  }

  function updateItemTitle(itemId, title) {
    const items = props.items.map((item, key) => {
      if (item.id === itemId) {
        item.title = title;
      }
      return item;
    });

    props.setCollectionsData(items);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={0}>
        <Grid
          container
          spacing={3}
          className={clsx(classes.centerVert, classes.outerPadding)}
        >
          <Grid item xs={12} align="left">
            <Typography
              variant="h1"
              component="h1"
              align="left"
              className={classes.heading}
            >
              Samlesider
            </Typography>
          </Grid>
          {props.items.length === 0 && (
            <Grid item xs={12} align="left">
              <Typography variant="body1" component="h3" align="left">
                <span className={classes.iconText}>
                  {" "}
                  Klikk på "Ny samleside" for å komme i gang.
                </span>{" "}
              </Typography>
            </Grid>
          )}
          {/*<Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <MenuSort />
            <IconButton>
              <MoreVert className={classes.bigIcon} />
            </IconButton>
          </Grid>
          */}
        </Grid>

        <Grid item xs={12} className={classes.placementlist}>
          <TableCollections
            selectedCat={displayCategory}
            rows={props.items}
            handleDelete={handleDelete}
            handleOpenDialog={handleOpenDialog}
            setCollectionsData={props.setCollectionsData}
            updateItemTitle={updateItemTitle}
            handleUpdateTitle={handleUpdateTitle}
          />
        </Grid>
        <Grid item xs={12} align="center" className={classes.buttonTopMargin}>
          <Button
            variant="outlined"
            onClick={handleNewPage}
            color="primary"
            className={classes.buttonMob}
          >
            <AddIcon /> NY SAMLESIDE
          </Button>{" "}
        </Grid>
      </Grid>

      <AlertDialog
        open={alertOpen}
        handleClose={handleOpenAlert}
        handleConfirm={confirmDelete}
        msg="Er du sikker på at du vil slette?"
      />
    </Container>
  );
}

export default withRouter(CollectionsListHolder);
