import React, { useEffect } from "react";
import clsx from "clsx";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import palette from "../theme/palette";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

import TimeField from "react-simple-timefield";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { daynames, potCondition, potSize } from "../common/consts";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles(theme => ({
  win: {
    minWidth: "613px",
    maxWidth: "613px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      maxWidth: "100%"
    }
  },
  cellPadLeft: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1)
    }
  },
  cellPadRight: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(1)
    }
  },
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 8px"
  },
  gridCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 8px",
    flexDirection: "row"
  },
  gridRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px 8px"
  },

  gridStretch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px"
  },
  clockSelect: {
    width: "92px",
    marginLeft: theme.spacing(1),
    "& .MuiFilledInput-input": {
      padding: "10px"
    }
  },
  titleArea: {
    backgroundColor: "rgba(216, 216, 216, 0.2)",
    "& .MuiTypography-h6": {
      fontSize: "2.2rem",
      fontWeight: 600
    }
  },
  hourDrop: {
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  blueBtn: {
    color: palette.primary.button
  },
  dividerPad: {
    margin: theme.spacing(3) + "px 0px "
  },
  dataPicker: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    "& .MuiInputBase-adornedEnd": {
      padding: "8px 8px 0 8px"
    }
  },
  flexGrid: {
    display: "flex",
    flexDirection: "row"
  },
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    "& label + .MuiInput-formControl": {
      marginTop: "16px"
    },
    "& .MuiInput-root": {
      width: "100%"
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "transparent",
      padding: "12px"
    }
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary
  }
}));
export default function TimeScheduleCondition(props) {
  const classes = useStyles();

  const conditionName = props.values.conditionName;

  let renderedDays = "";
  let option = props.values.option;
  useEffect(() => {
    if (option === 0) {
      props.setValidated(false);
    } else if (option === 1) {
      props.setValidated(true);
    } else if (option === 2) {
      props.setValidated(false);
      let ukeArr = props.values.ukeDager.map(item => item.day);
      if (ukeArr.length > 0) {
        props.setValidated(true);
      } else {
        props.setValidated(false);
      }
    } else if (option === 3) {
      props.setValidated(true);
    }
  }, [option, props.values.ukeDager]);

  function chooseOption() {
    if (props.values.option === 0) {
      return "";
    } else if (props.values.option === 1) {
      return renderRange();
    } else if (props.values.option === 2) {
      return renderUkeOption();
    } else if (props.values.option === 3) {
      return renderPott();
    }
  }

  function renderDays() {
    return props.values.ukeDager.map(item => (
      <Grid container key={item.day}>
        <Grid item xs={12} sm={6} className={clsx(classes.gridRight)}>
          {daynames[item.day].name} fra kl.
        </Grid>
        <Grid item xs={12} sm={6} className={clsx(classes.gridStretch)}>
          <TimeField
            value={item.from}
            onChange={e => props.handleChangeTimeFrom(e, item, props.index)}
            input={
              <TextField
                id={"filled-timeslot-start-" + item.day}
                className={clsx(classes.clockSelect)}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                variant="filled"
              ></TextField>
            }
          />
          til kl.
          <TimeField
            value={item.to}
            onChange={e => props.handleChangeTimeTo(e, item, props.index)}
            input={
              <TextField
                id={"filled-timeslot-end-" + item.day}
                className={classes.clockSelect}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                variant="filled"
              ></TextField>
            }
          />
        </Grid>
      </Grid>
    ));
  }

  renderedDays = renderDays();

  function renderUkeOption() {
    let ukeArr = props.values.ukeDager.map(item => item.day);

    return (
      <>
        <Grid item xs={12} sm={6} className={classes.cellPadLeft}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">
              Velg ukedag(er)
            </InputLabel>
            <Select
              multiple
              value={ukeArr}
              onChange={e => props.handleChangeWeek(e, props.index)}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={selected =>
                selected.length > 1 ? "Flere valgt" : daynames[selected].name
              }
              MenuProps={MenuProps}
            >
              {daynames.map(day => (
                <MenuItem key={day.id} value={day.id}>
                  <Checkbox checked={ukeArr.indexOf(day.id) > -1} />
                  <ListItemText primary={day.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {renderedDays}
      </>
    );
  }

  function renderRange() {
    return (
      <>
        <Grid item xs={12} sm={6} className={classes.cellPadRight}></Grid>
        <Grid item xs={12} sm={6} className={classes.cellPadRight}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-fra"
              label="Begrenset periode fra"
              value={props.values.selectedDateFra}
              onChange={e => props.handleDateChangeFra(e, props.index)}
              className={classes.dataPicker}
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.cellPadLeft}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              autoOk={true}
              margin="normal"
              id="date-picker-til"
              label="Begrenset periode til"
              value={props.values.selectedDateTil}
              onChange={e => props.handleDateChangeTil(e, props.index)}
              minDate={props.values.selectedDateFra}
              className={classes.dataPicker}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </>
    );
  }

  function renderPott() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.cellPadRight}>
          <TextField
            id={"filled-ts-pott"}
            select
            label={"Velg pott betingelse"}
            className={classes.textField}
            value={props.values.pott}
            onChange={e => props.handleChangePott(e, props.index)}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="filled"
          >
            {potCondition.map((pot, index) => (
              <MenuItem key={index} value={pot.id}>
                {pot.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.cellPadRight}>
          <TextField
            id={"filled-ts-size"}
            select
            label={""}
            className={classes.textField}
            value={props.values.pottSize}
            onChange={e => props.handleChangePottSize(e, props.index)}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="filled"
          >
            {potSize.map((pot, index) => (
              <MenuItem key={index} value={pot.id}>
                {pot.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={0}>
      {props.isMainCondition !== 0 && (
        <>
          <Grid item xs={12} className={classes.gridCenter}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Gender"
                name="betingelse"
                className={classes.gridCenter}
                value={props.values.conditionParameter}
                onChange={e => props.handleChange(e, props.index)}
              >
                <FormControlLabel value="and" control={<Radio />} label="og" />
                <FormControlLabel
                  value="or"
                  control={<Radio />}
                  label="eller"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={10} className={classes.cellPadRight}>
        <Typography
          variant="h3"
          component="h3"
          align="left"
          className={classes.contentHeading}
        >
          {conditionName}
        </Typography>
      </Grid>
      <Grid item xs={2} align="right">
        <DeleteIcon
          className={classes.bigIcon}
          onClick={e => props.deleteCondition(e, props.index)}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.cellPadRight}>
        <TextField
          id={"filled-ts"}
          select
          label={"Velg type betingelse"}
          className={classes.textField}
          value={props.values.option}
          onChange={e => props.handleChangeContent(e, props.index)}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="filled"
        >
          <MenuItem key={0} value={0}>
            Velg betingelse
          </MenuItem>
          <MenuItem key={1} value={1}>
            Tidsstyring
          </MenuItem>
          <MenuItem key={2} value={2}>
            Ukedag
          </MenuItem>
          <MenuItem key={3} value={3}>
            Pott
          </MenuItem>
        </TextField>
      </Grid>

      {chooseOption()}

      <Grid item xs={12}>
        <Divider className={classes.dividerPad} />
      </Grid>
    </Grid>
  );
}
