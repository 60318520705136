/* eslint-disable no-script-url */

import React, { useEffect } from "react";
import useAxios from "axios-hooks";

import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import PasswordShowHide from "../../../components/PasswordShowHide";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import StatsContext from "../../../context/StatsContext";

import palette from "../../../theme/palette";
const useStyles = makeStyles((theme) => ({
  /* seeMore: {
    marginTop: theme.spacing(3),
  },*/
  textField: {
    width: "100%",
  },
  formContainer: {
    padding: "0px 24px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  loginBtn: {
    width: "100%",
    marginTop: "4.5rem",
  },
  links: {
    marginTop: "4rem",
    display: "flex",
    justifyContent: "center",
  },
  boldLink: {
    fontWeight: 600,
    color: palette.primary.dark,
  },
  errormsg: {
    textAlign: "center",
    color: palette.error.main,
  },
}));

function LoginCard(props) {
  const classes = useStyles();
  const historyObject = props.history;
  const [values, setValues] = React.useState({
    username: "",
    password: "",
  });

  const [error, setError] = React.useState("");
  const statsRange = React.useContext(StatsContext);
  const [
    { data: loginData, loading: isLoading, error: isError },
    doLogin,
  ] = useAxios(
    {
      url: "https://nt-ekom-2.herokuapp.com/user/login",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmit = (e) => {
    doLogin({
      data: { ...values },
    });

    //props.history.push('/dashboard');
  };

  useEffect(() => {
    if (!isLoading && loginData && loginData.status === "OK") {
      statsRange.updateSID(loginData.session_key);
      statsRange.updatePartner(loginData.partner_name);
      statsRange.updatePartnerCode(loginData.partner_code);
      statsRange.updateTime(loginData.stats_hours);
      statsRange.updatePartnerId(loginData.partner_id);

      if (loginData.partner_id) {
        historyObject.push("/dashboard");
      } else {
        setError("Feil bruker eller passord.");
        return;
      }
    } else if (loginData && loginData.error === "WRONG_USER_OR_PASSWORD") {
      console.log("ERROR");
      setError("Feil bruker eller passord.");
    }
  }, [isLoading, loginData, error]);

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <React.Fragment>
      <Typography variant="h2" component="h2" align="center" gutterBottom>
        Velkommen til Partnerportalen
      </Typography>
      <Typography variant="body2" align="center" gutterBottom>
        Vennligst logg inn nedenfor
      </Typography>
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
        className={classes.formContainer}
        autoComplete="off"
      >
        <TextValidator
          id="username"
          label="Brukernavn"
          className={classes.textField}
          value={values.username}
          onChange={handleChange("username")}
          margin="normal"
          variant="filled"
          validators={["required", "isEmail"]}
          errorMessages={[
            "dette feltet er obligatorisk",
            "e-posten er ikke gyldig",
          ]}
        />

        <PasswordShowHide
          password={values.password}
          title="Passord"
          onChange={handleChange("password")}
        />
        <Button variant="contained" className={classes.loginBtn} type="submit">
          LOGG INN
        </Button>

        <div className={classes.links}>
          <Link
            component={RouterLink}
            variant="body2"
            className={classes.boldLink}
            to="/forgot"
          >
            Glemt passord
          </Link>
        </div>
      </ValidatorForm>
      <div className={classes.errormsg}>{error}</div>
    </React.Fragment>
  );
}
export default withRouter(LoginCard);
