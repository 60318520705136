import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';

import TextField from '@material-ui/core/TextField';

const currencies = [
  {
    value: 'USD',
    label: 'Menu item 1'
  },
  {
    value: 'EUR',
    label: 'Menu item 2'
  },
  {
    value: 'BTC',
    label: 'Menu item 3'
  },
  {
    value: 'JPY',
    label: 'Menu item 4'
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 500,
    padding: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: '3px',
    minWidth: '176px'
  },
  input: {
    display: 'none'
  }
}));

export default function AllInputs(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: '',
    age: '',
    multiline: 'Controlled',
    currency: 'EUR'
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <div className={classes.root}>
      <Typography variant="h1" component="h2" gutterBottom>
        Inputs
      </Typography>

      <TextField
        id="filled-name"
        label="Name"
        className={classes.textField}
        value={values.name}
        onChange={handleChange('name')}
        margin="normal"
        variant="filled"
      />
      <br />
      <TextField
        id="filled-name"
        label="Name"
        className={classes.textField}
        value={values.name}
        onChange={handleChange('name')}
        margin="normal"
        variant="filled"
        multiline
        rows="4"
        rowsMax="4"
        helperText="Help"
      />
      <br />
      <TextField
        id="filled-select-currency"
        select
        label="Select"
        className={classes.textField}
        value={values.currency}
        onChange={handleChange('currency')}
        SelectProps={{
          MenuProps: {
            className: classes.menu
          }
        }}
        helperText="Please select your menu item"
        margin="normal"
        variant="filled"
      >
        {currencies.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
