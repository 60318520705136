import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 500,
    padding: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "3px",
    minWidth: "176px"
  },
  input: {
    display: "none"
  }
}));

export default function AllButtons(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" component="h2" gutterBottom>
        Buttons
      </Typography>
      <Button variant="contained" className={classes.button}>
        Default
      </Button>
      <Button variant="contained" color="primary" className={classes.button}>
        Primary
      </Button>
      <Button variant="contained" color="secondary" className={classes.button}>
        Secondary
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled
        className={classes.button}
      >
        Disabled
      </Button>

      <Button
        variant="contained"
        color="secondary"
        disabled
        className={classes.button}
      >
        Disabled
      </Button>

      <Button className={classes.button}>Default</Button>
      <Button color="primary" className={classes.button}>
        Primary
      </Button>
      <Button className={classes.button} disabled>
        Default
      </Button>
      <Button color="primary" className={classes.button} disabled>
        Primary
      </Button>
    </div>
  );
}
