import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemText from "@material-ui/core/ListItemText";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const useStyles = makeStyles(theme => ({
  iconPadding: {
    marginRight: 0
  }
}));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary
      }
    }
  }
}))(MenuItem);

export default function MenuSort(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleSortPlassering(key, order) {
    props.sortPlassering(key, order);
    setAnchorEl(null);
  }
  function handleSortContent() {
    props.sortContent();
    setAnchorEl(null);
  }

  function handleSortStats(index) {
    props.sortStats(index);
    setAnchorEl(null);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.iconPadding}
      >
        <SortIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={e => handleSortPlassering("placement", 1)}>
          <ListItemText primary="Sorter etter plassering" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleSortContent}>
          <ListItemText primary="Sorter etter innhold" />
        </StyledMenuItem>
        <StyledMenuItem onClick={e => handleSortStats("ctr")}>
          <ListItemText primary="Sorter etter CTR" />
        </StyledMenuItem>
        <StyledMenuItem onClick={e => handleSortStats("clicks")}>
          <ListItemText primary="Sorter etter klikk" />
        </StyledMenuItem>
        <StyledMenuItem onClick={e => handleSortStats("views")}>
          <ListItemText primary="Sorter etter vist" />
        </StyledMenuItem>
        <StyledMenuItem onClick={e => handleSortPlassering("updated", -1)}>
          <ListItemText primary="Sorter etter sist endret" />
        </StyledMenuItem>
      </StyledMenu>
    </Fragment>
  );
}
