import JubelRegularWoff2 from '../../fonts/Jubel/Jubel-Regular.woff2';
import JubelRegularWoff from '../../fonts/Jubel/Jubel-Regular.woff';

import JubelMediumWoff2 from '../../fonts/Jubel/Jubel-Medium.woff2';
import JubelMediumWoff from '../../fonts/Jubel/Jubel-Medium.woff';

import JubelBoldWoff2 from '../../fonts/Jubel/Jubel-Bold.woff2';
import JubelBoldWoff from '../../fonts/Jubel/Jubel-Bold.woff';

const jubel = {
  fontFamily: 'Jubel',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Jubel-Regular'),
    url(${JubelRegularWoff2}) format('woff2'),
    url(${JubelRegularWoff}) format('woff')
  `
};

const jubelmedium = {
  fontFamily: 'Jubel',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('Jubel-Medium'),
    url(${JubelMediumWoff2}) format('woff2'),
    url(${JubelMediumWoff}) format('woff')
  `
};

const jubelbold = {
  fontFamily: 'Jubel',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
    local('Jubel-Bold'),
    url(${JubelBoldWoff2}) format('woff2'),
    url(${JubelBoldWoff}) format('woff')
  `
};

export default {
  '@global': {
    '@font-face': [jubel, jubelbold, jubelmedium],
    '@font-size': '10px'
  }
};
