export default [
  {
    key: '1',
    caption: 'Lotteri',
    subMenuItems: [
      {
        key: '12',
        caption: 'Lotto',
        url: 'lotto'
      },
      {
        key: '13',
        caption: 'Vikinglotto',
        url: 'vikinglotto'
      },
      {
        key: '14',
        caption: 'Eurojackpot',
        url: 'eurojackpot'
      },
      {
        key: '15',
        caption: 'Extra',
        url: 'extra'
      },
      {
        key: '16',
        caption: 'Joker',
        url: 'joker'
      },
      {
        key: '17',
        caption: 'Keno',
        url: 'keno'
      },
      {
        key: '18',
        caption: 'Nabolaget',
        url: 'nabolaget'
      },
      {
        key: '19',
        caption: 'Norsk Tipping',
        url: 'andelsbank'
      }
    ]
  },

  {
    key: 'more3',
    caption: 'Sport',
    subMenuItems: [
      {
        key: '21',
        caption: 'Langoddsen',
        url: 'langoddsen'
      },
      {
        key: '22',
        caption: 'Liveoddsen',
        url: 'liveoddsen'
      },
      {
        key: '23',
        caption: 'Tipping',
        url: 'tipping'
      },
      {
        key: '24',
        caption: 'Oddsbomben',
        url: 'oddsbomben'
      },
      {
        key: '25',
        caption: 'Vinneroddsen',
        url: 'vinneroddsen'
      },
      {
        key: '26',
        caption: 'Vinnerøyeblikk',
        url: 'vinneroyeblikk'
      }
    ]
  },
  {
    key: '3',
    caption: 'Flax',
    url: 'flax'
  },
  {
    key: '4',
    caption: 'Kongkasino',
    url: 'kongkasino'
  },
  {
    key: '5',
    caption: 'Bingoria',
    url: 'bingoria'
  }
];
