// Palette
import palette from '../palette';

export default {
  root: {
    fontWeight: '600 !important'
  },
  label: {
    fontSize: '1.4rem',
    color: palette.text.primary,
    fontWeight: '600 !important',
    '&.MuiStepLabel-active': {
      fontWeight: '600'
    }
  }
};
