import React, { Component } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";

import theme from "./theme";
import Layout from "./hoc/Layout";
import Routes from "./routes";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Layout>
            <CssBaseline />
            <Routes />
          </Layout>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withRouter(App);
