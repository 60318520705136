/* eslint-disable no-script-url */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  msgPadding: {
    paddingTop: "60px"
  },
  heading: {
    paddingBottom: "20px"
  }
}));

export default function EmptyMessage(props) {
  const classes = useStyles();
  const msg = ["Her finner du dine favoritter!"];
  return (
    <Grid container>
      <Grid item xs={12} sm={4} className={classes.msgPadding}>
        <Typography
          variant="h3"
          component="h3"
          align="left"
          className={classes.heading}
        >
          Du har ingen plasseringer
        </Typography>

        <Typography
          variant="body1"
          component="h3"
          align="left"
          className={classes.heading}
        >
          <Link to="/newplacement">Lag en ny plassering</Link> for å komme i
          gang.
        </Typography>
      </Grid>
    </Grid>
  );
}
