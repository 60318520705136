import React, { Fragment } from "react";
import CheckMark from "../../../images/mark.svg";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import FormRadio from "../../../components/FormRadio";
import ElementItemTippetips from "../../../components/ElementItemTippetips";
import ElementItemTippetipsHeader from "../../../components/ElementItemTippetipsHeader";

import StatsContext from "../../../context/StatsContext";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "3px",
    minWidth: "176px",
  },
  input: {
    display: "none",
  },

  checkboxBudget: {
    position: "absolute",
    left: "-9999px",
    width: "0",
    height: "0",
    visibility: "hidden",
    "&:checked + label,&:not(:checked) + label": {
      position: "relative",
      display: "inline-block",
      padding: "8px 14px 8px 0px",
      minWidth: "176px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "1px",
      margin: "0 auto",
      marginLeft: "5px",
      marginRight: "5px",
      marginBottom: "10px",
      textAlign: "center",
      borderRadius: "3px",
      overflow: "hidden",
      cursor: "pointer",
      textTransform: "uppercase",
      color: "#222222",
      border: "1px solid #0069E2",
    },
    "&:not(:checked) + label": {
      border: "1px solid #999999",
    },
    "&:not(:checked) + label img": {
      visibility: "hidden",
    },
    "&:checked + label": {
      backgroundColor: "transparent",
    },
    "&:checked + label::before,&:not(:checked) + label::before": {
      position: "absolute",
      content: "",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      borderRadius: "4px",
      backgroundImage: "linear-gradient(138deg, red, yellow)",
      zIndex: "-1",
    },
    "&:checked + label span,&:not(:checked) + label span": {
      position: "relative",
      display: "block",
    },
    "&:checked + label span::before,&:not(:checked) + label span::before": {
      position: "absolute",
      content: "attr(data-hover)",
      top: "0",
      left: "0",
      width: "100%",
      overflow: "hidden",
    },
    "&:not(:checked) + label span::before": {
      maxHeight: "0",
    },
    "&:checked + label span::before": {
      maxHeight: "100%",
    },
  },
  checkMark: {
    marginRight: "5px",
  },
  checkboxCoupon: {
    position: "relative",
    display: "inline-block",
    padding: "8px 14px 8px 0px",
    minWidth: "176px",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
    letterSpacing: "1px",
    margin: "0 auto",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
    textAlign: "center",
    borderRadius: "3px",
    overflow: "hidden",
    cursor: "pointer",
    textTransform: "uppercase",
    color: "#222222",
    border: "1px solid #0069E2",
    "&:not(:checked)": {
      border: "1px solid #999999",
    },
  },
}));
export default function TableTippetipsHolder(props) {
  const classes = useStyles();
  const dag = parseInt(props.dag, 10);
  const rowsholder = props.rows.filter(function (row) {
    return row.key == dag;
  });

  const [selectedOption, setSelectedOption] = React.useState("manuelt");
  const rows = rowsholder[0].coupon;
  const [coupon, setCoupon] = React.useState(rows);

  function handleChange(event) {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
  }

  function getSelection(row) {
    if (selectedOption === "manuelt") {
      return row.selection;
    } else {
      return 7;
    }
  }
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;

  return (
    <Fragment>
      {/*     <Grid item xs={12}>
        <FormRadio
          label="MANUELT"
          selectedOption={selectedOption}
          name="budget"
          id="manuelt"
          value="manuelt"
          onChange={handleChange}
        />

        <FormRadio
          label="FOLKETIPS"
          selectedOption={selectedOption}
          name="budget"
          id="folketips"
          value="folketips"
          onChange={handleChange}
        />

        <FormRadio
          label="EKSPERTTIPS"
          selectedOption={selectedOption}
          name="budget"
          id="eksperttips"
          value="eksperttips"
          onChange={handleChange}
        />
      </Grid>
*/}
      <ElementItemTippetipsHeader />

      {rows &&
        rows.map((row, index) => {
          const sClicks = 0;
          const sClicksTrend = "";
          const sCtr = 0;
          const sCtrTrend = "";
          const sViews = 0;
          const sViewsTrend = 0;

          return (
            <Grid item xs={12} key={row.id}>
              <ElementItemTippetips
                key={row.id}
                index={index}
                type={row.key}
                name={row.name}
                matchid={row.matchid}
                ekspert_h={row.ekspert_h}
                ekspert_u={row.ekspert_u}
                ekspert_b={row.ekspert_b}
                folke_h={row.folke_h}
                folke_u={row.folke_u}
                folke_b={row.folke_b}
                selection={row.selection}
                origselection={coupon.selection}
                updateSelection={props.updateSelection}
                id={row.id}
                zeroMinWidth
                handleDelete={props.handleDelete}
                handleOpenDialog={props.handleOpenDialog}
                addToFavorites={props.addToFavorites}
                couponSelection={coupon[index].selection}
                couponMode={props.feed}
              />
            </Grid>
          );
        })}
    </Fragment>
  );
}
