/* eslint-disable no-script-url */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import OddsProgrammet from "./OddsProgrammet";
import OddsLiga from "./OddsLiga";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileNotSupported from "../../../components/MobileNotSupported";
import EnhancedMenu from "../../../components/EnhancedMenu";
import Grid from "@material-ui/core/Grid";
import menuItems from "../../../data/link-categories";
import palette from "../../../theme/palette";

const useStyles = makeStyles({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
    boxShadow: "none",
  },
  depositContext: {
    margin: "2rem 0",
  },
  button: {
    paddingTop: "9px",
    paddingBottom: "9px",
  },
  textField: {
    padding: "5px",
  },
});

export default function LinksChooseCat(props) {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = React.useState(null);
  const isMobile = useMediaQuery("(max-width:769px)");
  function handleButtonClick(event) {
    setAnchorElement(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorElement(null);
  }

  function handleMenuItemClick(event) {
    let hasValidated = 1;

    if (event.key === "100") {
      hasValidated = 0;
    }
    props.setValues({
      ...props.values,
      name: event.caption,
      eventid: event.key,
      url: event.url,
      placement_code: event.code,
      isValidated: hasValidated,
    });

    setAnchorElement(null);
  }

  const handleChange = (name) => (event) => {
    props.setValues({
      ...props.values,
      [name]: event.target.value,
      isValidated: 1,
    });
  };

  const updateGameURL = (gameURL) => {
    if (props.values.gameURL === gameURL) return;
    props.setValues({
      ...props.values,
      gameURL: gameURL,
    });
  };

  const updateLeaguesURL = (leaguesURL) => {
    if (props.values.leaguesURL === leaguesURL) return;
    props.setValues({
      ...props.values,
      leaguesURL: leaguesURL,
    });
  };

  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          id="filled-name"
          label="Velg landingsside"
          placeholder="Velg et alternativ"
          className={classes.textField}
          onClick={handleButtonClick}
          value={props.values.name}
          margin="normal"
          variant="filled"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
      </Grid>

      {props.values.eventid === "100" && (
        <Grid item xs={12} sm={4}>
          <TextField
            id="filled-name"
            label="Egen URL"
            className={classes.textField}
            value={props.values.url}
            onChange={handleChange("url")}
            placeholder="Fyll inn din egen URL"
            margin="normal"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}
      {console.log(props.values)}
      {props.values.eventid === "322" && !isMobile && (
        <OddsProgrammet updateGameURL={updateGameURL} />
      )}
      {props.values.eventid === "323" && !isMobile && (
        <OddsLiga updateLeaguesURL={updateLeaguesURL} />
      )}

      {props.values.eventid === "322" && isMobile && <MobileNotSupported />}
      {props.values.eventid === "323" && isMobile && <MobileNotSupported />}

      <EnhancedMenu
        open={Boolean(anchorElement)}
        menuItems={menuItems}
        anchorElement={anchorElement}
        onClose={handleMenuClose}
        onMenuItemClick={handleMenuItemClick}
      />
    </>
  );
}
