import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import palette from "../../../theme/palette";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  odds: {
    padding: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    marginTop: "6px",
    marginBottom: "6px",
    minWidth: "54px",
    fontWeight: "bold",
    cursor: "inherit",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectedodds: {
    padding: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    minWidth: "54px",
    fontWeight: "bold",
    color: "#0069E2",
    cursor: "inherit",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icons: {
    width: "80%",
    height: "80%",
    margin: "10%",
  },
  noBreak: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function OddsChooserHeading(props) {
  let oddsArray = props.chosenValues;

  function markSelected(value) {
    //props.updateOdds(value.matchid, value.betid, value.selectionseqno);
  }

  const classes = useStyles();

  function translateHUB(val){
    if(val==="D"){
      return "U";
    }else if(val==="A"){
      return "B";
    }
    return val;
  }

  function renderHUB(value, clsArr) {
    return (
      <div
        key={value.selectionId}
        className={clsx(clsArr.join(", "))}
        onClick={markSelected.bind(null, value)}
      >
        {translateHUB(value.selectionValue)} {parseFloat(value.selectionOdds).toFixed(2)}
      </div>
    );
  }

  function renderVinner(value, clsArr) {
    return (
      <div
        key={value.selectionId}
        className={clsx(clsArr.join(", "))}
        onClick={markSelected.bind(null, value)}
      >
        {value.selectionShortName} {parseFloat(value.selectionOdds).toFixed(2)}
      </div>
    );
  }

  function renderChooser(value, clsArr) {
    if (
      value.selectionValue === "H" ||
      value.selectionValue === "D" ||
      value.selectionValue === "A"
    ) {
      return renderHUB(value, clsArr);
    } else {
      return renderVinner(value, clsArr);
    }
  }

  return (
    <div className={classes.container}>
      {oddsArray &&
        oddsArray.map((value, index) => {
          const clsArr = [];

          if (props.selectionId == value.selectionId) {
            clsArr.push(classes.selectedodds);
          } else {
            clsArr.push(classes.odds);
          }

          return renderChooser(value, clsArr);
        })}
    </div>
  );
}

export default OddsChooserHeading;
