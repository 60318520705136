import React from "react";
import ContentList from "./ContentList";

import rows from "../../../data/content";
import rowsnew from "../../../data/newcontent";

export default function ContentTable(props) {
  const [openModal, handleOpen] = React.useState(false);

  const openModalWindow = () => {
    handleOpen(true);
  };

  return (
    <React.Fragment>
      {props.type === "egen" && (
        <React.Fragment>
          <ContentList
            clickToOpenModal={openModalWindow}
            rows={rows}
            chosencontent={props.contentType}
            type={props.type}
            isModal={props.isModal}
            updateContent={props.updateContent}
          />
          {/*
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Vis mer
            </Button>
          </Grid>
          */}
        </React.Fragment>
      )}

      {props.type === "nytt" && (
        <React.Fragment>
          <ContentList
            clickToOpenModal={openModalWindow}
            rows={rowsnew}
            chosencontent={props.contentType}
            type={props.type}
            isModal={props.isModal}
            updateContent={props.updateContent}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
