export const daynames = [
  {
    id: 0,
    name: "Mandag",
    shortname: "Man"
  },
  {
    id: 1,
    name: "Tirsdag",
    shortname: "Tir"
  },
  {
    id: 2,
    name: "Onsdag",
    shortname: "Ons"
  },
  {
    id: 3,
    name: "Torsdag",
    shortname: "Tor"
  },
  {
    id: 4,
    name: "Fredag",
    shortname: "Fre"
  },
  {
    id: 5,
    name: "Lørdag",
    shortname: "Lør"
  },
  {
    id: 6,
    name: "Søndag",
    shortname: "Søn"
  }
];

export const potSize = [
  {
    id: 100,
    name: "100 Millioner"
  },
  {
    id: 200,
    name: "200 Millioner"
  },
  {
    id: 300,
    name: "300 Millioner"
  },
  {
    id: 400,
    name: "400 Millioner"
  },
  {
    id: 500,
    name: "500 Millioner"
  },
  {
    id: 600,
    name: "600 Millioner"
  },
  {
    id: 700,
    name: "700 Millioner"
  }
];

export const potCondition = [
  {
    id: 0,
    name: "Pott er større enn"
  },
  {
    id: 1,
    name: "Pott er mindre enn"
  }
];

const marketNames =[];
marketNames["1x2"] = "HUB Fulltid";
export const marketNamesArr = marketNames;

export const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
