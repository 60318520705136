/* eslint-disable no-script-url */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import palette from "../theme/palette";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { withRouter } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import TemplateIcon from "@material-ui/icons/Folder";
import ContentIcon from "@material-ui/icons/TextFields";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Visibility from "@material-ui/icons/Visibility";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";
import StarIcon from "@material-ui/icons/Grade";
import Schedule from "@material-ui/icons/Schedule";
import { withStyles } from "@material-ui/core/styles";

import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
  },
  cardList: {
    borderRadius: 0,
    backgroundColor: palette.common.white,
    "&:last-of-type": {
      borderBottomLeftRadius: "3px",
      borderBottomRightRadius: "3px",
    },
    "&:first-of-type": {
      borderTopLeftRadius: "3px",
      borderTopRightRadius: "3px",
    },
    padding: "0 24px",
    [theme.breakpoints.down("xs")]: {
      "&:last-of-type": {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      },
      "&:first-of-type": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      },
      padding: "0 20px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    marginTop: "1px",
    "&:hover": {
      backgroundColor: palette.greys.lighter,
    },
  },
  container: {
    height: "100%",
  },
  iconGridClass: {
    display: "flex",
  },
  depositContext: {
    margin: "3rem 0",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  inactiveIcon: {
    color: palette.text.primary,
  },
  cardListIconButtons: {
    width: 22,
    height: 22,
  },
  cardListIcon: {
    marginRight: "24px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSchedule: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 16,
    height: 16,
  },
  cardListActions: {
    [theme.breakpoints.down("xs")]: {
      padding: "12px 0",
    },
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  textEllipse: {
    justifyContent: "flex-start",
    width: "100%",
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      padding: "3px 0",
    },
  },
  alRight: {
    textAlign: "right",
    color: "#222222",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: "2.4rem",
  },
  tblPad: {
    padding: "0 8px",
  },
  scheduleIcon: {
    textAlign: "right",
    paddingRight: "8px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary,
      },
    },
  },
}))(MenuItem);

function ElementItem(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconType = props.type;
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick() {
    if (props.url === "/lenker/") {
      props.history.push(props.url);
    } else {
      props.history.push(props.url + props.id);
    }
  }

  function handleClickMeny(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function stopPropag(event) {
    event.stopPropagation();
    return;
  }
  function handleCloseDelete(event) {
    event.stopPropagation();

    props.handleDelete(props.id);
    setAnchorEl(null);
  }

  function handleCloseDeleteLink(event) {
    event.stopPropagation();

    props.handleDeleteLink(props.id);
    setAnchorEl(null);
  }

  function handleFavorites() {
    setAnchorEl(null);

    props.addToFavorites(props.id, props.favourite);
  }

  function formatStats(nr) {
    if (nr === undefined) return;
    let num = parseFloat(nr);

    if (num > 0 && num < 1) {
      return num.toFixed(3);
    } else if (num > 999) {
      return Math.round(num / 1000) + "K";
    } else {
      if (countDecimals(num) > 0) {
        return num.toFixed(2);
      } else {
        return num;
      }
    }
  }

  function countDecimals(num) {
    if (!num) return 0;
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1].length || 0;
  }
  function showContent(content) {
    if (props.type === 2) return;
    if (!content) return;

    let icon = (
      <IconButton
        disableRipple
        disableFocusRipple
        aria-label="Share"
        className={clsx(classes.button, classes.textEllipse)}
      >
        <ContentIcon
          className={clsx(classes.cardListIcon, classes.inactiveIcon)}
        />
        <Typography variant="subtitle2" noWrap>
          {content}
        </Typography>
      </IconButton>
    );
    return icon;
  }
  function showCollapsingIcon(icon) {
    return;
    /*
    let iconGrid = (
      <Grid item xs={3}>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
        >
          <ExpandMoreIcon className={classes.listIcon} />
        </IconButton>
      </Grid>
    );
    if (icon == 1) {
      iconGrid = <Grid item xs={3} />;
    }
    return iconGrid;*/
  }

  function renderArrow(trend) {
    if (trend === 1) {
      return (
        <ArrowUpward
          className={clsx(classes.upStats, classes.cardListIconSmall)}
        />
      );
    } else if (trend === -1) {
      return (
        <ArrowDownward
          className={clsx(classes.downStats, classes.cardListIconSmall)}
        />
      );
    } else {
      return (
        <span className={clsx(classes.downStats, classes.cardListIconSmall)}>
          {}
        </span>
      );
    }
  }

  let icon = (
    <PlacementIcon
      className={clsx(classes.cardListIcon, classes.inactiveIcon)}
    />
  );
  if (iconType == 2) {
    icon = (
      <TemplateIcon
        className={clsx(classes.cardListIcon, classes.inactiveIcon)}
      />
    );
  }

  return (
    <Card className={classes.cardList} square>
      <CardActions disableSpacing className={classes.cardListActions}>
        <Grid container>
          <Grid item xs={6} sm={4} onClick={handleClick}>
            <Grid container>
              <Grid item xs={10}>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  aria-label="Share"
                  className={clsx(classes.button, classes.textEllipse)}
                >
                  {icon}
                  <Typography variant="subtitle2" noWrap>
                    {props.placement}
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item xs={2} className={classes.scheduleIcon}>
                {props.contentNr > 1 && (
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    aria-label="Share"
                    className={clsx(classes.button)}
                  >
                    <Schedule
                      className={clsx(
                        classes.cardListIconSchedule,
                        classes.inactiveIcon
                      )}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} onClick={handleClick} zeroMinWidth>
            {showContent(props.content)}
          </Grid>
          <Grid item xs={12} sm={4} onClick={handleClick}>
            <Grid container spacing={0} className={classes.container}>
              <Grid item xs={4} className={classes.iconGridClass} zeroMinWidth>
                {props.ctr !== undefined && (
                  <IconButton
                    aria-label="Share"
                    className={clsx(classes.button, classes.textEllipse)}
                  >
                    <Hidden only={["sm", "md", "lg"]}>
                      <Typography variant="caption" noWrap>
                        CTR:
                      </Typography>
                    </Hidden>
                    {renderArrow(props.ctr_trend)}

                    <Typography variant="subtitle2" className={classes.alRight}>
                      {formatStats(props.ctr)}
                    </Typography>
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={4}
                className={clsx(classes.iconGridClass, classes.tblPad)}
              >
                <IconButton
                  aria-label="Share"
                  className={clsx(classes.button, classes.textEllipse)}
                >
                  <Hidden only={["sm", "md", "lg"]}>
                    <Typography variant="caption" noWrap>
                      Klikk:
                    </Typography>
                  </Hidden>
                  {renderArrow(props.clicks_trend)}

                  <Typography variant="subtitle2" className={classes.alRight}>
                    {formatStats(props.clicknr)}
                  </Typography>
                </IconButton>
              </Grid>

              <Grid item xs={4} className={classes.iconGridClass}>
                {props.ctr !== undefined && (
                  <IconButton
                    aria-label="Share"
                    className={clsx(classes.button, classes.textEllipse)}
                  >
                    <Hidden only={["sm", "md", "lg"]}>
                      <Typography variant="caption" noWrap>
                        Vist:
                      </Typography>
                    </Hidden>
                    {renderArrow(props.displays_trend)}

                    <Typography variant="subtitle2" className={classes.alRight}>
                      {formatStats(props.displays)}
                    </Typography>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={1} align="right">
              <Grid container spacing={0} alignItems="center">
                {/*<Grid item xs={6}>
                <a
                  href={props.preview_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.mrgRight}
                  onClick={stopPropag}
                >
                  <IconButton disableRipple disableFocusRipple>
                    <Visibility className={classes.cardListIconButtons} />
                  </IconButton>
                </a>
                </Grid>*/}
                <Grid item xs={12}>
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    onClick={handleClickMeny}
                  >
                    <MoreVert className={classes.cardListIconButtons} />
                  </IconButton>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem onClick={handleClick}>
                      <ListItemIcon>
                        <Edit />
                      </ListItemIcon>
                      <ListItemText primary="Rediger" />
                    </StyledMenuItem>
                    {props.url === "/lenker/" ? (
                      <StyledMenuItem onClick={handleCloseDeleteLink}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Slett" />
                      </StyledMenuItem>
                    ) : (
                      <StyledMenuItem onClick={handleCloseDelete}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Slett" />
                      </StyledMenuItem>
                    )}

                    {props.url === "/lenker/" ? (
                      ""
                    ) : (
                      <StyledMenuItem onClick={handleFavorites}>
                        <ListItemIcon>
                          <StarIcon />
                        </ListItemIcon>
                        {props.favourite === true ? (
                          <ListItemText primary="Fjern fra favoritter" />
                        ) : (
                          <ListItemText primary="Legg til favoritt" />
                        )}
                      </StyledMenuItem>
                    )}
                    {props.ver2 && (
                      <StyledMenuItem onClick={handleClick}>
                        <ListItemIcon>
                          <ContentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bytt innhold" />
                      </StyledMenuItem>
                    )}
                  </StyledMenu>
                </Grid>
                {showCollapsingIcon(iconType)}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Her kommer ekstra info</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default withRouter(ElementItem);
