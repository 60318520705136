import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";

import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";

import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import TippetipsSettingDialog from "./TippetipsSettingDialog";
import TippetipsCodeDialog from "./TippetipsCodeDialog";

import palette from "../../../theme/palette";
import { withRouter } from "react-router-dom";
import StatsContext from "../../../context/StatsContext";
import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  buttonMob: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "7px 10px",
    },
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(1),
  },
  placementlist: {
    marginBottom: "32px",
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary,
  },
  buttonMenuLeft: {
    marginRight: "10px",
  },
  outerPadding: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  centerVert: {
    alignItems: "center",
  },
  buttonTopMargin: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  card: {
    padding: "25px 40px",
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
  },
  kupongselect: {},
  previewContainer: {
    padding: "20px 45px",
    border: "1px solid #707070",
  },
}));

function TippetipsListHolder(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);

  const isMobile = useMediaQuery("(max-width:767px)");
  const statsPartnerId = statsRange.partnerId;
  const statsPartnerCode = statsRange.partnerCode;
  const [open, setOpenDialog] = useState(false);
  const [openCode, setOpenCode] = useState(false);

  const [kupong1, setKupong1] = useState("");
  const [kupong1Ttl, setKupong1Ttl] = useState("");

  const [kupong2, setKupong2] = useState("");
  const [kupong2Ttl, setKupong2Ttl] = useState("");

  useEffect(() => {
    const item1def =
      props.items && props.items[0] ? props.items[0].content_id : "";
    const item2def =
      props.items && props.items[1] ? props.items[1].content_id : "";

    const item1defname =
      props.items && props.items[0] ? props.items[0].name : "";
    const item2defname =
      props.items && props.items[1] ? props.items[1].name : "";

    const k1def = props.placementData.positions[0]
      ? props.placementData.positions[0].content[0].contentid
      : item1def;
    const k2def = props.placementData.positions[0]
      ? props.placementData.positions[0].content[1].contentid
      : item2def;
    const k1ttldef = props.placementData.positions[0]
      ? props.placementData.positions[0].content[0].tipstitle
      : item1defname;
    const k2ttldef = props.placementData.positions[0]
      ? props.placementData.positions[0].content[1].tipstitle
      : item2defname;

    setKupong1(k1def);
    setKupong1Ttl(k1ttldef);

    setKupong2(k2def);
    setKupong2Ttl(k2ttldef);
  }, [props.items]);

  function handleOpenDialog() {
    setOpenDialog(!open);
  }

  function handleCodeCloseDialog() {
    setOpenCode(!openCode);
  }
  function setCopy() {
    props.setSnackMsg("Lenken er kopiert!");
    props.setOpen(true);
  }
  function getPlacementURL() {
    return `<iframe frameborder="0" scrolling="no" allowtransparency="true" style="width:100%; height:1200px;" src="//widget.tippebannere.no/v3/iframes/NTOddsen/tippetips.aspx?p_id=${statsPartnerId}&WT.mc_id=${statsPartnerCode}_tippetipsmodul"></iframe>`;
  }

  function handleCloseDialog() {
    setOpenDialog(!open);
  }

  function saveSettings(settingsItem) {
    saveSettingsToDB(settingsItem);

    setOpenDialog(false);
  }

  function saveSettingsToDB(settingObj) {
    if (!settingObj) return;

    const doSaveSettings = async () => {
      const result = await axios.post(
        "https://nt-ekom-2.herokuapp.com/tippetips/info/" +
          "?session_key=" +
          statsRange.sid,
        { ...settingObj }
      );

      let results = result.data;

      props.setSnackMsg("Lagret!");
      props.setSnackMode("success");
      props.setOpen(true);
    };
    doSaveSettings();
  }

  function saveTippetips() {
    let kup1 = {};
    kup1.contentid = kupong1;
    kup1.contenttype = "tippekupong";
    kup1.name = kupong1Ttl;

    let kup2 = {};
    kup2.contentid = kupong2;
    kup2.contenttype = "tippekupong";
    kup2.name = kupong2Ttl;
    props.saveTippetips(kup1, kup2);
  }

  function showPublishCode() {
    setOpenCode(true);
  }

  function generateURL() {
    let urlString = "";
    let k1 = "&k1=" + kupong1;
    let k2 = "&k2=" + kupong2;
    let k1ttl = "&k1ttl=" + encodeURIComponent(kupong1Ttl);
    let k2ttl = "&k2ttl=" + encodeURIComponent(kupong2Ttl);
    return (
      `https://widget.tippebannere.no/v3/iframes/NTOddsen/tippetips.aspx?isa=1&p_id=${statsPartnerId}&WT.mc_id=${statsPartnerCode}_tippetipsmodul` +
      k1 +
      k2 +
      k1ttl +
      k2ttl
    );
  }

  function getTitle(option) {
    let resultTemplate = option.widgetoptions.filter(function (item) {
      return item.paramname == "tipstitle";
    });
    return resultTemplate;
  }
  function handleChangeKupong1(e) {
    let k1id = e.target.value;
    setKupong1(k1id);
    props.items.forEach((option) => {
      if (option.content_id === k1id) {
        let title = getTitle(option);
        setKupong1Ttl(title[0].value[0]);
        return;
      }
    });
  }

  function handleChangeKupong2(e) {
    let k2id = e.target.value;
    setKupong2(k2id);
    props.items.forEach((option) => {
      if (option.content_id === k2id) {
        let title = getTitle(option);
        setKupong2Ttl(title[0].value[0]);
        return;
      }
    });
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Card className={classes.card}>
        <Grid container spacing={0}>
          <Grid container spacing={3} className={clsx(classes.centerVert)}>
            <Grid item xs={8} align="left">
              <Typography
                variant="h1"
                component="h1"
                align="left"
                className={classes.heading}
              >
                Tippetips
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.menu}>
              <SettingsIcon onClick={handleOpenDialog} />
            </Grid>
          </Grid>
          {props.items && props.items.length < 2 && (
            <Grid item xs={12} sm={12}>
              For å publisere siden må du:
              <br />
              <br />
            </Grid>
          )}

          {props.items && props.items.length < 1 && (
            <Grid item xs={12} sm={12}>
              Steg 1: <a href="/content/sport">Lag to tippekuponger</a> <br />
              <br />
            </Grid>
          )}
          {props.items && props.items.length === 1 && (
            <Grid item xs={12} sm={12}>
              Steg 1b: <a href="/content/sport">Lag en til tippekupong</a>{" "}
              <br />
              <br />
            </Grid>
          )}

          <Grid item xs={12} className={classes.placementlist}>
            <Grid
              container
              spacing={3}
              className={clsx(classes.centerVert, classes.outerPadding)}
            >
              <Grid item xs={4} className={classes.kupongselect}>
                {props.items && props.items.length > 1 && (
                  <TextField
                    id={"filled-kupong1"}
                    select
                    label={"Kupong 1"}
                    className={classes.textField}
                    value={kupong1}
                    onChange={handleChangeKupong1}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="filled"
                    disabled={props.isDisabled}
                  >
                    {props.items &&
                      props.items.map((option) => (
                        <MenuItem
                          key={"k1" + option.content_id}
                          value={option.content_id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={4} className={classes.kupongselect}>
                {props.items && props.items.length > 1 && (
                  <TextField
                    id={"filled-kupong2"}
                    select
                    label={"Kupong 2"}
                    className={classes.textField}
                    value={kupong2}
                    onChange={handleChangeKupong2}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="filled"
                    disabled={props.isDisabled}
                  >
                    {props.items &&
                      props.items.map((option) => (
                        <MenuItem
                          key={"k2" + option.content_id}
                          value={option.content_id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={4} className={classes.kupongselect}></Grid>
            </Grid>
          </Grid>
        </Grid>

        {props.items &&
          props.items.length > 1 &&
          !props.placementData.tippetipsinfo.title && (
            <Grid item xs={12} sm={12}>
              Steg 2:{" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenDialog}
              >
                Legg inn info
              </Button>
            </Grid>
          )}

        {props.items &&
          props.items.length > 1 &&
          props.placementData.tippetipsinfo.title && (
            <Grid item xs={12} sm={12} className={classes.previewContainer}>
              <iframe
                title="preview"
                id="contentFrame"
                frameBorder="0"
                scrolling="no"
                src={generateURL()}
                style={{
                  width: "100%",
                  height: "800px",
                }}
              ></iframe>
            </Grid>
          )}
        {props.items &&
          props.items.length > 1 &&
          props.placementData.tippetipsinfo.title && (
            <Grid item xs={12} sm={12} align="right">
              <Button
                variant="contained"
                color="secondary"
                className={clsx(classes.button, classes.buttonLeft)}
              >
                Avbryt
              </Button>{" "}
              <Button
                variant="contained"
                onClick={saveTippetips}
                color="primary"
                className={clsx(classes.button, classes.buttonLeft)}
              >
                LAGRE
              </Button>{" "}
              <Button
                variant="contained"
                onClick={showPublishCode}
                color="primary"
                className={clsx(classes.button, classes.buttonLeft)}
              >
                VIS PUBLISERINGSKODE
              </Button>
            </Grid>
          )}
      </Card>
      <TippetipsSettingDialog
        isOpen={open}
        saveSettings={saveSettings}
        handleClose={handleCloseDialog}
        values={props.values}
        setValues={props.setValues}
        setSnackMsg={props.setSnackMsg}
        setSnackMode={props.setSnackMode}
        setOpen={props.setOpen}
      />
      <TippetipsCodeDialog
        isOpen={openCode}
        handleClose={handleCodeCloseDialog}
        values={props.values}
        setValues={props.setValues}
        setCopy={setCopy}
        getPlacementURL={getPlacementURL}
      />
    </Container>
  );
}

export default withRouter(TippetipsListHolder);
