import React, {useEffect} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import FilterListIcon from "@material-ui/icons/FilterList";
import OddsChooser from "./OddsChooser";
import moment from "moment";

import DeleteIcon from "@material-ui/icons/Delete";



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Start",
  },
  { id: "Match", numeric: false, disablePadding: false, label: "Match" },
  { id: "Sport", numeric: false, disablePadding: false, label: "Sport" },
  { id: "Type", numeric: false, disablePadding: false, label: "Type" },
  { id: "Utfall", numeric: false, disablePadding: false, label: "Utfall" },
  { id: "arrow", numeric: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Tooltip title="Filter list" align="right">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  betsCell: {
    maxWidth: "400px",
    minWidth: "230px",
    paddingRight: "0px",
  },
  arrowEx: {
    width: "15px",
    cursor: "pointer",
  },
  hideRow: {
    display: "none",
  },
  subCells: {
    backgroundColor: "rgba(0, 0, 0, 0.07)",
  },
}));

export default function OddsProgramChosen(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const [finalGameString, setFinalGameString] = React.useState("");
  const [updateFinalString, setUpdateFinalString] = React.useState(false);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [showMatches, setShowMatches] = React.useState([]);
  const arrBets = [];

  useEffect(() => {

    updateOddsString();

  }, [props.chosenEvents]);




  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.eventsData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function removeDot(item) {
    return item.split(".").join("");
  }

  function updateOddsString() {

    let gameString =
      "https://www.norsk-tipping.no/sport/oddsen";
    let eventString = "";
    let finalString = "";

    let betString = "";
    let betPrefixString = "";
    let gameNumber = 1;

    let numberOfBets = arrBets.length;
    arrBets.map((item) => {

        betString +=
          "" +
          item.selectionId +
          "";
        gameNumber++;
        if (gameNumber <= numberOfBets) {
          betString += ",";
        }


        if(item.eventId){
          eventString = "?event=" + item.eventId;
        }

    });

      if(betString && betString!="4000"){
        betPrefixString = "&betslip="+betString;
      }

      finalString = gameString + eventString + betPrefixString;


    if (arrBets.length < 1) {
      finalString = "";
    }

   props.updateGameURL(finalString);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function renderSubTable(tablerow) {
    const TblRow = tablerow.markets.map((bet, index) => {
      const labelId = `enhanced-table-checkbox-${index}`;
      const clsArr = [classes.betsCell];
      if (!bet.selectionId) {
        return;
      }
      if (index > 0 || showMatches === tablerow.eventId) {
        clsArr.push(classes.subCells);
      }
      const betSel = [];

      for(var i=0; i< bet.selections.length; i++){
        betSel.push(bet.selections[i].selectionOdds);
      }
      arrBets.push({
        marketId: bet.marketId,
        eventId: tablerow.eventId,
        selectionId: bet.selectionId,
        odds: betSel,
      });
      return (
        <TableRow
          hover
          onClick={(event) => handleClick(event, tablerow.startTime)}
          role="checkbox"
          tabIndex={-1}
          key={bet.marketId}
          className={clsx(clsArr.join(", "))}
        >
          <TableCell component="th" id={labelId} scope="row">
            {moment(tablerow.startTime).format("MM.D.YY HH:mm")}
          </TableCell>
          <TableCell align="left">{tablerow.homeParticipant + " - "+tablerow.awayParticipant}</TableCell>
          <TableCell align="left">{tablerow.sportName}</TableCell>
          <TableCell align="left">{bet.marketName}</TableCell>
          <TableCell align="left" className={classes.betsCell}>
            <OddsChooser
              chosenValues={bet.selections}
              marketId={tablerow.marketId}
              selectionId={bet.selectionId}
              eventId={tablerow.eventId}
              name={bet.marketName}
              updateOdds={props.updateOdds}
            />

          </TableCell>

          <TableCell align="left" className={classes.arrowEx}>
            <DeleteIcon
              onClick={(e) => props.doDelete(tablerow.eventId, bet.marketId)}
            />
          </TableCell>
        </TableRow>
      );
    });
    return TblRow;
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.chosenEvents - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.eventsData.length}
            />
            <TableBody>
              {stableSort(props.eventsData, getSorting(order, orderBy)).map(
                (row, index) => {
                  const Tbl = renderSubTable(row);
                  return Tbl;
                }
              )}


              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * 1 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {props.ver2 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            component="div"
            count={props.chosenEvents}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
