// Palette
import palette from '../palette';

export default {
  root: {
    '&.Mui-selected': {
      backgroundColor: palette.common.blue,
      borderRadius: '3px'
    },
    '&.Mui-selected:hover': {
      backgroundColor: palette.greys.lighter
    }
  },
  button: {
    '&:hover': {
      backgroundColor: palette.greys.lighter,
      color: palette.text.primary,
      borderRadius: '3px'
    }
  }
};
