// Palette
import palette from '../palette';

export default {
  root: {
    padding: '10px 16px'
  },
  subheader: {
    color: palette.common.white,
    fontSize: '1.4rem',
    fontWeight: 600
  }
};
