export default [
  {
    id: 1,
    type: 1,
    img: "flax-reise.jpg",
    url: "iframes/FlaxBanner/index.aspx?",
    placements: [
      {
        id: 1,
        type: 1,
        placement: "Forside topp",
        clicks: 212,
        ctr: 2.4,
        displays: 37321,
        tag: "forside",
        width: 481,
        widthUnit: "px",
        height: 400,
        heightUnit: "px"
      },
      {
        id: 2,
        type: 1,
        placement: "980x300",
        clicks: 412,
        ctr: 4.44,
        displays: 47321,
        tag: "insta-spill",
        width: 980,
        widthUnit: "px",
        height: 300,
        heightUnit: "px"
      }
    ],
    title: "Reiseflax",
    contenttype: "flax",
    contentitem: "reiseflax",
    contentId: 2,
    contentImg: "flax-reise.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "flax",
    width: 481,
    widthUnit: "px",
    height: 400,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "reiseflax",
            name: "reiseflax",
            label: "Reiseflax",
            selected: true
          },
          {
            key: "monopoly",
            name: "monopoly",
            label: "Monopoly"
          },
          {
            key: "flaxforlivet",
            name: "flaxforlivet",
            label: "Flax for livet"
          }
        ],
        type: "select",
        label: "Kategori",
        name: "f",
        paramname: "f",
        value: ["reiseflax"]
      }
    ]
  },
  {
    id: 2,
    type: 2,
    img: "flax-monopoly.jpg",
    url: "iframes/FlaxBanner/index.aspx?",
    placements: [
      {
        id: 4,
        title: "Tittel"
      },
      {
        id: 6,
        title: "Tittel 2"
      },
      {
        id: 7,
        title: "Tittel 2"
      },
      {
        id: 8,
        title: "Tittel 2"
      }
    ],
    title: "Monopoly",
    contenttype: "flax",
    contentitem: "monopoly",
    contentId: 2,
    contentImg: "flax-monopoly.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "flax",
    width: 980,
    widthUnit: "px",
    height: 300,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "reiseflax",
            label: "Reiseflax",
            selected: true
          },
          {
            key: "monopoly",
            label: "Monopoly"
          },
          {
            key: "flaxforlivet",
            label: "Flax for livet"
          }
        ],
        type: "select",
        label: "Kategori",
        name: "f",
        paramname: "f",
        value: ["monopoly"]
      }
    ]
  },
  {
    id: 3,
    type: 3,
    img: "flax-forlivet.jpg",
    url: "iframes/FlaxBanner/index.aspx?",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Flax for livet",
    contenttype: "flax",
    contentitem: "flaxforlivet",
    contentId: 2,
    contentImg: "flax-forlivet.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "flax",
    width: 300,
    widthUnit: "px",
    height: 300,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "reiseflax",
            label: "Reiseflax"
          },
          {
            key: "monopoly",
            label: "Monopoly"
          },
          {
            key: "flaxforlivet",
            label: "Flax for livet"
          }
        ],
        type: "select",
        label: "Kategori",
        name: "f",
        paramname: "f",
        value: ["flaxforlivet"]
      }
    ]
  },
  {
    id: 4,
    type: 4,
    img: "knapper.jpg",
    url:
      "2.0/Spillknapper.aspx?prizetxt=Før%2C%20under%20og%20etter%20kampen&chancetxt=&target=https%3A%2F%2Fwww.norsk-tipping.no%2Fsport&p=&u=&WT.mc_id=Saatchi&",
    placements: [
      {
        id: 15,
        title: "Dummy"
      }
    ],
    title: "Spill her-knapper",
    contenttype: "knapper",
    contentitem: "",
    contentId: 2,
    contentImg: "knapper.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "generell",
    width: 300,
    widthUnit: "px",
    height: 300,
    heightUnit: "px",
    widgetoptions: [
      {
        type: "select",
        label: "Kategori",
        name: "kategori",
        paramname: "s_spl",
        value: "1",
        hasChildren: true,
        items: [
          {
            key: "0",
            type: "select",
            name: "automatisk",
            paramname: "s_db",
            value: "1",
            label: "Automatisk",
            hasChildren: true,
            items: [
              {
                key: "1",
                label: "Lotteri",
                name: "Auto_lotteri"
              },
              {
                key: "2",
                name: "Auto_sport",
                label: "Sport",
                selected: true
              },
              {
                key: "3",
                name: "Auto_lotteri_og_sport",
                label: "Lotter og Sport"
              }
            ]
          },
          {
            key: "1",
            label: "Manuelt",
            type: "select",
            name: "manuelt",
            paramname: "btn",
            value: "Bingoria",
            hasChildren: true,
            items: [
              {
                key: "Bingoria",
                label: "Bingoria"
              },
              {
                key: "Oddsen",
                label: "Oddsen"
              },
              {
                key: "Kongkasino",
                label: "Kongkasino"
              },
              {
                key: "NorskTipping",
                label: "Norsk Tipping"
              }
            ]
          }
        ]
      },
      {
        type: "select",
        label: "Velg farge",
        name: "index",
        paramname: "index",
        value: "0",
        items: [
          {
            key: "0",
            label: "hvit"
          },
          {
            key: "1",
            label: "farge"
          }
        ]
      },
      {
        type: "checkbox",
        label: "Vis spill her",
        name: "s_spillher",
        paramname: "s_spillher",
        value: true
      }
    ]
  },
  {
    id: 5,
    type: 5,
    img: "htmlbanner.jpg",
    url: "iframes/RSSBanner3/index.aspx?",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "HTML banner",
    contenttype: "htmlbanner",
    contentitem: "flaxforlivet",
    contentId: 2,
    contentImg: "htmlbanner.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "generell",
    width: 700,
    widthUnit: "px",
    height: 150,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "all",
            label: "Alle"
          },
          {
            key: "lottery",
            label: "Lotteri"
          },
          {
            key: "sport",
            label: "Sport"
          },
          {
            key: "bingoria",
            label: "Bingoria"
          },
          {
            key: "flax",
            label: "Flax"
          },
          {
            key: "kongkasino",
            label: "KongKasino"
          }
        ],
        type: "select",
        label: "Kategori",
        name: "f",
        paramname: "f",
        value: "all"
      },
      {
        type: "text",
        label: "Rulleringstid",
        name: "cart",
        paramname: "cart",
        value: 6000
      },
      {
        type: "text",
        label: "Rulleringshastighet",
        name: "carv",
        paramname: "carv",
        value: 750
      }
    ]
  },
  {
    id: 7,
    type: 7,
    img: "banner.jpg",
    url: "iframes/RSSBanner/index.aspx?",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Banner",
    contenttype: "Banner",
    contentitem: "flaxforlivet",
    contentId: 2,
    contentImg: "banner.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "generell",
    width: 700,
    widthUnit: "px",
    height: 150,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "all",
            label: "Alle"
          },
          {
            key: "lottery",
            label: "Lotteri"
          },
          {
            key: "sport",
            label: "Sport"
          },
          {
            key: "33",
            label: "Bingoria"
          },
          {
            key: "35",
            label: "Flax"
          },
          {
            key: "34",
            label: "KongKasino"
          }
        ],
        type: "select",
        label: "Kategori",
        name: "f",
        paramname: "f",
        value: "all"
      },
      {
        type: "text",
        label: "Kanttykkelse",
        name: "bs",
        paramname: "bs",
        value: 0
      },
      {
        type: "checkbox",
        label: "Automatisk kantfarge fra spilltype",
        name: "bcauto",
        paramname: "bcauto",
        value: true
      },
      {
        type: "checkbox",
        label: "Bakgrunnsfarge fra kantfarge",
        name: "bf",
        paramname: "bf",
        value: true
      }
    ]
  },
  {
    id: 8,
    type: 8,
    img: "arnes.jpg",
    url: "iframes/ArnesTippetips/index.aspx",
    placements: [
      {
        id: 4,
        title: "Tittel"
      },
      {
        id: 6,
        title: "Tittel 2"
      },
      {
        id: 7,
        title: "Tittel 2"
      },
      {
        id: 8,
        title: "Tittel 2"
      }
    ],
    title: "Arne Scheies tippetips",
    contenttype: "tippetips",
    contentitem: "tippetips",
    contentId: 2,
    contentImg: "arnes.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "sport",
    width: 980,
    widthUnit: "px",
    height: 300,
    heightUnit: "px",
    widgetoptions: []
  },
  {
    id: 9,
    type: 9,
    img: "komigang.jpg",
    url: "2.0/Tallspill/TallspillKomigang.aspx",
    placements: [
      {
        id: 4,
        title: "Tittel"
      },
      {
        id: 6,
        title: "Tittel 2"
      },
      {
        id: 7,
        title: "Tittel 2"
      },
      {
        id: 8,
        title: "Tittel 2"
      }
    ],
    title: "Kom i gang",
    contenttype: "komigang",
    contentitem: "komigang",
    contentId: 2,
    contentImg: "komigang.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "generell",
    width: 980,
    widthUnit: "px",
    height: 300,
    heightUnit: "px",
    widgetoptions: []
  },
  {
    id: 10,
    type: 10,
    img: "spillvett.jpg",
    url: "iframes/Spillvett/spillvett.aspx?",
    placements: [
      {
        id: 4,
        title: "Tittel"
      }
    ],
    title: "Spillevett",
    contenttype: "spillevett",
    contentitem: "spillevett",
    contentId: 2,
    contentImg: "spillevett.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "generell",
    width: 680,
    widthUnit: "px",
    height: 300,
    heightUnit: "px",
    widgetoptions: [
      {
        type: "checkbox",
        label: "Spillvett første",
        name: "layout",
        paramname: "layout",
        value: true
      },
      {
        type: "checkbox",
        label: "Positiv tema ( color logo / mørk tekst)",
        name: "positiv",
        paramname: "positiv",
        value: true
      },
      {
        type: "checkbox",
        label: "Skjul NT logo",
        name: "hidentlogo",
        paramname: "hidentlogo",
        value: false
      },
      {
        type: "checkbox",
        label: "Skjul spillevett",
        name: "hidespillevett",
        paramname: "hidespillevett",
        value: false
      },
      {
        type: "checkbox",
        label: "Skul spillevett-lenker",
        name: "hidelinks",
        paramname: "hidelinks",
        value: false
      },
      {
        type: "checkbox",
        label: "Venstrestillt spillevett-ikon",
        name: "spillikonleft",
        paramname: "spillikonleft",
        value: false
      }
    ]
  },
  {
    id: 11,
    type: 11,
    img: "dagenspott.jpg",
    url: "2.0/Tallspill/DagensPotter.aspx?",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Dagens pott",
    contenttype: "flax",
    contentitem: "flaxforlivet",
    contentId: 2,
    contentImg: "dagenspott.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "lotteri",
    width: 300,
    widthUnit: "px",
    height: 600,
    heightUnit: "px",
    widgetoptions: []
  },
  {
    id: 12,
    type: 12,
    img: "kommendepotter.jpg",
    url: "2.0/Tallspill/Tallspill_ukens.aspx?",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Kommende potter",
    contenttype: "kommendepotter",
    contentitem: "kommendepotter",
    contentId: 2,
    contentImg: "kommendepotter.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "lotteri",
    width: 600,
    widthUnit: "px",
    height: 600,
    heightUnit: "px",
    widgetoptions: [
      {
        type: "checkbox",
        label: "Skjul disclaimer",
        name: "d",
        paramname: "d",
        value: false
      },
      {
        type: "text",
        label: "Rulleringsintervall",
        name: "rollInterval",
        paramname: "rollInterval",
        value: 4000
      },
      {
        type: "text",
        label: "Rulleringshastighet",
        name: "rollTime",
        paramname: "rollTime",
        value: 750
      }
    ]
  },
  {
    id: 13,
    type: 13,
    img: "tvsendekamper.jpg",
    url:
      "iframes/tvchannels.aspx?id=2&pid=b5311639-dfe7-4bf0-a7f0-327e41f48f41&",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "TV sendte kamper",
    contenttype: "tvsendekamper",
    contentitem: "tvsendekamper",
    contentId: 2,
    contentImg: "tvsendekamper.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "sport",
    width: 600,
    widthUnit: "px",
    height: 600,
    heightUnit: "px",
    widgetoptions: []
  },
  {
    id: 14,
    type: 14,
    img: "aktueltpaliveoddsen.jpg",
    url:
      "iframes/liveoddsen.aspx?id=2&pid=b5311639-dfe7-4bf0-a7f0-327e41f48f41&",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Aktuelt på LiveOddsen",
    contenttype: "aktueltpaliveoddsen",
    contentitem: "aktueltpaliveoddsen",
    contentId: 2,
    contentImg: "aktueltpaliveoddsen.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "sport",
    width: 600,
    widthUnit: "px",
    height: 600,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "0",
            label: "Alle"
          },
          {
            key: "70",
            label: "Fotball"
          },
          {
            key: "71",
            label: "Håndball"
          },
          {
            key: "74",
            label: "Ishockey"
          },
          {
            key: "5",
            label: "Tennis"
          }
        ],
        type: "select",
        label: "Velg idrett",
        name: "sportid",
        paramname: "sportid",
        value: "0"
      }
    ]
  },
  {
    id: 15,
    type: 15,
    img: "br-kampliste.jpg",
    url:
      "iframes/Betradar/index.aspx?widgetType=matchlist&amp;place=&amp;ekstra=&amp;WT.mc_id=Saatchi_matchlist",
    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Betradar - Kampliste",
    contenttype: "kampliste",
    contentitem: "kampliste",
    contentId: 2,
    contentImg: "br-kampliste.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "sport",
    width: 600,
    widthUnit: "px",
    height: 600,
    heightUnit: "px",
    widgetoptions: []
  },
  {
    id: 16,
    type: 16,
    img: "br-tabell.jpg",
    url: "iframes/Betradar/index.aspx?widgetType=livetable&place=&ekstra=&",

    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Betradar - Tabell",
    contenttype: "tabell",
    contentitem: "kampliste",
    contentId: 2,
    contentImg: "br-tabell.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "sport",
    width: 600,
    widthUnit: "px",
    height: 600,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "5",
            label: "Tippeliga"
          },
          {
            key: "1",
            label: "Premier League (ENG)"
          },
          {
            key: "42",
            label: "Bundesliga"
          },
          {
            key: "33",
            label: "Serie A"
          },
          {
            key: "36",
            label: "Primera Division"
          },
          {
            key: "4",
            label: "Ligue 1"
          }
        ],
        type: "select",
        label: "Velg liga",
        name: "tournamentId",
        paramname: "tournamentId",
        value: "5"
      }
    ]
  },
  {
    id: 17,
    type: 17,
    img: "br-cup.jpg",
    url: "iframes/Betradar/index.aspx?widgetType=cuproster&place=&ekstra=&",

    placements: [
      {
        id: 5,
        title: "Tittel"
      }
    ],
    title: "Betradar - Cup",
    contenttype: "cup",
    contentitem: "kampliste",
    contentId: 2,
    contentImg: "br-cup.jpg",
    clicks: 212,
    ctr: 2.4,
    displays: 37321,
    tag: "sport",
    width: 600,
    widthUnit: "px",
    height: 600,
    heightUnit: "px",
    widgetoptions: [
      {
        items: [
          {
            key: "123",
            label: "NM Cup"
          },
          {
            key: "17",
            label: "EFL Cup (ENG)"
          },
          {
            key: "43",
            label: "DFB Pokal"
          },
          {
            key: "35",
            label: "Coppa Italia"
          }
        ],
        type: "select",
        label: "Velg liga",
        name: "tournamentId",
        paramname: "tournamentId",
        value: "43"
      }
    ]
  }
];
