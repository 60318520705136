// Palette
import palette from '../palette';

export default {
  root: {
    color: palette.common.grey,
    width: '2.8rem',
    height: '2.8rem'
  },
  text: {
    fill: palette.common.black,
    fontWeight: '600',
    fontSize: '1.2rem'
  },
  active: {
    '& text': {
      fill: palette.common.white
    }
  },
  completed: {
    fill: palette.secondary.main
  }
};
