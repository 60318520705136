// Palette
import palette from '../palette';

export default {
  root: {
    fontSize: '1.4rem',
    backgroundColor: palette.common.blue,
    color: palette.primary.main,
    padding: '0 2rem',
    fontWeight: 400
  },
  colorPrimary: {
    color: palette.primary.main,
    backgroundColor: palette.common.blue
  },
  colorSecondary: {
    backgroundColor: palette.greys.lighter,
    color: palette.text.secondary
  },
  clickableColorPrimary: {
    '&:hover': {
      backgroundColor: palette.background.secondary
    },
    '&:focus': {
      color: palette.primary.main,
      backgroundColor: palette.common.blue
    }
  },
  clickableColorSecondary: {
    '&:hover': {
      backgroundColor: palette.common.blue
    },
    '&:focus': {
      color: palette.primary.main,
      backgroundColor: palette.common.blue
    }
  }
};
