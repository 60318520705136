// Palette
import palette from '../palette';

export default {
  root: {
    fontSize: '1.6rem',
    color: palette.common.black
  },
  input: {
    '&.Mui-disabled': {
      color: 'rgba(0,0,0,0.38)'
    }
  }
};
