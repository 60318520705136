import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";
import palette from "../../theme/palette";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflowX: "hidden"
  },
  mainheading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    alignItems: "center",
    display: "flex"
  },
  heading: {
    marginBottom: theme.spacing(3)
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0",
      paddingRight: "0",
      paddingTop: theme.spacing(0)
    }
  },
  iconRight: {
    marginRight: "1.2rem"
  },
  paper: {
    width: "100%",
    height: "100%",
    padding: "2.5rem"
  },
  greenPaper: {
    backgroundColor: palette.secondary.light,
    boxShadow: "none"
  },
  contactFormPaper: {
    maxWidth: "71.5rem"
  },
  bigAvatar: {
    marginRight: theme.spacing(3),
    width: 92,
    height: 92
  },
  flexDiv: {
    display: "flex",
    alignItems: "center"
  },
  flexCol: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    alignSelf: "flex-end"
  }
}));

function InfoPage(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: ""
  });
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />

      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={0}>
          <Grid container spacing={3} className={classes.centerVert}>
            <Grid item xs={12} align="left">
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.mainheading}
              >
                <InfoIcon className={classes.iconRight} /> Om NT Partnerportal
                2.0
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Paper className={clsx(classes.greenPaper, classes.paper)}>
                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Denne partnersiden har til hensikt å være en ressursside for
                  våre kommersielle samarbeidspartnere. Her vil man finne alt
                  fra materiell, teknisk dokumentasjon, informasjon. Siden
                  oppdateres med jevne mellomrom. Eventuelle spørsmål og
                  tilbakemeldinger kan gjøres via tilbakemeldingsiden eller ta
                  direkte kontakt med oss.
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} align="left">
              <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.centerVert}>
                  <Grid item xs={12} align="left">
                    <Typography
                      variant="h3"
                      component="h3"
                      align="left"
                      className={clsx(classes.flexDiv, classes.heading)}
                    >
                      Kontakt oss
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    align="left"
                    className={classes.flexDiv}
                  >
                    <Avatar
                      alt="Jon-Hroar"
                      src="/phone.png"
                      className={classes.bigAvatar}
                    />
                    <div className={classes.userInfo}>
                      <Typography variant="h3" component="h3" align="left">
                        Mikkel Aukrust
                      </Typography>
                      <Typography variant="body2" component="p" align="left">
                        Key Account Manager
                        <br />
                        mikkel.aukrust@norsk-tipping.no
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    align="left"
                    className={classes.flexDiv}
                  >
                    <Avatar
                      alt="Jon-Hroar"
                      src="/beritsvevad.png"
                      className={classes.bigAvatar}
                    />
                    <div className={classes.userInfo}>
                      <Typography variant="h3" component="h3" align="left">
                        Berit Svevad
                      </Typography>
                      <Typography variant="body2" component="p" align="left">
                        Operativ teknisk, konsept, utvikling og drift
                        <br />
                        berit.svevad@norsk-tipping.no
                        <br />
                        +47 48 26 79 26
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    align="left"
                    className={classes.flexDiv}
                  >
                    <Avatar
                      alt="Jon-Hroar"
                      src="/jonhroar.png"
                      className={classes.bigAvatar}
                    />
                    <div className={classes.userInfo}>
                      <Typography variant="h3" component="h3" align="left">
                        Jon-Hroar Nordstrøm
                      </Typography>
                      <Typography variant="body2" component="p" align="left">
                        Konseptutvikling
                        <br />
                        jon-hroar.nordstrom@norsk-tipping.no
                        <br />
                        +47 41 10 20 50
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    align="left"
                    className={classes.flexDiv}
                  >
                    <Avatar
                      alt="Jon-Hroar"
                      src="/sarah.png"
                      className={classes.bigAvatar}
                    />
                    <div className={classes.userInfo}>
                      <Typography variant="h3" component="h3" align="left">
                        Sarah Cervin
                      </Typography>
                      <Typography variant="body2" component="p" align="left">
                        Operativ produkt, konsept og salg
                        <br />
                        sarah.cervin@norsk-tipping.no
                        <br />
                        +47 95 74 20 16
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    align="left"
                    className={classes.flexDiv}
                  >
                    <Avatar
                      alt="Jon-Hroar"
                      src="/phone.png"
                      className={classes.bigAvatar}
                    />
                    <div className={classes.userInfo}>
                      <Typography variant="h3" component="h3" align="left">
                        Sentralbord
                      </Typography>
                      <Typography variant="body2" component="p" align="left">
                        sentralbord@norsk-tipping.no
                        <br />
                        +47 62 51 40 00-
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} align="left">
              <Paper className={clsx(classes.paper)}>
                <Typography
                  variant="h3"
                  component="h3"
                  align="left"
                  className={clsx(classes.flexDiv, classes.heading)}
                >
                  Retningslinjer
                </Typography>

                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Her vil du til enhver tid finne oppdaterte dokumenter vedr
                  ekommisjonærvervet og utførelsen av dette. Ved eventuelle
                  spørsmål eller uklarheter, vennligst kontakt Norsk Tipping
                  v/Sarah Cervin på epost sarah.cervin@norsk-tipping.no, eller
                  telefon 957 42 016.
                </Typography>

                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  <br />
                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2017/08/15-00620-5-Retningslinje-for-markedsf%C3%B8ring-372794_16_0.docx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Retningslinje for markedsføring
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2019/05/Retningslinjer_Natives_2019.docx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Retningslinje for natives
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2017/08/Avtale_Nettpartner_Portal_rev060613.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Avtale Nettpartner Portal
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2019/10/Teknisk_losning_og_support.docx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Krav til Teknisk løsning og Support
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2019/05/Taushetserkl%C3%A6ring-norsk_2019.docx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Sikkerhets- og taushetserklæring
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2018/05/Bilag_3_Bestemmelser_om_Markedsf%C3%B8ring_rev020210.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Bestemmelser om Markedsføring
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2018/05/Bilag_4_Merking_av_transaksjoner_rev25052013.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Merking av transaksjoner
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2019/05/Policy_for_ansvarlig_spill_2019.docx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Policy for ansvarlig spill
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2018/05/Retningslinjer_for_Nettpartnere_v2.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Retningslinjer for Nettpartnere
                  </a>
                  <br />
                  <br />

                  <a
                    href="http://norsktippingpartner.no/wp-content/uploads/2019/10/Etiske_retningslinjer_for_leverandorer.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Etiske retningslinjer
                  </a>
                </Typography>
              </Paper>
            </Grid>
            {/*
            <Grid item xs={12} align="left">
              <Paper
                className={clsx(
                  classes.paper,
                  classes.contactFormPaper,
                  classes.flexCol
                )}
              >
                <Typography
                  variant="h3"
                  component="h3"
                  align="left"
                  className={clsx(classes.flexDiv, classes.heading)}
                >
                  Tilbakemelding
                </Typography>
                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Har du en tilbakemelding kan du fylle ut og sende inn skjemaet
                  under.
                </Typography>

                <TextField
                  id="filled-name"
                  label="Din tilbakemelding"
                  className={classes.textField}
                  value={values.name}
                  onChange={handleChange('name')}
                  margin="normal"
                  variant="filled"
                  multiline
                  rows="6"
                  rowsMax="6"
                />
                <Button variant="contained" className={classes.button}>
                  Send inn
                </Button>
              </Paper>
            </Grid>
*/}
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}

export default withRouter(InfoPage);
