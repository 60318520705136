import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ArrowDropUp";
import ExpandMore from "@material-ui/icons/ArrowDropDown";

import { Typography } from "@material-ui/core";

import StarIcon from "@material-ui/icons/Grade";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import ContentIcon from "@material-ui/icons/TextFields";
import SportIcon from "@material-ui/icons/Rowing";
import LotteryIcon from "@material-ui/icons/MonetizationOn";
import InstaGameIcon from "@material-ui/icons/TouchApp";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import LinkIcon from "@material-ui/icons/Link";
import HelpIcon from "@material-ui/icons/HelpOutline";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import Divider from "@material-ui/core/Divider";

import NTIcon from "../../icons/NT";

import InfoIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 198,
    backgroundColor: "transparent",
    marginLeft: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div>
      <Typography variant="h1" component="h2" gutterBottom>
        Menu
      </Typography>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem button>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Forsiden" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PlacementIcon />
          </ListItemIcon>
          <ListItemText primary="Plasseringer" />
        </ListItem>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <ContentIcon />
          </ListItemIcon>
          <ListItemText primary="Innhold" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <NTIcon fillcolor={"#222"} />
              </ListItemIcon>
              <ListItemText primary="Generell" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <SportIcon />
              </ListItemIcon>
              <ListItemText primary="Sport" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <LotteryIcon />
              </ListItemIcon>
              <ListItemText primary="Lotteri" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <InstaGameIcon />
              </ListItemIcon>
              <ListItemText primary="Instaspill" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <DeveloperBoardIcon />
              </ListItemIcon>
              <ListItemText primary="Samlesider" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="Lenker" />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Hjelp" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Innstillinger" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Info" />
        </ListItem>
      </List>
    </div>
  );
}
