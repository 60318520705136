export default {
  root: {
    paddingLeft: 0,
    paddingRight: 0
  },
  icon: {
    marginLeft: '16px'
  },
  iconActive: {
    marginLeft: '16px'
  }
};
