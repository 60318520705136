// Palette
import palette from "../palette";

export default {
  root: {
    fontSize: "1.4rem",
    fontWeight: 400,
    color: palette.text.primary,
    minHeight: "48px !important"
  }
};
