/* eslint-disable no-script-url */

import React, { Fragment, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ElementItemCollectionsDetailsGrid from "./ElementItemCollectionsDetailsGrid";
import StatsContext from "../../../context/StatsContext";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: "1.6rem",
  },
  whitePaper: {
    backgroundColor: "#fff",
  },
}));

export default function GridCollectionsDetails(props) {
  const classes = useStyles();

  const rows = props.rows;
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;
  const inputRef = useRef(null);
  useEffect(() => {
    const width = inputRef.current.offsetWidth;
  }, [inputRef]);

  return (
    <Fragment>
      <Paper className={clsx(classes.whitePaper, classes.paper)} ref={inputRef}>
        <Grid container spacing={3}>
          {rows.map((row, index) => {
            console.log("row", row, row.position);
            return (
              <ElementItemCollectionsDetailsGrid
                key={"griddet" + row.position + index}
                index={index}
                position={row.position}
                id={row.contentid}
                gridWidth={row.width}
                gridHeight={row.height}
                items={row}
                url={row.url}
                containerWidth={inputRef}
                total={props.rows.length}
                moveArr={props.moveArr}
                changeGrid={props.changeGrid}
              />
            );
          })}
        </Grid>
      </Paper>
    </Fragment>
  );
}
