import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import MoreVert from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import ListItemText from "@material-ui/core/ListItemText";
import ContentListHolder from "../../Content/components/ContentListHolder";
import Schedule from "@material-ui/icons/Schedule";
import StatsContext from "../../../context/StatsContext";
import ContentIcon from "@material-ui/icons/TextFields";

import FormTextItem from "../../../components/FormTextItem";
import FormSelect from "../../../components/FormSelect";
import FormCheckbox from "../../../components/FormCheckbox";

import palette from "../../../theme/palette";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import { daynames, potCondition, potSize } from "../../../common/consts";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    paddingTop: 0,
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
      boxShadow: "none",
    },
  },
  noBottomPad: {
    paddingBottom: 0,
  },

  button: {
    marginTop: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  modal: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridFlexRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  menuItems: {
    color: palette.text.primary,
    fontWeight: 600,
    marginRight: theme.spacing(2),
  },
  card: {
    margin: "10px 0",
    minWidth: 275,
    width: "100%",
    padding: "1px",
    textAlign: "center",
    boxShadow: "0px 0px 0px",
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },

  pos: {
    marginBottom: 12,
  },
  media: {
    height: 238,
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary,
  },
  smallIcon: {
    verticalAlign: "text-top",
    width: 16,
    height: 16,
  },
  hasCursor: {
    cursor: "pointer",
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  mrgBtm: {
    marginBottom: "20px",
  },
  addToScroll: {
    height: "200px",
  },
  tsTitle: {
    fontWeight: 600,
  },
  contHead: {
    display: "flex",
    alignItems: "flex-start",
  },
  contHeadIcon: {
    marginRight: "16px",
  },
  contentHeading: {
    /*minHeight: "32px"*/
  },
  contentHeader: {
    margin: "0 -24px",
    width: "calc(100% + 48px)",
    padding: "24px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 40px)",
      margin: "0 -20px",
      boxSizing: "border-box",
    },
  },
  editModeBG: {
    backgroundColor: palette.common.blue,
  },
  btnMrgLft: {
    marginLeft: "52px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  btnMrgSmallLft: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },
  timeScheduleHeading: {
    margin: theme.spacing(4) + "px 0",
  },
  greenHeading: {
    backgroundColor: palette.secondary.light,
  },
  whiteHeading: {
    backgroundColor: palette.common.white,
  },
  margTop: {
    marginTop: theme.spacing(4) + "px",
  },
  mrgTop: {
    marginTop: theme.spacing(2) + "px",
  },
  alRight: {
    textAlign: "right",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  mobStatsTS: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1) + "px",
    },
  },
  mobSchedule: {
    marginTop: "8px",
  },
}));

function CollectionsContentGrid(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;
  const statsPartnerId = statsRange.partnerId;

  const [placementItem, updatePlacementItem] = useState(props.content);

  const isDefaultContent = props.id === 0 ? true : false;

  const isOpenView = props.id === 0 || !props.content.contentid ? true : false;

  const [open, setOpen] = React.useState(false);

  const [openView, setViewOpen] = React.useState(isOpenView);

  const [snackMsg, setSnackMsg] = React.useState("Lenken er kopiert!");

  const [hideHeader, setHideHeader] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let formdata = {};
  const isMobile = useMediaQuery("(max-width:768px)");

  function generateURL(values) {
    let urlString = "";

    for (var k in values.formdata) {
      if (values.formdata.hasOwnProperty(k) && values.formdata[k] !== "") {
        urlString += k + "=" + values.formdata[k].value + "&";
      }
    }
    urlString += "p_id=" + statsPartnerId + "&p=" + props.position + "&";
    let Coupon = "c_id=" + props.content.contentid;

    urlString += Coupon;
    return "https://widget.tippebannere.no/v3/" + placementItem.url + urlString;
  }
  function openCloseContent() {
    setViewOpen(!openView);
  }

  function parseFormdataOLD(data, parentName, lvl, parentKey) {
    if (!data) return;
    const level = lvl ? lvl : 0;

    data.forEach(function (item, key) {
      if (item.paramname && item.value) {
        let itemObj = {};
        let defaultValue = item.value;

        if (
          Array.isArray(defaultValue) === false &&
          item.items &&
          item.value == ""
        ) {
          defaultValue = item.items[0].key;
        } else if (Array.isArray(defaultValue)) {
          defaultValue = defaultValue[0];
        }
        itemObj.value = defaultValue;
        itemObj.name = item.name;
        itemObj.paramname = item.paramname;
        itemObj.parentName = parentName;
        itemObj.level = level;
        itemObj.key = parentKey;

        formdata[item.paramname] = itemObj;
        if (item.items) {
          parseFormdata(item.items, item.paramname, level + 1, key);
        }
      }
    });
  }

  function parseFormdata(data, parentName, lvl, parentKey, mainParent) {
    if (!data) return;
    const level = lvl ? lvl : 0;

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.paramname && item.value) {
        let itemObj = {};
        let defaultValue = item.value;

        if (Array.isArray(defaultValue) === false && item.items) {
          defaultValue = item.items[0].key;
        } else if (Array.isArray(defaultValue)) {
          defaultValue = defaultValue[0];
        }
        itemObj.value = defaultValue;
        itemObj.name = item.name;
        itemObj.paramname = item.paramname;
        itemObj.parentName = parentName;
        itemObj.parentKey = parentKey;
        itemObj.level = level;
        itemObj.key = parentKey;
        if (level === 0) {
          mainParent = defaultValue;
        }

        itemObj.mainParent = mainParent;
        if (item.items) {
          itemObj.childName = item.items[0].paramname;
          formdata[item.paramname] = itemObj;
          parseFormdata(
            item.items,
            item.paramname,
            level + 1,
            item.value[0],
            mainParent
          );
        } else {
          formdata[item.paramname] = itemObj;
        }
      }
    }
  }
  var newFormdata = {};
  //reset all levels under the changed select, add new formdata if it does not exits
  function resetFormdata(
    data,
    name,
    newValue,
    parentName,
    lvl,
    parentKey,
    mainParent
  ) {
    const level = lvl ? lvl : 0;
    /* console.log(
      "ENTER RESET ",
      data,
      name,
      newValue,
      parentName,
      lvl,
      parentKey,
      mainParent
    );*/
    //data.forEach(function(item, key) {
    let key = newValue;
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.value) {
        //console.log("RESET ", item, parentKey);
        let itemObj = {};
        let defaultValue = -1;
        item.value = null;
        //newFormdata[item.paramname] = -1;
        if (item.items) {
          resetFormdata(
            item.items,
            item.paramname,
            defaultValue,
            item.name,
            level + 1,
            newValue
          );
        }
      } else {
        //add new formdata if it does not exits
        if (item.items) {
          addFormdata(
            item,
            item.paramname,
            newValue,
            item.name,
            level + 1,
            newValue
          );
        } else {
          addFormdata(item, item.paramname, "", item.paramname, level + 1, "");
        }
      }
    }
  }
  function addFormdata(data, name, newValue, parentName, lvl, parentKey) {
    /* console.log(
      "addFormdata",
      data,
      name,
      newValue,
      parentName,
      lvl,
      parentKey
    );*/

    //console.log("DATA CHECK", data.key, parentKey);
    if (data.key.toString() != parentKey.toString()) return;
    const level = lvl ? lvl : 0;
    let defaultValue = data.key;
    let itemObj = {};
    if (!data.value && data.items) {
      defaultValue = data.items[0].key;
    }
    itemObj.value = defaultValue;
    itemObj.name = data.name;
    itemObj.paramname = data.paramname;
    itemObj.parentName = parentName;
    itemObj.level = level;
    itemObj.key = parentKey;
    //console.log("ADDING KEY", itemObj, data, parentKey);
    newFormdata[data.paramname] = itemObj;
    if (data.items) {
      resetFormdata(
        data.items,
        data.paramname,
        defaultValue,
        data.name,
        level + 1,
        newValue
      );
    }
  }

  function findItemById(data, id) {
    if (!data) return false;
    let found = false;
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.key === id) {
        found = true;
        //console.log("FOUND", item, id);
        break;
      }
    }
    return found;
  }

  function updateFormadata(
    data,
    name,
    newValue,
    parentName,
    lvl,
    parentKey,
    mainParent
  ) {
    const level = lvl ? lvl : 0;

    if (!lvl) {
      console.log(
        "ROOT UPDATING",
        data,
        name,
        newValue,
        parentName,
        lvl,
        parentKey,
        mainParent
      );
    } else {
      console.log(
        "---- CHILD UPDATING LEVEL " + lvl,
        data,
        name,
        newValue,
        parentName,
        lvl,
        parentKey,
        mainParent
      );
    }
    if (!parentKey && parentName && values.formdata[parentName]) {
      parentKey = values.formdata[parentName].value;
    }

    let found = false;
    //console.log("parentKey", parentKey);
    for (let i = 0; i < data.length; i++) {
      //data.forEach(function(item, key) {
      let item = data[i];

      if (name && item.paramname == name) {
        let itemObj = {};
        /*console.log(
          "---- ITEM NAME " + name + " " + item.paramname + " LEVEL " + lvl,
          item
        );*/
        let defaultValue = newValue;

        itemObj.value = defaultValue;
        itemObj.name = item.name;
        itemObj.paramname = item.paramname;
        itemObj.parentName = parentName;
        itemObj.level = level;

        itemObj.parentKey = parentKey;
        itemObj.level = level;
        itemObj.key = parentKey;

        if (level === 0) {
          mainParent = defaultValue;
        }

        itemObj.mainParent = mainParent;

        itemObj.key = parentKey;
        let check = findItemById(item.items, defaultValue);
        if (item.items) {
          itemObj.childName = item.items[0].paramname;
        }
        if (item.type != "select") {
          check = true;
        }
        //MATCH FOUND UPDATE FORMDATA
        if (check === true) {
          //|| !item.items
          const oldData = newFormdata[item.paramname];
          //console.log("OLD DATA", oldData);
          newFormdata[item.paramname] = itemObj;
          /*console.log(
            "UPDATING",
            defaultValue,
            item,
            item.name,
            newFormdata[item.paramname],
            parentKey,
            mainParent
          );*/
          found = true;

          //RESET ALL UNDER
          if (item.items) {
            /*console.log("ITEM", item.items[defaultValue].items[0]);
          if (!item.items[defaultValue].value) {
            item.items[defaultValue].value = [
              "" + item.items[defaultValue].items[0]["key"]
            ];
          }*/

            /*  console.log(
              "RESET FORMDATA",
              item.items,
              item.paramname,
              defaultValue,
              item.name,
              level + 1
            );*/

            resetFormdata(
              item.items,
              item.paramname,
              defaultValue,
              item.paramname,
              level + 1,
              parentKey,
              item.mainParent
            );
          }
          break;
        }
      } else {
        //CHECK HERE IF THE MAIN PARENT IS SAME
        if (item.items && !found) {
          // console.log("---- LEVEL " + (level + 1) + "-----", found);

          updateFormadata(
            item.items,
            name,
            newValue,
            item.paramname,
            level + 1,
            parentKey,
            item.mainParent
          );
        }
      }
    }

    // console.log("----EXIT updateFormadata-----");
  }

  function deleteFormadata(name, newValue, parentName) {
    //console.log("DELETE FORMDATA", name, newValue, parentName);

    newFormdata = { ...values.formdata };
    updateFormadata(placementItem.widgetoptions, name, newValue, parentName);

    setValues({ ...values, formdata: newFormdata });
  }

  parseFormdata(placementItem.widgetoptions);

  const defaultMode =
    (props.content.contentid > 0 && props.content.contentid !== 999999) ||
    props.content.contenttype !== ""
      ? "view"
      : "newwidget";

  const defaultbuttonsDisabled =
    props.content.contenttype !== "" ? true : false;
  const defaultDisabled = props.content.contentid !== "" ? true : false;

  const [values, setValues] = React.useState({
    formdata: formdata,
    isDisabled: defaultDisabled,
    buttonsDisabled: defaultbuttonsDisabled,
    mode: defaultMode,
    position: props.position,
  });

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.background.secondary,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.text.primary,
        },
      },
    },
  }))(MenuItem);

  //props.contenttype, props.contentitem, props.content
  function updateContentItem(row) {
    formdata = {};

    const contentName = row.name === undefined ? "Nytt innhold" : row.name;
    const newplacementItem = {
      widgetoptions: row.widgetoptions,
      name: contentName,
      contenttype: row.contenttype,
      contentitem: row.contentitem,
      content: row.content,
      url: row.url,
      contentImg: row.contentImg,
      contentid: row.id,
      contentoptions: props.contentoptions,
    };
    parseFormdata(row.widgetoptions);

    updatePlacementItem({
      ...placementItem,
      widgetoptions: row.widgetoptions,
      name: contentName,
      contenttype: row.contenttype,
      contentitem: row.contentitem,
      content: row.content,
      url: row.url,
      contentImg: row.contentImg,
      contentid: row.id,
      contentoptions: props.contentoptions,
    });

    props.updatePlacementContent(newplacementItem, props.id);

    setValues({
      ...values,
      formdata: formdata,
      isDisabled: true,
      buttonsDisabled: true,
      mode: "view",
    });
  }

  const handleChangeContent = (name, parentName) => (event) => {
    let newValue = event.target.value;

    // console.log(event, name, parentName);
    if (newValue && typeof newValue === "string") {
      if (newValue === "true") newValue = false;
      if (newValue === "false") newValue = true;
    }
    deleteFormadata(name, newValue, parentName);

    /*
    let newValue = event.target.value;

    if (newValue && typeof newValue === "string") {
      if (newValue === "true") newValue = false;
      if (newValue === "false") newValue = true;
    }

    let formdataitem = {
      ...values.formdata[name],
      value: newValue
    };
    let formdata = { ...values.formdata, [name]: { ...formdataitem } };

    setValues({ ...values, formdata: formdata });
    */
  };

  function chooseEditing() {
    setValues({
      ...values,
      isDisabled: true,
      buttonsDisabled: false,
      mode: "chose",
    });
    setViewOpen(true);
  }

  function enableWidgetEditing() {
    setValues({
      ...values,
      isDisabled: false,
      buttonsDisabled: false,
      mode: "edit",
    });
  }

  function chooseNewWidget() {
    setAnchorEl(null);
    setValues({
      ...values,
      mode: "newwidget",
      isDisabled: false,
      buttonsDisabled: false,
    });
  }

  function disableEditing() {
    setValues({
      ...values,
      isDisabled: true,
      buttonsDisabled: true,
      mode: "view",
    });
  }
  function updateAsNewContent() {
    updateData();
    setAnchorEl(null);
    setValues({
      ...values,
      isDisabled: true,
      buttonsDisabled: true,
      mode: "view",
    });
    setSnackMsg("Oppdatert!");
    setOpen(true);

    const newplacementItem = {
      widgetoptions: placementItem.widgetoptions,
      name: placementItem.name,
      contenttype: placementItem.contenttype,
      contentitem: placementItem.contentitem,
      content: placementItem.content,
      url: placementItem.url,
      contentImg: placementItem.contentImg,
      contentid: null,
      contentoptions: props.contentoptions,
    };

    props.updatePlacementContent(newplacementItem, props.id);
  }

  function updateContent() {
    updateData();
    setValues({
      ...values,
      isDisabled: true,
      buttonsDisabled: true,
      mode: "view",
    });
    setSnackMsg("Oppdatert!");
    setOpen(true);

    const newplacementItem = {
      widgetoptions: placementItem.widgetoptions,
      name: placementItem.name,
      contenttype: placementItem.contenttype,
      contentitem: placementItem.contentitem,
      content: placementItem.content,
      url: placementItem.url,
      contentImg: placementItem.contentImg,
      contentid: placementItem.contentid,
      contentoptions: props.contentoptions,
    };

    props.updatePlacementContent(newplacementItem, props.id);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function changeMode() {
    setValues({
      ...values,
      mode: "edit",
      isDisabled: false,
      buttonsDisabled: false,
    });
  }
  function handleClickMeny(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  const handleTitle = (name) => (event) => {
    let newValue = event.target.value;

    updatePlacementItem({ ...placementItem, [name]: newValue });
  };

  function renderTimeCondition(contentitem) {
    const dateFra = moment(contentitem.selectedDateFra).format("D. MMM YYYY");
    const dateTil = moment(contentitem.selectedDateTil).format("D. MMM YYYY");

    return (
      <>
        <span className={classes.tsTitle}>Periode:</span>
        {" " + dateFra + "-" + dateTil}
      </>
    );
  }

  function renderWeekCondition(contentitem) {
    let conditionTitle = "";

    contentitem.ukeDager.map((condition, index) => {
      let conditionDate = " " + condition.from + "-" + condition.to + ", ";

      if (condition.from == "00:00" && condition.to == "23:59") {
        conditionDate = ", ";
      }
      conditionTitle += daynames[condition.day].shortname + conditionDate;
    });

    return (
      <>
        <span className={classes.tsTitle}>Ukedager:</span>
        {" " + conditionTitle}
      </>
    );
  }

  function renderPottCondition(contentitem) {
    const pCond = potCondition[contentitem.pott].name;
    const pSizeItem = potSize.filter(function (item) {
      return item.id === contentitem.pottSize;
    });

    const pSize = pSizeItem[0].name;
    return (
      <>
        <span className={classes.tsTitle}>Pott:</span>
        {" > " + pSizeItem[0].id + " mill"}
      </>
    );
  }

  function renderTitle() {
    let contentDescription = [];

    if (props.contentoptions) {
      props.contentoptions.map((condition, index) => {
        if (condition.option === 1) {
          contentDescription.push(renderTimeCondition(condition));
        } else if (condition.option === 2) {
          contentDescription.push(renderWeekCondition(condition));
        } else if (condition.option === 3) {
          contentDescription.push(renderPottCondition(condition));
        }
      });
    }

    return contentDescription;
  }

  function countDecimals(num) {
    if (!num) return 0;
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1].length || 0;
  }

  function formatStats(nr) {
    if (nr === undefined) return;
    let num = parseFloat(nr);

    if (num > 1 && num < 1) {
      return num.toFixed(3);
    } else if (num > 999) {
      return Math.round(num / 1000) + "K";
    } else {
      if (countDecimals(num) > 0) {
        return num.toFixed(2);
      } else {
        return num;
      }
    }
  }

  function renderHeader(mode) {
    let contentDescription = placementItem.name;
    let numUsedInPlacement = placementItem.numTimesUsed;
    numUsedInPlacement =
      numUsedInPlacement > 1
        ? `NB: Brukes på ${numUsedInPlacement} plasseringer`
        : "";
    if (mode === "view") {
      return (
        <>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              component="h1"
              align="left"
              className={classes.contentHeading}
            >
              {contentDescription}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.gridFlexRight}>
            <Edit
              className={clsx(
                classes.hasCursor,
                classes.bigIcon,
                classes.btnMrgSmallLft
              )}
              onClick={chooseEditing}
            />
          </Grid>
        </>
      );
    } else if (mode === "edit") {
      return (
        <>
          {!isMobile && (
            <Grid item xs={6}>
              <Typography
                variant="h1"
                component="h1"
                align="left"
                className={classes.contentHeading}
              >
                {contentDescription}
              </Typography>

              <Typography
                variant="body2"
                component="h6"
                align="left"
                className={clsx(classes.contentHeading, classes.mrgTop)}
              >
                {numUsedInPlacement}
              </Typography>
            </Grid>
          )}

          {isMobile && (
            <>
              <Grid item xs={6}>
                <Typography
                  variant="h4"
                  component="h4"
                  align="left"
                  className={classes.contentHeading}
                >
                  Endre innhold
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridFlexRight}>
                <Button color="primary" onClick={disableEditing}>
                  AVBRYT
                </Button>

                <Button
                  color="primary"
                  onClick={updateContent}
                  className={classes.btnMrgLft}
                >
                  LAGRE
                </Button>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  onClick={handleClickMeny}
                >
                  <MoreVert className={classes.bigIcon} />
                </IconButton>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem onClick={updateAsNewContent}>
                    <ListItemText primary="Lag nytt innhold" />
                  </StyledMenuItem>
                </StyledMenu>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h6"
                  align="left"
                  className={clsx(classes.contentHeading, classes.mrgTop)}
                >
                  {numUsedInPlacement}
                </Typography>
              </Grid>
            </>
          )}

          {!isMobile && (
            <Grid item xs={6} className={classes.gridFlexRight}>
              <Button color="primary" onClick={disableEditing}>
                AVBRYT
              </Button>
              <Button
                color="primary"
                onClick={updateAsNewContent}
                className={classes.btnMrgLft}
              >
                LAG NYTT INNHOLD
              </Button>
              <Button
                color="primary"
                onClick={updateContent}
                className={classes.btnMrgLft}
              >
                LAGRE
              </Button>
            </Grid>
          )}
        </>
      );
    } else if (mode === "newwidget") {
      return (
        <>
          <Grid item xs={8}>
            {!isMobile && (
              <Typography
                variant="h1"
                component="h1"
                align="left"
                className={classes.contentHeading}
              >
                Legg til innhold
              </Typography>
            )}
            {isMobile && (
              <Typography
                variant="h4"
                component="h4"
                align="left"
                className={classes.contentHeading}
              >
                Endre innhold
              </Typography>
            )}
          </Grid>

          <Grid item xs={4} className={classes.gridFlexRight}>
            {mode === "newwidget" &&
              props.content.contentid &&
              props.content.contentid != 999999 && (
                <Button color="primary" onClick={disableEditing}>
                  AVBRYT
                </Button>
              )}
          </Grid>
        </>
      );
    } else if (mode === "chose") {
      return (
        <>
          {!isMobile && (
            <>
              <Grid item xs={12} md={5}>
                <Typography
                  variant="h1"
                  component="h1"
                  align="left"
                  className={classes.contentHeading}
                >
                  Endre innhold
                </Typography>
              </Grid>
              <Grid item xs={12} md={7} className={classes.gridFlexRight}>
                <Button color="primary" onClick={disableEditing}>
                  AVBRYT
                </Button>
                <Button
                  color="primary"
                  onClick={enableWidgetEditing}
                  className={classes.btnMrgLft}
                >
                  REDIGER INNHOLD
                </Button>
                <Button
                  color="primary"
                  onClick={chooseNewWidget}
                  className={classes.btnMrgLft}
                >
                  ERSTATT INNHOLD
                </Button>
              </Grid>
            </>
          )}

          {isMobile && (
            <>
              <Grid item xs={5} md={5}>
                <Typography
                  variant="h4"
                  component="h4"
                  align="left"
                  className={classes.contentHeading}
                >
                  Endre innhold
                </Typography>
              </Grid>
              <Grid item xs={5} md={7} className={classes.gridFlexRight}>
                <Button color="primary" onClick={disableEditing}>
                  AVBRYT
                </Button>
                <Button
                  color="primary"
                  onClick={enableWidgetEditing}
                  className={classes.btnMrgLft}
                >
                  REDIGER
                </Button>
              </Grid>
              <Grid item xs={2} md={7} className={classes.gridFlexRight}>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  onClick={handleClickMeny}
                >
                  <MoreVert className={classes.bigIcon} />
                </IconButton>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem onClick={chooseNewWidget}>
                    <ListItemText primary="Erstatt innhold" />
                  </StyledMenuItem>
                </StyledMenu>
              </Grid>
            </>
          )}
        </>
      );
    }
  }

  function updateData() {
    for (var k in values.formdata) {
      if (values.formdata.hasOwnProperty(k) && values.formdata[k] !== "") {
        //  console.log("%c LOOPING FORMDATA ", "background: red; color: #fff", k);

        updateDataItem(
          values.formdata[k],
          placementItem.widgetoptions,
          "start"
        );
      }
    }
  }
  /*CHECK THIS - YOU NEED TO UPDATE contentItem.widgetoptions */
  function updateDataItem(formdataItem, arr, sub, patharr) {
    //    console.log("updateDataItem", arr, formdataItem, sub, patharr);
    let found = false;
    if (!arr) return;
    if (!patharr) patharr = [];
    let widgetItem = arr.filter(function (item) {
      return item.paramname == formdataItem.paramname;
    });
    //FOUND MATCH
    if (widgetItem.length > 0 && widgetItem[0].value) {
      /*  console.log(
        "%c update WIDGET value",
        "background: #bada55; color: #fff",
        formdataItem,
        widgetItem[0].value
      );*/
      widgetItem[0].value[0] = formdataItem.value + "";
      return;
    } else {
      for (let i = 0; i < arr.length; i++) {
        let newArr = arr[i];

        //console.log("KEY", formdataItem.parentKey, newArr.key, newArr);
        if (newArr.paramname == formdataItem.paramname) {
          // console.log("SAME PARAM NAME", formdataItem, newArr);
          //FOUND MATCH
          if (findItemById(newArr.items, formdataItem.value)) {
            newArr.value = [formdataItem.value];
            found = true;
            /* console.log(
              "%c FOUND MATCH IN ITEMS",
              "background: #bada55; color: #fff",
              formdataItem,
              newArr.value
            );*/
            break;
          } else if (!newArr.items) {
            newArr.value = [formdataItem.value];
            /* console.log(
              "%c FOUND MATCH",
              "background: #bada55; color: #fff",
              formdataItem,
              newArr.value
            );*/
            found = true;
            break;
          }
        } else {
          if (newArr.items) {
            patharr.push(newArr.paramname);
            updateDataItem(formdataItem, newArr.items, "sub", patharr);
          }
          // console.log("NOT FOUND - NEXT LEVEL", formdataItem, newArr);
        }
        //}
      }
    }
  }

  let newitems = [];
  function processData(data, selectedItem) {
    if (!data) return;

    data.forEach(function (item, key) {
      if (!values.formdata[item.paramname]) return;

      if (item.type === "select") {
        if (
          item.items &&
          item.items.length > 0 &&
          (selectedItem == item.key || selectedItem === "start")
        ) {
          newitems.push(
            <FormSelect
              key={item.paramname}
              item={item}
              onChange={handleChangeContent}
              value={values.formdata[item.paramname].value}
              isDisabled={values.isDisabled}
              isStart={true}
            />
          );

          if (values.formdata[item.paramname].value) {
            processData(item.items, values.formdata[item.paramname].value);
          }
        } else if (!item.items && item.type != "select") {
          newitems.push(
            <FormSelect
              key={item.value && item.value ? item : item.key}
              item={item}
              onChange={handleChangeContent}
              value={values.formdata[item.paramname].value}
              isDisabled={values.isDisabled}
              isStart={true}
            />
          );
        }
      } else if (item.type === "text") {
        let val = values.formdata[item.paramname].value;

        newitems.push(
          <FormTextItem
            key={item.paramname}
            item={item}
            onChange={handleChangeContent}
            value={val}
            isDisabled={values.isDisabled}
          />
        );
      } else if (item.type === "checkbox") {
        newitems.push(
          <FormCheckbox
            key={item.name}
            item={item}
            onChange={handleChangeContent}
            value={values.formdata[item.paramname].value}
            isDisabled={values.isDisabled}
          />
        );

        if (
          values.formdata[item.paramname].value &&
          values.formdata[item.paramname].value != "false"
        ) {
          processData(item.items, values.formdata[item.paramname].value);
        }
      }
    });

    return newitems;
  }

  processData(placementItem.widgetoptions, "start");
  let iframeWidth, iframeHeight;
  let contWidth = props.divWidth - 150;
  if (isMobile) {
    iframeWidth = "280px";
    iframeHeight = "280px";
  } else {
    if (props.contentWidthUnit === "%") {
      iframeWidth = props.contentWidth + props.contentWidthUnit;
      iframeHeight = props.contentHeight + props.contentHeightUnit;
    } else {
      if (props.contentWidth > contWidth) {
        iframeWidth = contWidth + props.contentWidthUnit;
        iframeHeight = props.contentHeight + props.contentHeightUnit;
      } else {
        iframeWidth = props.contentWidth + props.contentWidthUnit;
        iframeHeight = props.contentHeight + props.contentHeightUnit;
      }
    }
  }

  return (
    <>
      <iframe
        title="preview"
        id={"contentFrame" + props.position}
        frameBorder="0"
        scrolling="no"
        data-pos={props.position}
        src={generateURL(values)}
        style={{
          width: iframeWidth,
          height: iframeHeight,
          border: "2px solid #666666",
        }}
      ></iframe>
    </>
  );
}
export default CollectionsContentGrid;
