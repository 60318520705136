import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import LinksStepper from "./LinksStepper";

const styles = theme => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500]
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
});

const useStyles = makeStyles(theme => ({
  dialogWin: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 !important"
    }
  },
  buttonMobile: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 0 8px 0 !important"
    }
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/*
'root'
  | 'scrollPaper'
  | 'scrollBody'
  | 'container'
  | 'paper'
  | 'paperScrollPaper'
  | 'paperScrollBody'
  | 'paperWidthFalse'
  | 'paperWidthXs'
  | 'paperWidthSm'
  | 'paperWidthMd'
  | 'paperWidthLg'
  | 'paperWidthXl'
  | 'paperFullWidth'
  | 'paperFullScreen';
*/
const DialogContainer = withStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "200 !important"
    }
  },
  paperFullWidth: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      padding: "0",
      height: "100%",
      maxHeight: "100%"
    }
  }
}))(Dialog);

const DialogContent = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  }
}))(MuiDialogActions);

function LinksDialog(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <DialogContainer
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
        fullWidth={true}
        maxWidth="xl"
        className={classes.dialogWin}
      >
        <DialogTitle id="customized-dialog-title">Linkgenerator</DialogTitle>

        <DialogContent>
          <LinksStepper
            handleClose={props.handleCloseDialog}
            handleNext={props.handleNext}
            handleBack={props.handleBack}
            handleReset={props.handleReset}
            activeStep={props.activeStep}
            steps={props.steps}
            values={props.values}
            setValues={props.setValues}
            saveNewRow={props.saveNewRow}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            variant="contained"
            color="secondary"
            className={classes.buttonMobile}
          >
            AVBRYT
          </Button>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            variant="contained"
            color="secondary"
            className={classes.buttonMobile}
          >
            TILBAKE
          </Button>

          <Button
            onClick={props.handleNext}
            variant="contained"
            color="primary"
            disabled={props.values.isValidated === props.activeStep}
            className={classes.buttonMobile}
          >
            {props.activeStep === props.steps.length - 1 ? "AVSLUTT" : "VIDERE"}
          </Button>
        </DialogActions>
      </DialogContainer>
    </React.Fragment>
  );
}

export default LinksDialog;
