import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AllTypography from "../components/all/allTypography";
import AllButtons from "../components/all/allButtons";
import AllInputs from "../components/all/allInputs";
import AllSnackbars from "../components/all/allSnackbars";
import AllChips from "../components/all/allChips";
import AllSteppers from "../components/all/allSteppers";
import AllAppBar from "../components/all/allAppBar";
import AllDrawer from "../components/all/allDrawer";
import AllTabs from "../components/all/allTabs";
import AllDialogs from "../components/all/allDialogs";
import AllRadios from "../components/all/allRadios";
import AllChecks from "../components/all/allChecks";
import AllMenu from "../components/all/allMenu";
import AllCards from "../components/all/allCards";
import AllPaper from "../components/all/allPaper";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 980,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(10)
  }
}));

export default function AllComponents(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AllAppBar />
      <AllDrawer />
      <AllTypography />
      <AllMenu />
      <AllChips />
      <AllButtons />
      <AllInputs />
      <AllRadios />
      <AllChecks />
      <AllSnackbars />
      <AllTabs />
      <AllSteppers />
      <AllDialogs />
      <AllCards />
      <AllPaper />
    </div>
  );
}
