/* eslint-disable no-script-url */

import React from "react";

import { makeStyles } from "@material-ui/styles";

import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import MoreVert from "@material-ui/icons/MoreVert";

import palette from "../theme/palette";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  iconStyles: {
    verticalAlign: "middle"
  },
  smallIcon: {
    verticalAlign: "middle",
    width: 16,
    height: 16
  },
  noPadd: {
    padding: "0 !important",
    color: palette.common.white,
    borderBottom: "none",
    verticalAlign: "middle",
    fontWeight: 600,
    marginRight: "30px",
    [theme.breakpoints.down("md")]: {
      marginRight: "10px",
      display: "flex"
    }
  },
  downStats: {
    color: palette.error.main
  },
  upStats: {
    color: palette.secondary.main
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 16,
    height: 16
  },
  bigIcon: {
    width: 24,
    height: 24,
    color: palette.common.white
  },
  bigIconBlack: {
    width: 24,
    height: 24,
    color: palette.common.black
  },
  hasCursor: {
    cursor: "pointer"
  },
  menuStats: {
    display: "flex"
  }
}));

export default function MenuStats(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  let clickNr = props.clicks;
  let clickTrends = props.clicks_trend;
  let ctrNr = props.ctr;
  let ctrTrend = props.ctr_trend;
  let viewsNr = props.views;
  let viewTrends = props.views_trend;

  if (!props.clicks) {
    clickNr = 0;
  }
  if (!props.ctr) {
    ctrNr = 0;
  }
  if (!props.views) {
    viewsNr = 0;
  }

  function countDecimals(num) {
    if (!num) return 0;
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1].length || 0;
  }

  function formatStats(nr) {
    if (nr === undefined) return;
    let num = parseFloat(nr);

    if (num > 0 && num < 1) {
      return num.toFixed(3);
    } else if (num > 999) {
      return Math.round(num / 1000) + "K";
    } else {
      if (countDecimals(num) > 0) {
        return num.toFixed(2);
      } else {
        return num;
      }
    }
  }

  function renderArrow(trend) {
    if (trend === 1) {
      return (
        <ArrowUpward
          className={clsx(classes.upStats, classes.cardListIconSmall)}
        />
      );
    } else if (trend === -1) {
      return (
        <ArrowDownward
          className={clsx(classes.downStats, classes.cardListIconSmall)}
        />
      );
    } else {
      return (
        <span className={clsx(classes.downStats, classes.cardListIconSmall)}>
          {}
        </span>
      );
    }
  }

  let menuStr = (
    <div className={classes.menuStats}>
      <div className={classes.noPadd}>
        {" "}
        CTR
        {renderArrow(ctrTrend)} {formatStats(ctrNr)}
      </div>
      <div className={classes.noPadd}>
        {" "}
        Klikk {renderArrow(clickTrends)}
        {formatStats(clickNr)}
      </div>

      <div className={classes.noPadd}>
        {" "}
        Vist {renderArrow(viewTrends)} {formatStats(viewsNr)}
      </div>
    </div>
  );

  if (props.noMenu) {
    menuStr = (
      <div>
        <div className={classes.noPadd} align="right">
          {" "}
          <MoreVert className={classes.bigIconBlack} />{" "}
        </div>
      </div>
    );
  }
  return <React.Fragment>{menuStr}</React.Fragment>;
}
