import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import palette from "../../../theme/palette";

import Paper from "@material-ui/core/Paper";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  mainheading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    alignItems: "center",
    display: "flex"
  },
  heading: {
    marginBottom: theme.spacing(3)
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  iconRight: {
    marginRight: "1.2rem"
  },
  paper: {
    width: "100%",
    height: "100%",
    padding: "2.5rem"
  },
  greenPaper: {
    backgroundColor: palette.secondary.light,
    boxShadow: "none"
  },
  contactFormPaper: {
    maxWidth: "71.5rem"
  },
  bigAvatar: {
    marginRight: theme.spacing(3),
    width: 92,
    height: 92
  },
  flexDiv: {
    display: "flex",
    alignItems: "center"
  },
  flexCol: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    alignSelf: "flex-end"
  }
}));

function AdminPage(props) {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />

      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={0}>
          <Grid container spacing={3} className={classes.centerVert}>
            <Grid item xs={12} align="left">
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.mainheading}
              >
                NT Partnerportal 2.0 Admin
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Paper className={clsx(classes.greenPaper, classes.paper)}>
                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Her har du som administrator muligheten til å legge
                  til/redigere feature-modul og forsidenyheter, i tillegg til å
                  administrere partnere, brukere og retningslinjer.
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} align="left">
              <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.centerVert}>
                  <Grid item xs={12} align="left">
                    <Typography
                      color="textPrimary"
                      className={classes.depositContext}
                    >
                      <Link to="/admin/front">
                        Feature-modul og forsidenyhet
                      </Link>
                      <br />
                      <br />
                      <Link to="/admin/files">
                        {" "}
                        Administrere retningslinjer
                      </Link>

                      <br />
                      <br />

                      <Link to="/admin/users">
                        Partner og brukeradministrasjon
                      </Link>

                      <br />
                      <br />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}

export default withRouter(AdminPage);
