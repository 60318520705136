import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import palette from "../../theme/palette";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ContentIcon from "@material-ui/icons/TextFields";

import ContentListItem from "../../views/Content/components/ContentListItem";
import ContentTemplateListItem from "../../views/Content/components/ContentTemplateListItem";
import ElementItem from "../ElementItem";
import DashInfoBig from "../../views/Frontpage/components/DashInfoBig";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 245
  },
  media: {
    height: 164
  },
  cardMedium: {
    maxWidth: 350
  },
  mediaMedium: {
    height: 204
  },

  cardBig: {
    maxWidth: 716
  },
  mediaBig: {
    minHeight: 286
  },
  cardList: {
    maxWidth: 1083
  },
  cardListActions: {
    padding: 0
  },
  cardListIconSmall: {
    marginRight: "5px",
    color: palette.text.secondary,
    width: 16,
    height: 16
  },
  cardListIcon: {
    marginRight: "5px",
    color: palette.text.secondary,
    width: 22,
    height: 22
  },
  absHeader: {
    position: "absolute",
    backgroundColor: "rgba(38, 20, 64, 0.8)",
    width: 716,
    zIndex: 100
  },
  cardMenu: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  circleNumber: {
    border: "1px solid " + palette.text.secondary,
    borderRadius: "50%",
    padding: "5px",
    margin: "0 10px",
    width: "24px",
    height: "24px",
    textAlign: "center",
    fontSize: "1.2rem",
    lineHeight: 1
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  flexHeading: {
    display: "flex",
    alignItems: "center"
  },
  mediumIcon: {
    width: 20,
    height: 20,
    marginRight: "5px"
  },
  largeIcon: {
    width: 22,
    height: 22,
    color: palette.greys.dark
  },
  listIcon: {
    width: 22,
    height: 22,
    color: palette.greys.dark
  },
  downStats: {
    color: palette.error.main
  },
  upStats: {
    color: palette.secondary.main
  }
}));

export default function MediaCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  function updateContent() {
    console.log("s");
  }
  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <div>
      <Typography variant="h1" component="h2" gutterBottom>
        Cards
      </Typography>
      <br />
      <ContentListItem
        key="3"
        id="3"
        doOpenModal={handleExpandClick}
        img="dummy-viking.png"
        heading="Vikingloto"
        description=" Lorem ipsum dolor amet lumbersexual authentic pug."
        type="Lotteri"
        placements={[]}
        isModal={false}
        updateContent={updateContent}
      />
      <br />
      <ContentTemplateListItem
        key="1"
        id="1"
        doOpenModal={handleExpandClick}
        img="dummy-viking.png"
        heading="Vikingloto"
        description=" Lorem ipsum dolor amet lumbersexual authentic pug."
        type="Lotteri"
      />

      <br />
      <Card className={classes.cardMedium}>
        <CardActionArea>
          <CardMedia
            className={classes.mediaMedium}
            image="dummycard-medium.png"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography
              variant="h4"
              component="h4"
              className={classes.flexHeading}
            >
              <ContentIcon className={classes.mediumIcon} />
              Registrer deg for å spille
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <br />
      <DashInfoBig />

      <br />
      <ElementItem
        key="2"
        type="1"
        placement="Forside topp"
        content="Påskeflax"
        contentid="2"
        contentImg="flax.png"
        clicknr="212"
        ctr="2.4"
        displays="333"
        tag="Forside"
        url="/placements/edit/"
        id="3"
        zeroMinWidth
      />
    </div>
  );
}
