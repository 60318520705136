// Palette
import palette from '../palette';

export default {
  list: {
    fontSize: '1.4rem',
    backgroundColor: palette.common.white,
    color: palette.common.white
  }
};
