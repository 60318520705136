import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";

import LoginCard from "./components/LoginCard";
import LoginForgotPassword from "./components/LoginForgotPassword";
import LoginReset from "./components/LoginReset";
import Logout from "./components/Logout";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    width: "100%",
  },
  container: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(4, 4),
    marginTop: "54px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginTop: "154px",
      width: "417px",
      minHeight: "485px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  button: {
    marginTop: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  fullWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function Login(props) {
  const classes = useStyles();
  let currentComponent = <LoginCard />;

  if (props.match.path === "/forgot") {
    currentComponent = <LoginForgotPassword />;
  } else if (props.match.path === "/reset/:token") {
    currentComponent = <LoginReset />;
  } else if (props.match.path === "/logout") {
    currentComponent = <Logout />;
  }

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} className={classes.fullWidth}>
              <Paper className={classes.paper}>{currentComponent}</Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Login);
