/* eslint-disable no-script-url */

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import palette from "../../../theme/palette";
import TextField from "@material-ui/core/TextField";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { withRouter } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import OddsChooserItem from "./OddsChooserItem";
import OddsChooserHeading from "./OddsChooserHeading";
import moment from "moment";
import Button from "@material-ui/core/Button";

import ExpandLess from "@material-ui/icons/ArrowDropUp";
import ExpandMore from "@material-ui/icons/ArrowDropDown";
import StarIconOutlined from "@material-ui/icons/GradeOutlined";
import Schedule from "@material-ui/icons/Schedule";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
  },
  cardList: {
    borderRadius: 0,
    backgroundColor: palette.common.white,
    cursor: "pointer",
    "&:last-of-type": {
      borderBottomLeftRadius: "3px",
      borderBottomRightRadius: "3px",
      borderBottom: "1px solid rgba(0.34,0.34,0.34,0.2)",
    },
    "&:first-of-type": {
      borderTopLeftRadius: "3px",
      borderTopRightRadius: "3px",
    },
    padding: "0 24px",
    [theme.breakpoints.down("xs")]: {
      "&:last-of-type": {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      },
      "&:first-of-type": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      },
      padding: "0 20px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    marginTop: "1px",
    "&:hover": {
      backgroundColor: palette.greys.lighter,
    },
  },
  containerOdds: {
    height: "100%",
    overflow: "hidden",
  },
  btnIcons: {
    justifyContent: "space-between",
  },
  iconGridClass: {
    display: "flex",
  },
  depositContext: {
    margin: "3rem 0",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  inactiveIcon: {
    color: palette.text.primary,
  },
  cardListIconButtons: {
    width: 22,
    height: 22,
  },
  cardListIcon: {
    marginRight: "24px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSchedule: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 16,
    height: 16,
  },
  cardListActions: {
    [theme.breakpoints.down("xs")]: {
      padding: "12px 0",
    },
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  textEllipse: {
    justifyContent: "flex-start",
    width: "100%",
  },
  button: {
    cursor: "inherit",
    [theme.breakpoints.down("xs")]: {
      padding: "3px 0",
    },
  },
  centAlg: {
    alignSelf: "center",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  rightAlg: {
    justifyContent: "flex-end",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  paddLeft: {
    paddingLeft: "16px",
  },
  btnRightPad: {
    marginRight: "12px",
  },
  alRight: {
    textAlign: "right",
    color: "#222222",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: "2.4rem",
  },
  tblPad: {
    padding: "0 8px",
  },
  scheduleIcon: {
    textAlign: "right",
    paddingRight: "8px",
  },
  fadeoutRow: {
    opacity: 0.2,
    cursor: "default",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary,
      },
    },
  },
}))(MenuItem);

const publishon = [
  {
    label: "Umiddelbart",
    value: 0,
    key: "k1",
  },
  {
    label: "På kampdagen",
    value: 4,
    key: "k2",
  },
];

const priority = [
  {
    label: "Nei",
    value: 0,
    key: "k3",
  },
  {
    label: "Ja, på kampdagen",
    value: 2,
    key: "k5",
  },
];

function ElementItemOdds(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconType = props.type;
  let defaultOpen = false;

  if (props.editMode && props.id) {
    defaultOpen = false;
  } else if (props.editMode && !props.id) {
    defaultOpen = true;
  }
  const [expanded, setExpanded] = React.useState(defaultOpen);
  const [openContent, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    title: props.title,
    preamble: props.tipsingress,
    description: props.tipstext,
    publish: props.publish ? props.publish : 0,
    priority: props.priority ? props.priority : 0,
    displayorder: props.displayorder,
  });

  function handleExpandClick() {
    if (props.editMode && props.id) {
      return;
    }
    setExpanded(!expanded);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick() {
    if (props.url === "/lenker/") {
      props.history.push(props.url);
    } else {
      props.history.push(props.url + props.eventId);
    }
  }

  function handleClickMeny(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function stopPropag(event) {
    event.stopPropagation();
    return;
  }
  function handleCloseDelete(event) {
    if (props.editMode && props.id) {
      return;
    }
    props.doDelete(
      props.bet.eventId,
      props.bet.marketId,
      props.bet.selectionId,
      props.id
    );
  }

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  function moveItemUp() {
    if (props.editMode && props.id) {
      return;
    }
    if (props.index > 0 && props.id) {
      props.moveArr(props.index, props.index - 1);
    }
  }

  function moveItemDown() {
    if (props.editMode && props.id) {
      return;
    }
    if (props.index < props.total - 1 && props.id) {
      props.moveArr(props.index, props.index + 1);
    }
  }

  function closeTip() {
    if (props.id) {
      /*
      props.updateOdds(
        props.bet.matchid,
        props.bet.betid,
        props.bet.selected,
        props.id,
        false
      );
      */
      setValues({
        title: props.title,
        preamble: props.tipsingress,
        description: props.tipstext,
        publish: props.publish ? props.publish : 0,
        priority: props.priority ? props.priority : 0,
        displayorder: props.displayorder,
      });
      setExpanded(false);
    } else {
      props.doDelete(props.bet.eventId, props.bet.marketId, props.bet.selectionId);
    }
  }

  function saveTip() {

    props.saveTips(
      props.eventId,
      props.bet.marketId,
      props.bet.marketName,
      props.bet.selectionId,
      values.title,
      values.preamble,
      values.description,
      values.priority,
      values.publish,
      props.id,
      props.index,
      props.bettime
    );
  }

  const clsArr = [classes.cardList];
  if (props.editMode && props.id) {
    clsArr.push(classes.fadeoutRow);
  }

  useEffect(() => {
    if (props.editMode && props.id && expanded) {
      setExpanded(false);
    }
  }, [props.editMode]);

  useEffect(() => {
    if (props.editId === props.id && !expanded) {
      setExpanded(true);
      props.setEditId(false);
    } else if (props.editId && props.editId != props.id && expanded) {
      setExpanded(false);
    }
  }, [props.editId, expanded]);

  const clsMoveBtnArr = [classes.button, classes.menuIcons];
  if (!props.id) {
    clsMoveBtnArr.push(classes.fadeoutRow);
  }

  return (
    <Card className={clsx(clsArr.join(" "))} square>
      <CardActions disableSpacing className={classes.cardListActions}>
        <Grid container>
          <Grid item xs={2} sm={2} onClick={handleExpandClick}>
            <Grid container>
              <Grid item xs={8}>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  aria-label="Share"
                  className={clsx(classes.button, classes.textEllipse)}
                >
                  <Typography variant="subtitle2" noWrap>
                    {moment(props.matchtime).format("D.MM.YY HH:mm")}
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                {props.priority > 0 && (
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    aria-label="Share"
                    className={clsx(classes.button, classes.textEllipse)}
                  >
                    <StarIconOutlined />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={2}>
                {props.publish > 0 && (
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    aria-label="Share"
                    className={clsx(classes.button, classes.textEllipse)}
                  >
                    <Schedule />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} onClick={handleExpandClick} zeroMinWidth>
            <IconButton
              disableRipple
              disableFocusRipple
              aria-label="Share"
              className={clsx(classes.button, classes.textEllipse)}
            >
              <Typography variant="subtitle2" noWrap>
                {props.eventName}
              </Typography>
            </IconButton>
          </Grid>
          <Grid item xs={2} sm={2} onClick={handleExpandClick}>
            <Grid container spacing={0} className={classes.container}>
              <IconButton
                disableRipple
                disableFocusRipple
                aria-label="Share"
                className={clsx(classes.button, classes.textEllipse)}
              >
                <Typography variant="subtitle2" noWrap>
                  {props.oddsname}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} onClick={handleExpandClick}>
            <Grid container spacing={0} className={classes.containerOdds}>
              <OddsChooserHeading
                chosenValues={props.bet.selections}
                marketId={props.bet.marketId}
                id={props.id}
                selectionId={props.bet.selectionId}
                eventId={props.bet.eventId}
                name={props.bet.name}
                updateOdds={props.updateOdds}
              />
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2}>
            <Grid
              container
              spacing={0}
              className={clsx(classes.container, classes.btnIcons)}
            >
              <IconButton
                disableRipple
                disableFocusRipple
                aria-label="Share"
                className={clsx(clsMoveBtnArr.join(" "))}
                onClick={moveItemUp}
              >
                <ExpandLess />
              </IconButton>
              <IconButton
                disableRipple
                disableFocusRipple
                aria-label="Share"
                className={clsx(clsMoveBtnArr.join(" "))}
                onClick={moveItemDown}
              >
                <ExpandMore />
              </IconButton>
              <IconButton
                disableRipple
                disableFocusRipple
                aria-label="Share"
                className={clsx(classes.button, classes.menuIcons)}
                onClick={handleExpandClick}
              >
                <Edit />
              </IconButton>
              <IconButton
                disableRipple
                disableFocusRipple
                aria-label="Share"
                className={clsx(classes.button, classes.menuIcons)}
                onClick={handleCloseDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={0} align="right">
            <Grid item xs={6} sm={6}>
              <TextField
                id="filled-name"
                label="Tittel"
                className={classes.textField}
                value={values.title}
                onChange={handleChange("title")}
                placeholder="Tittel på tips"
                margin="normal"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} className={classes.rightAlg}>
              <OddsChooserItem
                chosenValues={props.bet.selections}
                marketId={props.marketId}
                selectionId={props.bet.selectionId}
                eventId={props.bet.eventId}
                id={props.id}
                name={props.bet.name}
                updateOdds={props.updateOdds}
              />
            </Grid>

            <Grid item xs={6} sm={6} align="left">
              <TextField
                id="filled-preamble"
                label="Ingress"
                className={classes.textField}
                value={values.preamble}
                onChange={handleChange("preamble")}
                margin="normal"
                variant="filled"
                multiline
                rows="6"
                rowsMax="6"
              />
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              <Grid container spacing={0} align="right">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  align="left"
                  className={classes.paddLeft}
                >
                  <TextField
                    id="filled-select-publish"
                    select
                    label="Publisering"
                    className={classes.textFieldInverse}
                    value={values.publish}
                    onChange={handleChange("publish")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText=""
                    margin="normal"
                    variant="filled"
                  >
                    {publishon.map((option) => (
                      <MenuItem key={option.key} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  align="left"
                  className={classes.paddLeft}
                >
                  <TextField
                    id="filled-select-priority"
                    select
                    label="Prioritering"
                    className={classes.textFieldInverse}
                    value={values.priority}
                    onChange={handleChange("priority")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText=""
                    margin="normal"
                    variant="filled"
                  >
                    {priority.map((option) => (
                      <MenuItem key={option.key} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} align="left">
              <TextField
                id="filled-description"
                label="Tipstekst"
                className={classes.textField}
                value={values.description}
                onChange={handleChange("description")}
                margin="normal"
                variant="filled"
                multiline
                rows="6"
                rowsMax="6"
              />
            </Grid>

            <Grid item xs={12} sm={12} align="right">
              {" "}
              <Button
                variant="outlined"
                onClick={closeTip}
                color="primary"
                className={classes.btnRightPad}
              >
                AVBRYT
              </Button>{" "}
              <Button variant="contained" onClick={saveTip} color="primary">
                LAGRE
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default withRouter(ElementItemOdds);
