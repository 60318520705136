/* eslint-disable no-script-url */

import React from "react";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import FormControl from "@material-ui/core/FormControl";

import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  iconStyles: {
    verticalAlign: "middle",
  },
  smallIcon: {
    verticalAlign: "middle",
    width: 16,
    height: 16,
  },
  noPadd: {
    padding: "0 !important",
    color: palette.common.white,
    borderBottom: "none",
    verticalAlign: "middle",
    fontWeight: 600,
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  bigIcon: {
    width: 24,
    height: 24,
  },
  bigIconBlack: {
    width: 24,
    height: 24,
    color: palette.common.black,
  },
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
  },
}));

export default function FormCheckbox(props) {
  const classes = useStyles();
  const item = props.item;
  const isTrueSet =
    props.value === true || props.value === "true" || props.value === "1"
      ? true
      : false;

  return (
    <>
      {!props.hideGrid && (
        <Grid item xs={12} sm={4} key={item.name}>
          <FormControl
            component="fieldset"
            className={classes.formControl}
            key={item.name}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTrueSet}
                    onChange={props.onChange(item.paramname)}
                    value={isTrueSet}
                    disabled={props.isDisabled}
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      )}
      {props.hideGrid && (
        <FormControl
          component="fieldset"
          className={classes.formControl}
          key={item.name}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTrueSet}
                  onChange={props.onChange(item.paramname)}
                  value={isTrueSet}
                  disabled={props.isDisabled}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </FormControl>
      )}
    </>
  );
}
