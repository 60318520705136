export default {
  tableRoot: {
    borderTop: '1px solid #CCCCCC',
    borderLeft: '1px solid #CCCCCC',
    borderRight: '1px solid #CCCCCC'
  },
  paper: {
    boxShadow: 'none'
  }
};
