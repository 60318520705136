import { white, black } from '../common/colors';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
    blue: '#CCF3FF',
    grey: '#D8D8D8',
    orange: '#FFC000',
    yellow: '#FFF2CC',
    pink: '#FF7EA9',
    lightpink: '#FFE5EE',
    green: '#EAF7DC'
  },
  primary: {
    contrastText: white,
    main: '#261440',
    light: '#00C1FF',
    dark: '#0069E2'
  },
  secondary: {
    contrastText: white,
    main: '#00962D',
    light: '#EAF7DC',
    dark: '#96D94E'
  },
  error: {
    contrastText: white,
    main: '#EF2500',
    light: '#FFC000',
    dark: '#FF7337'
  },
  greys: {
    darkest: '#222222',
    dark: '#666666',
    middle: '#999999',
    light: '#CCCCCC',
    lighter: '#eeeeee',
    lightest: '#FAFAFA'
  },
  text: {
    primary: '#222222',
    secondary: '#666666',
    button: '#0069E2'
  },
  background: {
    default: '#f8fafc',
    dark: '#172B4D',
    paper: white,
    secondary: '#E0E0E0',
    disabled: '#E0E0E0'
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
};
