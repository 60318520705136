export default {
  root: {
    fontSize: "1.4rem",
    fontWeight: 600,
    textTransform: "none",
    "@media (min-width: 960px)": {
      fontSize: "1.4rem"
    },
    "@media (min-width:768px)": {
      fontSize: "1.4rem"
    }
  }
};
