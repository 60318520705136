/* eslint-disable no-script-url */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../../components//MySnackbarContentWrapper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  textLink: {
    padding: theme.spacing(3),
    border: "1px solid #979797",
    borderRadius: "3px",
    wordWrap: "break-word",
  },
}));

export default function LinksCode(props) {
  const classes = useStyles();
  const finalURL = generateURL();
  /*useState(() => {
    props.setValues({
      ...props.values,
      isValidated: 3,
      finalURL: finalURL
    });

    return;
    props.saveNewRow(props.values.title, finalURL);
  }, [finalURL] );
  */

  useEffect(() => {
    const finalURL = generateURL();

    const saveNewStatsInterval = async () => {
      props.saveNewRow(props.values.title, finalURL);
      props.setValues({
        ...props.values,
        isValidated: 3,
        finalURL: finalURL,
      });
    };
    saveNewStatsInterval();
    // eslint-disable-next-line
  }, [finalURL]);

  const [copied, onCopy] = useState("");
  const [open, setOpen] = useState(false);

  function handleClose(event) {
    setOpen(false);
  }

  function setCopy() {
    setOpen(true);
  }

  function removeLastCharacter(str, chr) {
    if (str.lastIndexOf(chr) === str.length - 1) {
      str = str.substr(0, str.lastIndexOf(chr));
    }

    return str;
  }
  function generateEkom() {
    //https://www.norsk-tipping.no/flax?WT.mc_id=Sportspill_sportspill_ekomm_flax_element_extra_ekomm-kampanje&utm_source=sportspill&utm_medium=ekomm&utm_content=flax_element_extra&utm_campaign=ekomm-kampanje

    let ekomStr = "";
    if (props.values.placement_medium !== "ekomm") {
      return ekomStr;
    }

    const e_src =
      props.values.placement_source.length > 0
        ? props.values.placement_source + "_"
        : "";

    let e_url =
      props.values.placement_code.length > 0
        ? props.values.placement_code + "_"
        : "";
    //if manually typed url than use product instead url
    if (props.values.id === "100") {
      e_url = props.values.product.length > 0 ? props.values.product + "_" : "";
    }
    const e_type =
      props.values.placement_type.length > 0
        ? props.values.placement_type + "_"
        : "";

    const e_extras =
      props.values.extras.length > 0 ? props.values.extras + "_" : "";

    const e_campaign =
      props.values.campaign.length > 0
        ? "_ekomm-" + props.values.campaign + "_"
        : "";

    let source =
      e_src + e_src + "ekomm_" + e_url + e_type + e_extras + e_campaign;
    source = removeLastCharacter(source, "_");
    return (ekomStr = "WT.mc_id=" + source.toLocaleLowerCase() + "&");
  }

  function generateGame() {
    // DESKTOP VER
    // format = &0.gameID=12&0.selections=1218544,1,570&0.rowPrice=100&0.system=S&0.odds=5.70
    // multiple: &0.gameID=12&0.selections=1221815,1,180;1219746,1,155&0.rowPrice=100&0.system=S&0.odds=1.801.55

    let baseURI_kamp = "https://www.norsk-tipping.no";
    let baseURI_kamp_suffix = "/sales/validateCouponAndStoreToSession.json";
  }

  function generateURL() {
    let finalURL = "";
    let baseURI_kamp = "https://www.norsk-tipping.no/";

    let url = props.values.url;

    if (url.indexOf("http") !== -1) {
      baseURI_kamp = props.values.url;
      baseURI_kamp = removeLastCharacter(baseURI_kamp, "/");
      url = null;
    }

    const utm_source =
      props.values.placement_source.length > 0
        ? "utm_source=" + props.values.placement_source
        : "";
    const utm_medium =
      props.values.placement_medium.length > 0
        ? "&utm_medium=" + props.values.placement_medium
        : "";

    let uc_url =
      props.values.placement_code.length > 0
        ? props.values.placement_code + "_"
        : "";
    //if manually typed url than use product instead url
    if (props.values.id === "100") {
      uc_url =
        props.values.product.length > 0 ? props.values.product + "_" : "";
    }
    let uc_type =
      props.values.placement_type.length > 0
        ? props.values.placement_type + "_"
        : "";
    let uc_extras =
      props.values.extras.length > 0 ? props.values.extras + "_" : "";

    let utm_content =
      uc_url.length > 0 ||
      props.values.placement_type.length > 0 ||
      props.values.extras.length > 0
        ? "&utm_content=" + uc_url + uc_type + uc_extras
        : "";

    utm_content = removeLastCharacter(utm_content, "_");

    const utm_campaign = "&utm_campaign=" + props.values.campaign;

    const urlstr = url != null ? url : "";

    if (props.values.gameURL && props.values.gameURL != "") {
      baseURI_kamp = props.values.gameURL;
    } else if (props.values.leaguesURL && props.values.leaguesURL != "") {
      baseURI_kamp = props.values.leaguesURL;
    } else {
      baseURI_kamp = baseURI_kamp.toLocaleLowerCase();
    }

    const utm_term = "";

    const divider = "";
    const qstring = baseURI_kamp.indexOf("?") > 0 ? "&" : "?";
    finalURL =
      baseURI_kamp +
      divider +
      urlstr.toLocaleLowerCase() +
      qstring +
      generateEkom() +
      utm_source.toLocaleLowerCase() +
      utm_medium.toLocaleLowerCase() +
      utm_content.toLocaleLowerCase() +
      utm_campaign.toLocaleLowerCase() +
      utm_term;

    return finalURL;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <div className={classes.textLink}>
          {props.values.finalURL}
          <br />
          <br />
          <CopyToClipboard onCopy={setCopy} text={props.values.finalURL}>
            <Button variant="contained" color="primary">
              KOPIER
            </Button>
          </CopyToClipboard>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <MySnackbarContentWrapper
            onClose={handleClose}
            variant="success"
            message="Lenken er kopiert!"
          />
        </Snackbar>
      </Grid>
    </Grid>
  );
}
