import React from "react";
import PropTypes from "prop-types";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubMenu from "./SubMenuItem";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  menuHolder: {
    minWidth: "200px",
    borderBottom: "1px solid #D8D8D8"
  }
}));

function renderMenuItems(props, classes) {
  const { menuItems, onMenuItemClick } = props;

  return menuItems.map(menuItem => {
    if (menuItem.hasOwnProperty("subMenuItems")) {
      return (
        <SubMenu
          key={menuItem.key}
          caption={menuItem.caption}
          menuItems={menuItem.subMenuItems}
          className={classes.menuHolder}
          onMenuItemClick={onMenuItemClick}
        />
      );
    }

    return (
      <MenuItem
        key={menuItem.key}
        onClick={onMenuItemClick.bind(null, menuItem)}
        className={classes.menuHolder}
      >
        {menuItem.caption}
      </MenuItem>
    );
  });
}
export default function EnhancedMenu(props) {
  const classes = useStyles();

  const { anchorElement, open, onClose, anchorOrigin } = props;

  return (
    <MuiMenu
      anchorEl={anchorElement}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      {renderMenuItems(props, classes)}
    </MuiMenu>
  );
}

EnhancedMenu.propTypes = {
  anchorElement: PropTypes.any,
  menuItems: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
