/* eslint-disable no-script-url */
import React, { useState, useEffect } from "react";

import Typography from "@material-ui/core/Typography";
import axios from "axios";
import StatsContext from "../../../context/StatsContext";
import update from "immutability-helper";

import Grid from "@material-ui/core/Grid";
import OddsProgramTable from "./OddsProgramTable";
import OddsProgramChosen from "./OddsProgramChosen";

export default function OddsProgrammet(props) {
  const [eventsData, setEventsData] = useState([]);
  const [chosenEvents, setChosenEvents] = useState(0);
  const [chosenBets, setChosenBets] = useState();

  const [values, setValues] = useState([]);
  const statsRange = React.useContext(StatsContext);

  function handleDelete(eventId, marketId) {
    //const selectedMatch = eventsData.filter((item, => item.matchid === matchid);

    let updatedMatchKey;
    let nrOfSelectedBets = chosenEvents;
    const selectedMatch = eventsData.filter(function(item, key) {
      if (item.eventId === eventId) {
        updatedMatchKey = key;
        return item;
      }
    });

    let updatedBetKey;
    const selectedBet = selectedMatch[0].markets.filter(function(item, key) {
      if (item.marketId === marketId) {
        updatedBetKey = key;
        return item;
      }
    });

    let selectedOddsId = null;

    nrOfSelectedBets = nrOfSelectedBets - 1;

    const updatedItem = update(eventsData, {
      [updatedMatchKey]: {
        markets: { [updatedBetKey]: { selectionId: { $set: selectedOddsId } } }
      }
    });
    setChosenEvents(nrOfSelectedBets);
    setEventsData(updatedItem);
  }

  function updateOdds(eventId, marketId, selectionId) {
    //const selectedMatch = eventsData.filter((item, => item.matchid === matchid);
    let updatedMatchKey;
    let nrOfSelectedBets = chosenEvents;
    const selectedMatch = eventsData.filter(function(item, key) {
      if (item.eventId === eventId) {
        updatedMatchKey = key;
        return item;
      }
    });

    let updatedBetKey;
    const selectedBet = selectedMatch[0].markets.filter(function(item, key) {
      if (item.marketId === marketId) {
        updatedBetKey = key;
        return item;
      }
    });

    let updatedOddsKey;
    const selectedOdds = selectedBet[0].selections.filter(function(item, key) {
      if (item.selectionId === selectionId) {
        updatedOddsKey = key;
        return item;
      }
    });

    let selectedOddsId = selectionId;

    if (selectedBet[0].selectionId === selectionId) {
      selectedOddsId = null;
      nrOfSelectedBets = nrOfSelectedBets - 1;
    } else {
      nrOfSelectedBets = nrOfSelectedBets + 1;
    }

    const updatedItem = update(eventsData, {
      [updatedMatchKey]: {
        markets: { [updatedBetKey]: { selectionId: { $set: selectedOddsId } } }
      }
    });
    setChosenEvents(nrOfSelectedBets);
    setEventsData(updatedItem);
  }

  useEffect(() => {
    const loadProgrammet = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/feed/langoddsen" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.events;
      setEventsData(results);
    };
    loadProgrammet();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" gutterBottom>
          Valgte objekter
        </Typography>
        <OddsProgramChosen
          eventsData={eventsData}
          values={values}
          setValues={setValues}
          doDelete={handleDelete}
          updateOdds={updateOdds}
          chosenEvents={chosenEvents}
          setChosenBets={setChosenBets}
          updateGameURL={props.updateGameURL}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3">
          OddsProgrammet
        </Typography>
        {eventsData.length > 0 && (
          <OddsProgramTable
            eventsData={eventsData}
            setValues={setValues}
            updateOdds={updateOdds}
          />
        )}
      </Grid>
    </>
  );
}
