export default {
  root: {},
  line: {
    color: "#D8D8D8"
  },
  completed: {
    "& .MuiStepConnector-line": {
      borderColor: "#291449"
    }
  }
};
