import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import FormTextItem from "../../../components/FormTextItem";
import FormSelect from "../../../components/FormSelect";
import FormCheckbox from "../../../components/FormCheckbox";
import FormRadio from "../../../components/FormRadio";

import palette from "../../../theme/palette";
import { withRouter } from "react-router-dom";
import TableTippetipsHolder from "./TableTippetipsHolder";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    maxWidth: 300,
    color: palette.common.black,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  textFieldInverse: {
    margin: 0,
    backgroundColor: "rgba(255, 255, 255, 0.24)",
    "& .MuiInputLabel-root": {
      color: palette.common.black,
    },
    "& .MuiFilledInput-root": {
      color: palette.common.black,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "1px solid " + palette.common.black,
    },
    "& .MuiSelect-root": {
      color: palette.common.black,
    },
    "& .MuiSelect-icon": {
      color: palette.common.black,
    },
  },
  chip: {
    margin: theme.spacing(0) / 4,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  buttonLeft: {
    marginRight: "1.6rem",
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    color: palette.common.black,
    backgroundColor: palette.common.white,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  heading: {
    color: palette.common.black,
  },
  formElementHolder: {
    marginTop: "3rem",
    marginBottom: "4rem",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const categories = [
  {
    value: "generell",
    label: "Generell",
  },
  {
    value: "sport",
    label: "Sport",
  },
  {
    value: "lotteri",
    label: "Lotteri",
  },
  {
    value: "instaspill",
    label: "Instaspill",
  },
  {
    value: "forside",
    label: "Forside",
  },
];

const units = [
  {
    value: "px",
    label: "px",
  },
  {
    value: "per",
    label: "%",
  },
];
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function updateContent(props) {
  props.history.push("/content");
}

function ContentProperties(props) {
  const classes = useStyles();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  let newitems = [];
  let tippetipsItems = [];
  function updateSelection(selection, index) {
    console.log("SEL", selection, index);
    console.log("formdata", props.formdata);
    props.formdata["coupon"][index] = selection;
    props.updateTipping(props.formdata["coupon"], props.widgetoptions);
  }

  function processData(data, selectedItem) {
    if (!data) return;

    data.forEach(function (item, key) {
      if (item.type === "select") {
        if (
          item.items &&
          item.items.length > 0 &&
          (selectedItem == item.key || selectedItem === "start")
        ) {
          if (!props.formdata[item.paramname]) return;
          let selectedValue = props.formdata[item.paramname].value;

          newitems.push(
            <FormSelect
              key={item.paramname}
              item={item}
              onChange={(e) =>
                props.handleChange(
                  item.paramname,
                  props.formdata[item.paramname].parentName
                )
              }
              value={selectedValue}
              isDisabled={props.isDisabled}
              isStart={true}
            />
          );

          if (props.formdata[item.paramname].value) {
            processData(item.items, props.formdata[item.paramname].value);
            return;
          }
        } else if (!item.items && item.type != "select") {
          newitems.push(
            <FormSelect
              key={item.value && item.value ? item : item.key}
              item={item}
              onChange={(e) =>
                props.handleChange(
                  item.paramname,
                  props.formdata[item.paramname].parentName
                )
              }
              value={props.formdata[item.paramname].value}
              isDisabled={props.isDisabled}
              isStart={true}
            />
          );
        }
      } else if (item.type === "text") {
        let val = props.formdata[item.paramname].value;

        //val = props.formdata[item.paramname].default_value;
        newitems.push(
          <FormTextItem
            key={item.paramname}
            item={item}
            onChange={(e) =>
              props.handleChange(
                item.paramname,
                props.formdata[item.paramname].parentName
              )
            }
            value={val}
            isDisabled={props.isDisabled}
          />
        );
      } else if (item.type === "checkbox") {
        newitems.push(
          <FormCheckbox
            key={item.name}
            item={item}
            onChange={(e) =>
              props.handleChange(
                item.paramname,
                props.formdata[item.paramname].parentName
              )
            }
            value={props.formdata[item.paramname].value}
            isDisabled={props.isDisabled}
          />
        );

        if (
          props.formdata[item.paramname].value &&
          props.formdata[item.paramname].value != "false"
        ) {
          processData(item.items, props.formdata[item.paramname].value);
          return;
        }
      } else if (item.type === "radio") {
        console.log(item);
        newitems.push(
          <FormRadio
            key={item.value && item.value ? item : item.key}
            item={item}
            onChange={(e) =>
              props.handleChange(
                item.paramname,
                props.formdata[item.paramname].parentName
              )
            }
            value={props.formdata[item.paramname].value}
            isStart={true}
          />
        );

        if (
          props.formdata[item.paramname].value &&
          props.formdata[item.paramname].value != "false"
        ) {
          processData(item.items, props.formdata[item.paramname].value);
          return;
        }
      } else if (item.type === "tippekupong") {
        tippetipsItems.push(
          <TableTippetipsHolder
            key={item.name}
            rows={item.items}
            page={0}
            rowsPerPage={10}
            dag={props.formdata["dag"].value}
            feed={props.formdata["feed"].value}
            onChange={(e) =>
              props.handleChange(
                item.paramname,
                props.formdata[item.paramname].parentName
              )
            }
            updateSelection={updateSelection}
            value={props.formdata[item.paramname].value}
            isDisabled={props.isDisabled}
          />
        );
        processData(item.items, props.formdata[item.paramname].value);
        return;

        console.log(item);
      }
    });

    return newitems;
  }

  processData(props.widgetoptions, "start");

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h1"
            align="left"
            className={classes.heading}
          >
            {props.name}
          </Typography>
        </Grid>

        <Grid item container xs={12} className={classes.formElementHolder}>
          {props.mode === "view" && (
            <>
              <Grid container spacing={2}>
                {newitems}
                <Grid item xs={12} sm={4}>
                  <TextField
                    id={"filled-titel"}
                    label="Tittel"
                    className={classes.textField}
                    value={props.name}
                    onChange={props.handleTitle("name")}
                    margin="normal"
                    variant="filled"
                    disabled={props.isDisabled}
                  />
                </Grid>
              </Grid>
              {tippetipsItems && (
                <Grid container spacing={0}>
                  {tippetipsItems}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
export default withRouter(ContentProperties);
