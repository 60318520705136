import palette from "./palette";

export default {
  fontFamily: ["Jubel", "Arial"].join(","),
  htmlFontSize: 10,
  fontSize: 10,
  h1: {
    color: palette.text.primary,
    fontWeight: "600",
    fontSize: "2.2rem",
    lineHeight: "2.4rem",
    "@media (min-width:600px)": {
      fontSize: "2.2rem"
    }
  },
  h2: {
    color: palette.text.primary,
    fontWeight: "600",
    lineHeight: "2.4rem",
    fontSize: "2.2rem"
  },
  h3: {
    color: palette.text.primary,
    fontWeight: "600",
    fontSize: "1.8rem",
    lineHeight: "2.4rem"
  },
  h4: {
    color: palette.text.primary,
    fontWeight: "600",
    fontSize: "1.6rem",
    lineHeight: "2.4rem"
  },
  h6: {
    color: palette.text.primary,
    fontWeight: "900",
    fontSize: "1.6rem",
    lineHeight: "2.4rem"
  },
  subtitle1: {
    color: "#000",
    fontWeight: "600",
    fontSize: "1.6rem",
    lineHeight: "2.4rem"
  },
  subtitle2: {
    color: palette.text.primary,
    fontWeight: "600",
    fontSize: "1.4rem",
    lineHeight: "2.4rem"
  },
  body1: {
    color: palette.text.primary,
    fontSize: "1.4rem",
    fontWeight: "400",
    lineHeight: "2rem"
  },
  body2: {
    fontSize: "1.4rem",
    fontWeight: "400",
    lineHeight: "2rem",
    color: "#666"
  },
  menu: {
    color: palette.text.primary,
    fontSize: "1.4rem",
    fontWeight: "400",
    lineHeight: "2rem"
  },
  button: {
    color: palette.text.button,
    fontSize: "1.4rem",
    fontWeight: "600",
    lineHeight: "2rem",
    textTransform: "uppercase"
  },
  caption: {
    color: palette.text.primary,
    fontSize: "1.4rem",
    fontWeight: "400",
    lineHeight: "2rem"
  },
  overline: {
    color: palette.text.secondary,
    fontSize: "1.2rem",
    fontWeight: "400",
    lineHeight: "1.8rem"
  }
};
