export default {
  gutterBottom: {
    marginBottom: "1.6rem",
    marginTop: "1.6rem"
  },
  body1: {
    fontSize: "1.6rem",
    lineHeight: "2.4rem"
  }
};
