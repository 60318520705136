/* eslint-disable no-script-url */
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import LinksChosePlacement from "./LinksChosePlacement";
import LinksChoseProduct from "./LinksChoseProduct";
import axios from "axios";
import StatsContext from "../../../context/StatsContext";

import Grid from "@material-ui/core/Grid";

import palette from "../../../theme/palette";

const useStyles = makeStyles({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
    boxShadow: "none",
  },
  depositContext: {
    margin: "2rem 0",
  },
  button: {
    paddingTop: "9px",
    paddingBottom: "9px",
  },
  textField: {
    padding: "5px",
  },
});

export default function LinksTracking(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);

  const [errorPlacement, setErrorPlacement] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [isTouched, setIsTouched] = React.useState(false);

  useEffect(() => {
    const loadProgrammet = async () => {
      const result = await axios.post(
        "https://nt-ekom-2.herokuapp.com/link/title" +
          "?session_key=" +
          statsRange.sid,
        {
          url: getPageURL(),
        }
      );

      let results = result.data.title;

      props.setValues({
        ...props.values,
        title: results,
      });
    };
    loadProgrammet();
    // eslint-disable-next-line
  }, []);

  function getPageURL() {
    let url = props.values.url;

    if (url.indexOf("http") === -1) {
      return "https://www.norsk-tipping.no/" + url;
    } else {
      return url;
    }
  }
  const handleChange = (name) => (event) => {
    // const onlyNumsLettes = event.target.value.replace(/[^A-Za-z0-9]+/g, "");
    const onlyNumsLettes = event.target.value;
    if (name === "placement_type" && onlyNumsLettes.length < 2 && isTouched) {
      setErrorPlacement(true);
      let err = [...props.values.errors];
      props.setValues({
        ...props.values,
        errors: [...err, name],
        [name]: onlyNumsLettes,
        isValidated: 1,
      });
      return;
    } else if (name === "campaign" && onlyNumsLettes.length < 2) {
      setErrorPlacement(true);
      let err = [...props.values.errors];
      props.setValues({
        ...props.values,
        errors: [...err, name],
        [name]: onlyNumsLettes,
        isValidated: 1,
      });
      return;
    } else if (
      name === "campaign" &&
      props.values.campaign !== "" &&
      onlyNumsLettes.length > 2
    ) {
      setErrorPlacement(false);
      let errors = props.values.errors.filter((e, i) => e !== name);
      let val = props.values.placement !== "" ? 2 : 1;

      props.setValues({
        ...props.values,
        [name]: onlyNumsLettes,
        isValidated: val,
      });
      return;
    } else if (name === "placement_type" && props.values.placement !== "") {
      setErrorPlacement(false);
      let errors = props.values.errors.filter((e, i) => e !== name);
      let val = props.values.campaign !== "" ? 2 : 1;

      props.setValues({
        ...props.values,
        [name]: onlyNumsLettes,
        isValidated: val,
      });
      return;
    }

    props.setValues({
      ...props.values,
      [name]: onlyNumsLettes,
    });
  };

  const handleTouched = () => {
    setIsTouched(true);
  };
  return (
    <Grid container spacing={0}>
      <LinksChosePlacement values={props.values} setValues={props.setValues} />

      <Grid item xs={12} sm={4}>
        <TextField
          id="filled-plasseringtype"
          label="Plasseringstype"
          placeholder="F.eks topbanner, les mer knapp..."
          className={classes.textField}
          value={props.values.placement_type}
          error={errorPlacement}
          onChange={handleChange("placement_type")}
          onFocus={handleTouched}
          onBlur={handleChange("placement_type")}
          margin="normal"
          variant="filled"
          helperText={helperText}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {props.values.id === "100" && (
        <LinksChoseProduct values={props.values} setValues={props.setValues} />
      )}
      <Grid item xs={12} sm={4}>
        <TextField
          id="filled-ekstra"
          label="Ekstra merknad (Valgfri)"
          className={classes.textField}
          value={props.values.extras}
          placeholder="F.eks Gul, Versjon1..."
          onChange={handleChange("extras")}
          margin="normal"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          id="filled-kampanjenavn"
          label="Kampanjenavn"
          className={classes.textField}
          value={props.values.campaign}
          onChange={handleChange("campaign")}
          onBlur={handleChange("campaign")}
          placeholder="Kampanjenavn"
          margin="normal"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          id="filled-tittel"
          label="Tittel (Valgfri)"
          className={classes.textField}
          value={props.values.title}
          onChange={handleChange("title")}
          placeholder="Tittel"
          margin="normal"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
