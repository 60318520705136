import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CopyToClipboard from "react-copy-to-clipboard";
const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  dialogWin: {
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0 !important",
    },
  },
  buttonMobile: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 0 8px 0 !important",
    },
  },
  textLink: {
    padding: theme.spacing(3),
    border: "1px solid #979797",
    borderRadius: "3px",
    wordWrap: "break-word",
    textAlign: "left",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/*
'root'
  | 'scrollPaper'
  | 'scrollBody'
  | 'container'
  | 'paper'
  | 'paperScrollPaper'
  | 'paperScrollBody'
  | 'paperWidthFalse'
  | 'paperWidthXs'
  | 'paperWidthSm'
  | 'paperWidthMd'
  | 'paperWidthLg'
  | 'paperWidthXl'
  | 'paperFullWidth'
  | 'paperFullScreen';
*/
const DialogContainer = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "200 !important",
    },
  },
  paperFullWidth: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      padding: "0",
      height: "100%",
      maxHeight: "100%",
    },
  },
}))(Dialog);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}))(MuiDialogActions);

function OddsCodeDialog(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    title: "",
    description: "",
    discus: "0",
    image: "",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  function saveSettingInfo() {
    props.saveSettings(values);
  }

  function setCopy() {
    props.setSnackMsg("Lenken er kopiert!");
    props.setOpen(true);
  }

  return (
    <React.Fragment>
      <DialogContainer
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
        fullWidth={true}
        maxWidth="xl"
        className={classes.dialogWin}
      >
        <DialogTitle id="customized-dialog-title">Publiseringskode</DialogTitle>

        <DialogContent>
          <Grid container spacing={1} align="right">
            <Grid item xs={12}>
              <div className={classes.textLink}>
                <code>{props.getPlacementURL()}</code>
                <br />
                <br />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            variant="contained"
            color="secondary"
            className={classes.buttonMobile}
          >
            AVBRYT
          </Button>
          <CopyToClipboard
            onCopy={props.setCopy}
            text={props.getPlacementURL()}
          >
            <Button
              onClick={props.handleClose}
              variant="contained"
              color="primary"
              className={classes.buttonMobile}
            >
              KOPIER
            </Button>
          </CopyToClipboard>
        </DialogActions>
      </DialogContainer>
    </React.Fragment>
  );
}

export default OddsCodeDialog;
