import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import update from "immutability-helper";

import CollectionsHolder from "./components/CollectionsHolder";
import StatsContext from "../../context/StatsContext";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";
import RenameCollectionDialog from "./components/RenameCollectionDialog";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  circleProg: {
    marginTop: "48px",
  },
}));

function CollectionsDetails(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: "",
    title: "",
    id: "",
    url: "",
    itemId: "",
    placement: "",
    placement_id: "",
    placement_type: "",
    placement_source: "",
    placement_medium: "",
    placement_code: "",
    placement_campaign: "",
    product: "",
    extras: "",
    campaign: "",
    isValidated: 0,
    finalURL: "",
    gameURL: "",
    leaguesURL: "",
    errors: [],
  });

  const pId =
    props.match.params.id > 0 && props.match.params.id !== "new"
      ? props.match.params.id
      : -1;

  const [isBlocked, setIsBlocked] = useState(false);

  const [collectionsData, setCollectionsData] = useState(null);
  const [collectionData, setCollectionData] = useState(null);

  const [templatesData, setTemplatesData] = useState(null);
  const [templateId, setTemplateId] = useState(null);

  const [updateRow, setUpdateRow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert!");
  const [snackVar, setSnackVar] = React.useState("success");
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const statsRange = React.useContext(StatsContext);
  function handleClose(event) {
    setOpen(false);
  }

  function showAlert(msg, msgtype) {
    setSnackVar(msgtype);
    setSnackMsg(msg);
    setOpen(true);
  }

  useEffect(() => {
    const loadPlacements = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/collection/" +
          pId +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.content;

      setCollectionData(results);
      setCollectionsData(result.data);
    };
    if (pId < 0) {
      createNewPage();
    } else {
      loadPlacements();
    }
  }, []);

  useEffect(() => {
    const loadTemplates = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/templates" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.templates;

      setTemplatesData(results);
    };
    loadTemplates();
  }, []);

  useEffect(() => {
    const getTemplate = async (id) => {
      if (!statsRange.sid) return;
      let result;
      setIsBlocked(false);

      result = await axios.post(
        "https://nt-ekom-2.herokuapp.com/template/" +
          id +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.content;
      setCollectionData(results);
      console.log("results", results);
    };
    if (templateId) {
      getTemplate(templateId);
      setTemplateId(null);
    }
  }, [templateId]);

  useEffect(() => {
    const saveCollection = async (id) => {
      if (!statsRange.sid) return;
      let result;
      setIsBlocked(false);
      if (pId < 0) {
        result = await axios.post(
          "https://nt-ekom-2.herokuapp.com/collection" +
            "?session_key=" +
            statsRange.sid,
          {
            ...updateRow,
          }
        );
      } else {
        result = await axios.put(
          "https://nt-ekom-2.herokuapp.com/collection/" +
            pId +
            "?session_key=" +
            statsRange.sid,
          {
            ...updateRow,
          }
        );
      }

      let results = result.data;

      console.log("results", results);
      if (pId < 0) {
        console.log("HERE");

        //props.history.push("/collections/" + results.collection_id);
        //updatePageId(results.collection_id);
        window.location.href = "/collections/" + results.collection_id;
      }
    };
    if (updateRow) {
      saveCollection(updateRow.id);
      showAlert("Oppdatert!", "success");
    }
  }, [updateRow, statsRange.sid]);

  function addTemplate(id) {
    console.log(id);
    setTemplateId(id);
  }

  function createNewPage() {
    var dummyObj = {
      id: null,
      partner_id: statsRange.partnerId,
      name: "Ny samleside",
      created: "",
      updated: "",
      last_access: "",
      updated_by: null,
      deleted: null,
      content: [],
    };

    setCollectionData(dummyObj.content);
    setCollectionsData(dummyObj);
  }

  function moveArr(old_index, new_index) {
    console.log("MOVE ARR");
    const updatedData = [...collectionData];
    if (new_index >= updatedData.length) {
      var k = new_index - updatedData.length + 1;
      while (k--) {
        updatedData.push(undefined);
      }
    }

    updatedData.splice(new_index, 0, updatedData.splice(old_index, 1)[0]);
    let newObj = [];
    for (var i = 0; i < updatedData.length; i++) {
      let position = i + 1;
      let tempObj = {};
      tempObj = updatedData[i];
      tempObj.position = position;
      newObj.push(tempObj);
    }

    setCollectionData(newObj);
  }

  function removeRow(remid) {
    const updatedData = [...collectionData];

    if (remid === 999999) {
      setIsBlocked(false);
    }
    const newObj = updatedData.filter(function (item) {
      console.log(item);
      return item.contentid !== remid;
    });

    setCollectionData(newObj);
  }
  function addNewRow() {
    const updatedData = [...collectionData];
    const pos = updatedData.length;
    const newTitle = "Nytt innhold";
    const id = null;
    const url = "";
    var statsObj = {
      3: {
        clicks: 0,
        clicks_trend: 0,
        display: 0,
        display_trend: 0,
        ctr: 0,
        ctr_trend: 0,
      },
      6: {
        clicks: 0,
        clicks_trend: 0,
        display: 0,
        display_trend: 0,
        ctr: 0,
        ctr_trend: 0,
      },
      12: {
        clicks: 0,
        clicks_trend: 0,
        display: 0,
        display_trend: 0,
        ctr: 0,
        ctr_trend: 0,
      },
      24: {
        clicks: 0,
        clicks_trend: 0,
        display: 0,
        display_trend: 0,
        ctr: 0,
        ctr_trend: 0,
      },
    };

    var w = 2;
    var h = 2;

    var newrow = {
      contentid: 999999,
      name: newTitle,
      contenttype: "",
      position: pos,
      contentImg: "",
      numTimesUsed: 0,
      widgetoptions: [],
      title: newTitle,
      url: url,
      id: null,
      stats: statsObj,
      width: w,
      height: h,
    };
    setIsBlocked(true);
    setCollectionData([...updatedData, newrow]);
  }

  function changeGrid(w, h, index) {
    let updatedData = [...collectionData];
    let updateItem = updatedData[index];

    updateItem.width = w;
    updateItem.height = h;

    setCollectionData(updatedData);
  }

  function returnFastHeight(contenttype) {
    if (contenttype === "spillevett") {
      return 32;
    }

    return 0;
  }
  function updatePlacementContent(item, id) {
    let updatedData = [...collectionData];
    let updateItemId = null;
    console.log(item, item.contenttype);
    var newHeight = returnFastHeight(item.contenttype);

    if (newHeight > 0) {
      item.height = newHeight;
      item.width = 4;
    }

    let updateItemIdT = updatedData.map(function (item, index) {
      if (item.contentid === id) {
        updateItemId = index;
        return index;
      }
      return null;
    });

    const newState = update(updatedData, {
      [updateItemId]: { $set: item },
    });
    setIsBlocked(false);
    setCollectionData(newState);
    console.log("HERRR");
  }

  function saveData() {
    if (isBlocked) return;
    var updatedCollections = { ...collectionsData };
    const newState = update(updatedCollections, {
      content: { $set: collectionData },
    });

    setUpdateRow(newState);
  }

  function renameCollection() {
    handleOpenRenameDialog();
  }

  function handleOpenRenameDialog() {
    setRenameDialogOpen(!renameDialogOpen);
    console.log("open");
  }

  function handleChange() {
    console.log("handleChange");
  }

  function updateTitle(title) {
    var updatedCollections = { ...collectionsData };

    const newState = update(updatedCollections, {
      name: { $set: title },
    });

    setCollectionsData(newState);
  }

  function updatePageId(pageid) {
    var updatedCollections = { ...collectionsData };

    const newState = update(updatedCollections, {
      id: { $set: pageid },
    });

    setCollectionsData(newState);
  }

  function setCopy() {
    setSnackMsg("Lenken er kopiert!");
    setOpen(true);
  }

  function getCollectionURL() {
    if (!pId) return;

    return `<script type='text/javascript' src='//widget.tippebannere.no/v3/Scripts/nt-utilities.js'></script><iframe title="ntWidget" id="NTOddsenSupermodul" frameBorder="0" scrolling="no" src="https://nt-ekom-2.herokuapp.com/col/${pId}" style="width:100%;height:800px"></iframe>`;
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {!collectionsData && (
        <Grid item xs={12} className={classes.circleProg} align="center">
          <CircularProgress disableShrink />
        </Grid>
      )}
      {collectionsData && templatesData && (
        <CollectionsHolder
          collectionid={pId}
          values={values}
          setValues={setValues}
          title={collectionsData.name}
          items={collectionData}
          setCollectionData={setCollectionData}
          templates={templatesData}
          addTemplate={addTemplate}
          addNewRow={addNewRow}
          removeRow={removeRow}
          renameCollection={renameCollection}
          showAlert={showAlert}
          moveArr={moveArr}
          changeGrid={changeGrid}
          saveData={saveData}
          isBlocked={isBlocked}
          setIsBlocked={setIsBlocked}
          handleDelete={props.handleDelete}
          updatePlacementContent={updatePlacementContent}
          getCollectionURL={getCollectionURL}
          setCopy={setCopy}
        />
      )}
      {renameDialogOpen && (
        <RenameCollectionDialog
          open={renameDialogOpen}
          handleClose={handleOpenRenameDialog}
          handleChange={handleChange}
          updateTitle={updateTitle}
          title={collectionsData.name}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}

export default withRouter(CollectionsDetails);
