import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import ContentListHolder from './components/ContentListHolder';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,

    overflow: 'auto'
  },
  topMarg: {
    marginTop: '40px'
  }
}));

function ContentPage({ match }) {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={clsx(classes.appBarSpacer, classes.topMarg)} />
      <ContentListHolder />
    </main>
  );
}

export default withRouter(ContentPage);
