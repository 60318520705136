// Palette
import palette from '../palette';

export default {
  root: {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: palette.common.black
  },
  shrink: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: palette.greys.dark,
    transform: 'translate(12px, 7px) scale(1) !important'
  }
};
