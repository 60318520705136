import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Link";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import EmptyMessage from "./components/EmptyMessage";
import CircularProgress from "@material-ui/core/CircularProgress";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import WbSunnyOutlinedIcon from "@material-ui/icons/WbSunnyOutlined";
import TrafficIcon from "@material-ui/icons/Traffic";
import TrendingUp from "@material-ui/icons/TrendingUp";
import TrendingDown from "@material-ui/icons/TrendingDown";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";

import DashInfo from "./components/DashInfo";

import TableFrontpage from "./components/TableFrontpage";
import TableGlobalStats from "./components/TableGlobalStats";
import AlertDialog from "../../components/AlertDialog";
import StatsContext from "../../context/StatsContext";
import axios from "axios";
import update from "immutability-helper";

import palette from "../../theme/palette";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      padding: "7px 16px",
      marginTop: theme.spacing(1),
    },
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(3),
    width: 24,
    height: 24,
  },
  orange: {
    color: palette.error.dark,
  },
  greyIcons: {
    color: palette.text.secondary,
  },
  heading: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  singleSpacing: {
    marginRight: theme.spacing(1),
  },
  doubleSpacing: {
    marginRight: theme.spacing(2),
  },
  rowMargin: {
    marginTop: "32px",
  },
  iconButton: {
    color: "rgba(0,0,0,0.54)",
  },
}));

export default function Rapports(props) {
  const classes = useStyles();

  const statsRange = React.useContext(StatsContext);
  const [deleteURL, setDeleteURL] = useState("");
  const [addFavId, setAddFavId] = useState(null);
  const [delId, setDelId] = useState(null);
  const [delType, setDelType] = useState(null);

  const [removeFavId, setRemoveFavId] = useState(null);
  const [positionData, setPositionData] = useState();
  const [linksData, setLinksData] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [snackMsg, setSnackMsg] = React.useState("Lagt som favoritt!");
  const [snackVar, setSnackVar] = React.useState("success");

  useEffect(() => {
    const loadPlacements = async () => {
      if (!statsRange.sid) return;
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/positions/" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.positions;
      setPositionData(results);
    };
    loadPlacements();
  }, []);

  useEffect(() => {
    if (!addFavId) return;
    if (!statsRange.sid) return;
    const addNewFavorite = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/position/favourite/" +
          addFavId +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data;
      setSnackMsg("Lagt som favoritt!");
      setOpen(true);
    };
    addNewFavorite();
  }, [addFavId]);

  useEffect(() => {
    if (!removeFavId) return;
    if (!statsRange.sid) return;
    const removeNewFavorite = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/position/unfavourite/" +
          removeFavId +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data;
      setSnackMsg("Fjernet fra favoritter!");
      setOpen(true);
    };
    removeNewFavorite();
  }, [removeFavId]);

  useEffect(() => {
    if (!statsRange.sid) return;
    const loadLinks = async () => {
      const result = await axios.get(
        "https://nt-ekom-2.herokuapp.com/links/" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.links;
      setLinksData(results);
    };
    loadLinks();
  }, []);

  //DELETE
  function doDelete(itemId) {
    setDeleteURL(
      "https://nt-ekom-2.herokuapp.com/position/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
  }

  //DELETE
  function doDeleteLinks(itemId) {
    setDeleteURL(
      "https://nt-ekom-2.herokuapp.com/link/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (deleteURL != "") {
        const result = await axios.delete(deleteURL);
        setSnackMsg("Slettet!");
        setOpen(true);
      }
    };
    fetchData();
  }, [deleteURL]);

  //GET FAVORITES
  function parseFavourites() {
    if (!positionData.length === 0) return;

    let a = positionData.filter(function (item) {
      return item.favourite === true;
    });

    return a;
  }

  function sortPlacementByStats(itemKey, items, sortorder) {
    const order = sortorder;

    const newOrder = items.sort(function (a, b) {
      if (a.stats.length < 1) return 0;
      if (!a.stats[24]) return 0;
      if (!b.stats[24]) return 0;

      var keyA = a.stats[24][itemKey],
        keyB = b.stats[24][itemKey];
      // Compare the 2 dates
      if (keyA < keyB) return -1 * order;
      if (keyA > keyB) return 1 * order;
      return 0;
    });
    return newOrder;
  }

  function parseBestPlacements() {
    if (positionData.length < 1) return;
    const arrayToSort = [...positionData];
    let a = sortPlacementByStats("ctr", arrayToSort, -1);

    return a.slice(0, 5);
  }

  function parseWorstPlacements() {
    if (positionData.length < 1) return;
    const arrayToSort = [...positionData];
    let b = sortPlacementByStats("ctr", arrayToSort, 1);

    return b.slice(0, 5);
  }

  function parseLinks() {
    if (positionData.length < 1) return;
    const arrayToSort = [...linksData];
    let b = sortPlacementByStats("ctr", arrayToSort, 1);

    return b.slice(0, 5);
  }

  const [alertOpen, setAlertOpen] = useState(false);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  function handleDelete(itemId) {
    setDelType("position");
    setDelId(itemId);
    handleOpenAlert();
  }

  function handleDeleteLink(itemId) {
    setDelType("lenke");
    setDelId(itemId);
    handleOpenAlert();
  }

  function handleOpenAlert() {
    setAlertOpen(!alertOpen);
  }

  function handleClose(event) {
    setOpen(false);
  }

  function confirmDelete() {
    let updatedItem;
    if (delType === "position") {
      updatedItem = positionData.filter((item) => item.id !== delId);
      setPositionData(updatedItem);
      doDelete(delId);
    } else {
      updatedItem = linksData.filter((item) => item.id !== delId);
      setLinksData(updatedItem);
      doDeleteLinks(delId);
    }

    handleOpenAlert();
  }

  function addToFavorites(pid, action) {
    if (action === false) {
      setAddFavId(pid);
    } else if (action === true) {
      setRemoveFavId(pid);
    }
    let updatedPositionKey;
    let updatedPosition = positionData.filter(function (item, key) {
      if (item.id === pid) {
        updatedPositionKey = key;
        return item;
      }
    });

    const updatedItem = update(positionData, {
      [updatedPositionKey]: { favourite: { $set: !action } },
    });

    setPositionData(updatedItem);
  }

  const currentPath = props.location.pathname;
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <TableGlobalStats />
          </Grid>
          {/* Chart */}
          <Grid item xs={12} md={7} lg={8}></Grid>
        </Grid>
        {/* Favorite placements */}
        {positionData && positionData.length === 0 && <EmptyMessage type={0} />}

        {!positionData && (
          <Grid item xs={12} className={classes.rowMargin} align="center">
            <CircularProgress disableShrink />
          </Grid>
        )}
        {positionData && positionData.length > 0 && (
          <>
            <Grid item xs={12} className={classes.rowMargin}>
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.heading}
              >
                <TrafficIcon className={clsx(classes.iconStyles)} /> Trafikk
                siste 7 dager
              </Typography>
              {positionData.length > 0 && (
                <TableFrontpage
                  key="fav"
                  editurl="/placements/edit/"
                  handleDelete={handleDelete}
                  addToFavorites={addToFavorites}
                  items={parseFavourites()}
                />
              )}
            </Grid>
            {/* best placements */}
            <Grid item xs={12} className={classes.rowMargin}>
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.heading}
              >
                <TrendingUp
                  className={clsx(classes.iconStyles, classes.greyIcons)}
                />{" "}
                Beste plasseringer
              </Typography>
              {positionData.length > 0 && (
                <TableFrontpage
                  key="best"
                  title="Beste plasseringer"
                  editurl="/placements/edit/"
                  items={parseBestPlacements()}
                  handleDelete={handleDelete}
                  addToFavorites={addToFavorites}
                />
              )}
            </Grid>
            {/* Worst placements */}
            <Grid item xs={12} className={classes.rowMargin}>
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.heading}
              >
                <WbSunnyOutlinedIcon
                  className={clsx(classes.iconStyles, classes.greyIcons)}
                />{" "}
                Siste plasseringer
              </Typography>
              {positionData && positionData.length > 0 && (
                <TableFrontpage
                  key="worst"
                  title="Dårligste plasseringer"
                  editurl="/placements/edit/"
                  items={parseWorstPlacements()}
                  handleDelete={handleDelete}
                  addToFavorites={addToFavorites}
                />
              )}
            </Grid>
          </>
        )}
        <br />
        <br />
        <br />
        <AlertDialog
          open={alertOpen}
          handleClose={handleOpenAlert}
          handleConfirm={confirmDelete}
          msg={"Er du sikkert at du vil slette?"}
        />
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}
