/* eslint-disable no-script-url */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import palette from "../../../theme/palette";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Link";
import FileIcon from "@material-ui/icons/FileCopy";
import ContentIcon from "@material-ui/icons/TextFields";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Menu from "@material-ui/core/Menu";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Hidden from "@material-ui/core/Hidden";

import CopyToClipboard from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";

import Visibility from "@material-ui/icons/Visibility";

import MoreVert from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ArrowDropUp";

import ExpandMore from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import CollectionsContentGrid from "./CollectionsContentGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
  },
  cardList: {
    borderRadius: 0,
    backgroundColor: palette.common.white,
    "&:last-of-type": {
      borderBottomLeftRadius: "3px",
      borderBottomRightRadius: "3px",
    },
    "&:first-of-type": {
      borderTopLeftRadius: "3px",
      borderTopRightRadius: "3px",
    },
    padding: "0",
    marginTop: "1px",
    "&:hover": {
      backgroundColor: palette.greys.lighter,
    },
  },
  cardListOpen: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "white",
    },
  },
  cardListActions: {
    cursor: "pointer",
    padding: "0 24px",
  },
  cardActionOpen: {
    backgroundColor: "#EAF7DC",
  },
  depositContext: {
    margin: "3rem 0",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    pointerEvents: "none",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginRight: theme.spacing(2),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardListIcon: {
    width: 22,
    height: 22,
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 16,
    height: 16,
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  ellipsis: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
  fullwidth: {
    width: "100%",
  },
  mrgRight: {
    marginRight: theme.spacing(2),
  },
  mrgTop: {
    marginTop: theme.spacing(1),
  },
  centAlg: {
    alignSelf: "center",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  wrapURL: {
    wordBreak: "break-all",
  },
  mobHidden: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobPadding: {
    padding: "0 !important",
  },
  mobPaddinLeft: {
    paddingLeft: "40px !important",
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      padding: "3px 0",
    },
  },
  buttonMenu: {
    padding: "3px 0",
    color: "#fff",
  },
  menuGrid: {
    position: "absolute",
    width: "125px",
    height: "40px",
    right: "12px",
    top: "12px",
    backgroundColor: "#666666",
    color: "#fff",
    paddingTop: "6px",
    textAlign: "center",
  },
  relPos: {
    position: "relative",
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary,
      },
    },
  },
}))(MenuItem);

export default function ElementItemCollectionsDetails(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const GRID_HEIGHT = [0, 162, 368, 495, 650];
  function setCopy() {
    setOpen(true);
  }

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function stopPropag(event) {
    event.stopPropagation();
    return;
  }

  function countDecimals(num) {
    if (!num) return 0;
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1].length || 0;
  }

  function formatStats(nr) {
    if (nr === undefined) return;
    let num = parseFloat(nr);

    if (num > 0 && num < 1) {
      return num.toFixed(3);
    } else if (num > 999) {
      return Math.round(num / 1000) + "K";
    } else {
      if (countDecimals(num) > 0) {
        return num.toFixed(2);
      } else {
        return num;
      }
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function handleCloseSnack(event) {
    setOpen(false);
  }
  function handleCloseDelete(event) {
    event.stopPropagation();
    props.handleDelete(props.id);
    setAnchorEl(null);
  }

  function doOpenLink(event) {
    event.stopPropagation();
    handleClose();

    props.setValues({
      ...props.values,
      name: props.name,
      title: props.title,
      url: props.url,
      id: props.id,
      placement: props.placement,
      placement_id: props.placement_id,
      description: props.description,
      placement_type: props.description,
      placement_source: props.placement_source,
      placement_medium: props.placement_source,
      placement_code: props.placement_code,
      placement_campaign: props.placement_source,
      extras: props.extras,
      campaign: props.campaign,
      isValidated: 3,
      finalURL: props.finalURL,
    });

    props.handleOpenDialog();
  }

  const iconType = props.type;
  const [isHover, setIsHover] = useState(false);
  const [expanded, setExpanded] = useState(false);

  function renderArrow(trend) {
    if (trend === 1) {
      return (
        <ArrowUpward
          className={clsx(classes.upStats, classes.cardListIconSmall)}
        />
      );
    } else if (trend === -1) {
      return (
        <ArrowDownward
          className={clsx(classes.downStats, classes.cardListIconSmall)}
        />
      );
    } else {
      return (
        <span className={clsx(classes.downStats, classes.cardListIconSmall)}>
          {}
        </span>
      );
    }
  }

  function handleClickMeny(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleHoverIn() {
    setIsHover(true);
  }
  function handleHoverOut() {
    setIsHover(false);
  }
  const handleTitleChange = (itemid) => (event) => {
    props.updateItemTitle(itemid, event.target.value);
  };

  function moveItemUp() {
    console.log(props.index, props.id);
    if (props.index > 0) {
      props.moveArr(props.index, props.index - 1);
    }
  }

  function moveItemDown() {
    console.log("click", props.index, props.total);
    if (props.index < props.total - 1) {
      props.moveArr(props.index, props.index + 1);
    }
  }

  function showContent(content) {
    if (props.type == 2) return;

    let icon = (
      <IconButton aria-label="Share" className={classes.button}>
        <ContentIcon className={classes.cardListIcon} />
        <Typography variant="subtitle2">{content}:</Typography>
      </IconButton>
    );
    return icon;
  }
  function showCollapsingIcon(icon) {
    if (isHover === false) return;
    let iconGrid = (
      <IconButton
        className={clsx(classes.button, classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        aria-expanded={expanded}
        aria-label="Show more"
      >
        <ExpandMore className={classes.listIcon} />
      </IconButton>
    );

    return iconGrid;
  }
  const clsMoveBtnArr = [classes.button, classes.menuIcons];
  if (!props.id) {
    clsMoveBtnArr.push(classes.fadeoutRow);
  }
  function getGridWidth() {
    if (!props.gridWidth) return 4;
    let gw = 3 * props.gridWidth;

    return gw;
  }

  function getGridHeight() {
    let gh = GRID_HEIGHT[props.gridHeight];

    if (props.gridHeight > 4) gh = props.gridHeight;
    return gh;
  }

  function changeLayout(w, h) {
    setAnchorEl(null);
    props.changeGrid(w, h, props.index);
    console.log(w, h);
  }
  const isAnnonsebilag =
    props.items &&
    (props.items.contenttype === "annonsebilag" ||
      props.items.contenttype === "lotteriresultater" ||
      props.items.contenttype === "tallstatistikk");

  console.log("RERENDERING", props);
  return (
    <Grid
      item
      xs={12}
      sm={getGridWidth()}
      onClick={handleExpandClick}
      className={classes.relPos}
    >
      <div className={classes.menuGrid}>
        <Grid container>
          <Grid item xs={4}>
            <IconButton
              disableRipple
              disableFocusRipple
              onClick={moveItemDown}
              className={classes.buttonMenu}
            >
              <ExpandMore className={classes.cardListIcon} />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              disableRipple
              disableFocusRipple
              onClick={moveItemUp}
              className={classes.buttonMenu}
            >
              <ExpandLess className={classes.cardListIcon} />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            {parseInt(props.gridHeight, 10) < 5 && (
              <IconButton
                disableRipple
                disableFocusRipple
                onClick={handleClickMeny}
                className={classes.buttonMenu}
              >
                <MoreVert className={classes.cardListIcon} />
              </IconButton>
            )}
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                selected={props.gridWidth + "x" + props.gridHeight === "2x2"}
                onClick={() => changeLayout(2, 2)}
              >
                <ListItemText primary="2x2 layout (normal)" />
              </MenuItem>
              {!isAnnonsebilag && (
                <MenuItem
                  selected={props.gridWidth + "x" + props.gridHeight === "4x1"}
                  onClick={() => changeLayout(4, 1)}
                >
                  <ListItemText primary="4x1 layout (langt banner)" />
                </MenuItem>
              )}
              <MenuItem
                selected={props.gridWidth + "x" + props.gridHeight === "4x2"}
                onClick={() => changeLayout(4, 2)}
              >
                <ListItemText primary="4x2 layout (banner)" />
              </MenuItem>
              <MenuItem
                selected={props.gridWidth + "x" + props.gridHeight === "4x3"}
                onClick={() => changeLayout(4, 3)}
              >
                <ListItemText primary="4x3 layout (stort banner)" />
              </MenuItem>
              <MenuItem
                selected={props.gridWidth + "x" + props.gridHeight === "4x4"}
                onClick={() => changeLayout(4, 4)}
              >
                <ListItemText primary="4x4 layout (board)" />
              </MenuItem>
            </StyledMenu>
          </Grid>
        </Grid>
      </div>

      <CollectionsContentGrid
        name={props.items.name}
        divWidth={props.gridWidth}
        key={props.position + props.items.name}
        contentNr={props.position}
        content={props.items}
        id={props.id}
        url={props.url}
        position={props.position}
        contentoptions={undefined}
        contentWidth={100}
        contentWidthUnit={"%"}
        contentHeight={getGridHeight()}
        contentHeightUnit={"px"}
      />
    </Grid>
  );
}
