/* eslint-disable no-script-url */

import React from "react";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import palette from "../theme/palette";

const useStyles = makeStyles(theme => ({
  iconStyles: {
    verticalAlign: "middle"
  },
  smallIcon: {
    verticalAlign: "middle",
    width: 16,
    height: 16
  },
  noPadd: {
    padding: "0 !important",
    color: palette.common.white,
    borderBottom: "none",
    verticalAlign: "middle",
    fontWeight: 600
  },
  downStats: {
    color: palette.error.main
  },
  upStats: {
    color: palette.secondary.main
  },
  bigIcon: {
    width: 24,
    height: 24
  },
  bigIconBlack: {
    width: 24,
    height: 24,
    color: palette.common.black
  }
}));

export default function FormSelect(props) {
  const classes = useStyles();
  const item = props.item;
  let selectedValue = props.value;

  return (
    <Grid item xs={12} sm={4} key={item.name}>
      <TextField
        id={"filled-" + item.name}
        select
        label={item.label}
        className={classes.textField}
        value={selectedValue}
        onChange={props.onChange(item.paramname)}
        SelectProps={{
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="filled"
        key={item.paramname}
        disabled={props.isDisabled}
      >
        {item.items &&
          item.items.map(option => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    </Grid>
  );
}
