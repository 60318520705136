/* eslint-disable no-script-url */

import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import palette from "../../../theme/palette";

const useStyles = makeStyles({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
    boxShadow: "none"
  },
  depositContext: {
    margin: "2rem 0"
  },
  button: {
    paddingTop: "9px",
    paddingBottom: "9px"
  }
});

export default function DashInfo(props) {
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.root)}>
      <Typography
        component="h2"
        variant="subtitle1"
        color="primary"
        align="left"
      >
        Til informasjon
      </Typography>

      <Typography color="textSecondary" className={classes.depositContext}>
        Her ser du hvordan den nye partnerportalen kommer til å bli.
        <br /> <br /> Vi er godt i gang med utviklingen og du får beskjed når
        det nærmer seg lansering.
      </Typography>
      {props.ver2 && (
        <Button variant="outlined" color="primary" className={classes.button}>
          Les mer
        </Button>
      )}
    </Paper>
  );
}
