import React, { PureComponent } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "fre 13. nov",
    Lotteri: 590,
    Insta: 800,
    Sport: 1400,
  },
  {
    name: "lør 14. nov",
    Lotteri: 868,
    Insta: 967,
    Sport: 1506,
  },
  {
    name: "søn 15. nov",
    Lotteri: 1397,
    Insta: 1098,
    Sport: 989,
  },
  {
    name: "man 16. nov",
    Lotteri: 1480,
    Insta: 1200,
    Sport: 1228,
  },
  {
    name: "tirs 17. nov",
    Lotteri: 1520,
    Insta: 1108,
    Sport: 1100,
  },
  {
    name: "ons 18. nov",
    Lotteri: 1400,
    Insta: 680,
    Sport: 1700,
  },
  {
    name: "tors 19. nov",
    Lotteri: 1300,
    Insta: 380,
    Sport: 1100,
  },
];

export default class GlobalChart extends PureComponent {
  render() {
    return (
      <div style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer width="100%">
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 80,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="name"
              label={{
                value: "",
                position: "insideBottomRight",
                offset: 0,
              }}
              scale="point"
            />

            <Tooltip />

            <Area dataKey="Sport" fill="#BDE5BA" stroke="#00962D" />
            <Line dataKey="Insta" stroke="#B620E0" />
            <Line dataKey="Lotteri" stroke="#0069E2" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
