/* eslint-disable no-script-url */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import palette from "../theme/palette";

import FormCheckbox from "./FormCheckbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import TemplateIcon from "@material-ui/icons/Folder";
import ContentIcon from "@material-ui/icons/TextFields";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
  },

  container: {
    height: "100%",
  },
  iconGridClass: {
    display: "flex",
    alignItems: "center",
  },
  depositContext: {
    margin: "3rem 0",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  inactiveIcon: {
    color: palette.text.primary,
  },
  cardListIconButtons: {
    width: 22,
    height: 22,
  },
  cardListIcon: {
    marginRight: "24px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSchedule: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 16,
    height: 16,
  },
  cardListActions: {
    [theme.breakpoints.down("xs")]: {
      padding: "12px 0",
    },
    borderBottom: "1px solid #C0C0C0",
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  textEllipse: {
    justifyContent: "flex-start",
    width: "100%",
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      padding: "3px 0",
    },
  },
  alRight: {
    textAlign: "right",
    color: "#222222",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: "2.4rem",
  },
  tblPad: {
    padding: "0 8px",
  },
  scheduleIcon: {
    textAlign: "right",
    paddingRight: "8px",
  },
  hubMargRight: {
    paddingRight: "10px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  textField: {
    margin: "0 10px 0 0",
    maxWidth: "40px",
    "& input": {
      padding: "12px 0px 10px",
      textAlign: "center",
    },
  },
  padLeft: {
    paddingLeft: "10px",
  },
  alignRight: {
    justifyContent: "flex-end",
    paddingRight: "16px",
  },
  hubTitle: {
    maxWidth: "40px",
    width: "100%",
    textAlign: "center",
  },
  hubMarg: {
    marginRight: "10px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary,
      },
    },
  },
}))(MenuItem);

function ElementItemTippetipsHeader(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconType = props.type;
  const [expanded, setExpanded] = React.useState(false);
  const [isHChecked, setHChecked] = useState(false);
  const [isUChecked, setUChecked] = useState(false);
  const [isBChecked, setBChecked] = useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleHChange(event) {
    setHChecked(!isHChecked);
  }
  function handleUChange(event) {
    setUChecked(!isUChecked);
  }
  function handleBChange(event) {
    console.log(isBChecked);
    setBChecked(!isBChecked);
  }

  function handleClick() {}

  function handleClickMeny(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function stopPropag(event) {
    event.stopPropagation();
    return;
  }
  function handleCloseDelete(event) {
    event.stopPropagation();

    props.handleDelete(props.id);
    setAnchorEl(null);
  }

  function handleCloseDeleteLink(event) {
    event.stopPropagation();

    props.handleDeleteLink(props.id);
    setAnchorEl(null);
  }

  function handleFavorites() {
    setAnchorEl(null);

    props.addToFavorites(props.id, props.favourite);
  }

  return (
    <Grid container className={classes.cardListActions}>
      <Grid
        item
        xs={6}
        sm={6}
        onClick={handleClick}
        className={classes.iconGridClass}
      >
        <Grid container>
          <Grid item xs={2}>
            <IconButton
              disableRipple
              disableFocusRipple
              aria-label="Share"
              className={clsx(classes.button, classes.textEllipse)}
            >
              #
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <IconButton
              disableRipple
              disableFocusRipple
              aria-label="Share"
              className={clsx(classes.button, classes.textEllipse)}
            >
              <Typography variant="subtitle2" noWrap>
                Lag
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
        onClick={handleClick}
        zeroMinWidth
        className={classes.iconGridClass}
      >
        <Grid container>
          <Grid item xs={3} className={classes.padLeft}>
            H
          </Grid>
          <Grid item xs={3} className={classes.padLeft}>
            U
          </Grid>
          <Grid item xs={3} className={classes.padLeft}>
            B
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        onClick={handleClick}
        className={classes.iconGridClass}
      >
        <Grid container spacing={0} className={classes.container}>
          <Grid
            item
            xs={9}
            className={clsx(classes.hubMargRight, classes.inactiveIcon)}
          >
            <div className={clsx(classes.hubMarg, classes.hubTitle)}>H</div>
            <div className={clsx(classes.hubMarg, classes.hubTitle)}>U</div>
            <div className={classes.hubTitle}>B</div>
          </Grid>
          <Grid
            item
            xs={3}
            className={clsx(classes.iconGridClass, classes.alignRight)}
          >
            Sum
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(ElementItemTippetipsHeader);
