/* eslint-disable no-script-url */

import React from "react";

import { makeStyles } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

import palette from "../theme/palette";

const useStyles = makeStyles(theme => ({
  iconStyles: {
    verticalAlign: "middle"
  },
  smallIcon: {
    verticalAlign: "middle",
    width: 16,
    height: 16
  },
  noPadd: {
    padding: "0 !important",
    color: palette.common.white,
    borderBottom: "none",
    verticalAlign: "middle",
    fontWeight: 600
  },
  downStats: {
    color: palette.error.main
  },
  upStats: {
    color: palette.secondary.main
  },
  bigIcon: {
    width: 24,
    height: 24
  },
  bigIconBlack: {
    width: 24,
    height: 24,
    color: palette.common.black
  },
  mobLabel: {
    transform: "translate(0px, 5px) scale(1) !important"
  },
  mobBg: {
    backgroundColor: "#EEEEEE",
    marginTop: theme.spacing(2),
    paddingLeft: "20px",
    "& .MuiInput-underline:before ": {
      left: "-20px !important",
      borderBottom: "1px solid rgba(0, 0, 0, 1)"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before ": {
      borderBottom: "1px solid rgba(0, 0, 0, 1)"
    },
    "& .MuiInput-underline:hover:after": {
      borderBottom: "none"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "inherit"
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 18px)",
      color: "#000"
    }
  }
}));

export default function FormSelectMobile(props) {
  const classes = useStyles();
  const items = props.items;
  const label = props.label;
  let selectedValue = props.selectedValue;

  return (
    <div className={classes.mobBg}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple" className={classes.mobLabel}>
          {label}
        </InputLabel>
        <Select
          native
          value={selectedValue}
          onChange={props.onChange}
          inputProps={{
            name: "age",
            id: "age-native-simple"
          }}
          className={classes.menuIcon}
        >
          {items &&
            items.map(option => (
              <option value={option.category} key={option.id}>
                {option.category.replace(/^\w/, function(chr) {
                  return chr.toUpperCase();
                }) +
                  " (" +
                  option.nr +
                  ")"}
              </option>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
