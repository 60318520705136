import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withRouter } from "react-router-dom";
import ContentIcon from "@material-ui/icons/TextFields";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import palette from "../../../theme/palette";
import ContentTable from "./ContentTable";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {},
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,

    overflow: "auto"
  },
  container: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px"
    }
  },
  mobCont: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    marginTop: theme.spacing(2)
  },
  fixedHeight: {
    height: 240
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1)
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  chip: {
    margin: theme.spacing(1)
  },
  placementlist: {
    margin: "32px 0"
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary
  },
  bigIndicator: {
    height: 4
  },
  tabHeading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px"
  },
  tabHeadingText: {
    marginLeft: theme.spacing(2)
  },
  mobPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0px"
    }
  }
}));

function ContentListHolder(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:767px)");

  const match = props.match;
  const isModal = props.isModal;
  const [value, setValue] = React.useState(0);
  const ctype = match.params.contenttype ? match.params.contenttype : "Alle";
  const [contenttype, setContentType] = React.useState(ctype);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const theme = {
    spacing: 8
  };

  let contClasses = classes.container;
  if (props.isModal) {
    contClasses = classes.mobCont;
  }

  return (
    <Container maxWidth="xl" className={contClasses}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            classes={{ indicator: classes.bigIndicator }}
            centered
          >
            <Tab
              label={
                <>
                  <div className={classes.tabHeading}>
                    <ContentIcon />
                    <span className={classes.tabHeadingText}>
                      Egendefinert {!isMobile && "innhold"}
                    </span>
                  </div>
                </>
              }
              variant="fullWidth"
            />
            <Tab
              label={
                <>
                  <div className={classes.tabHeading}>
                    <AddIcon />
                    <span className={classes.tabHeadingText}>
                      Lag nytt {!isMobile && "innhold"}
                    </span>
                  </div>
                </>
              }
              variant="fullWidth"
            />
          </Tabs>
          <Grid container spacing={0} className={classes.mobPadding}>
            {value === 0 && (
              <ContentTable
                isModal={isModal}
                type="egen"
                contentType={ctype}
                updateContent={props.updateContent}
              >
                Item One
              </ContentTable>
            )}
            {value === 1 && (
              <ContentTable
                isModal={isModal}
                type="nytt"
                contentType={ctype}
                updateContent={props.updateContent}
              >
                Item Two
              </ContentTable>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withRouter(ContentListHolder);
