import React from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import StarIcon from "@material-ui/icons/Grade";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import ContentIcon from "@material-ui/icons/TextFields";

import { Typography } from "@material-ui/core";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary
      }
    }
  }
}))(MenuItem);

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleToggle() {
    setOpen(!open);
  }

  function handleNestedListToggle(item) {
    setOpen(!open);
  }

  return (
    <div>
      <Typography variant="h1" component="h2" gutterBottom>
        Kontektsmeny/valgmeny
      </Typography>

      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Favoritter" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <PlacementIcon />
          </ListItemIcon>
          <ListItemText primary="Plasseringer" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <ContentIcon />
          </ListItemIcon>
          <ListItemText primary="Innhold" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
