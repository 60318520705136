import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import palette from "../../../theme/palette";
import { makeStyles } from "@material-ui/core/styles";

import update from "immutability-helper";

const useStyles = makeStyles((theme) => ({
  win: {
    minWidth: "613px",
    maxWidth: "613px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      maxWidth: "100%",
    },
  },
  cellPadLeft: {
    paddingLeft: theme.spacing(1),
  },
  cellPadRight: {
    paddingRight: theme.spacing(1),
  },
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 8px",
  },
  clockSelect: {
    width: "92px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& .MuiFilledInput-inputSelect": {
      padding: "10px",
    },
  },
  titleArea: {
    backgroundColor: "rgba(216, 216, 216, 0.2)",
    "& .MuiTypography-h6": {
      fontSize: "2.2rem",
      fontWeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "76px",
    },
  },
  hourDrop: {
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  button: {
    color: palette.primary.button,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0 0 0 !important",
    },
  },
  blueBtn: {
    color: palette.primary.button,
  },
  dividerPad: {
    margin: theme.spacing(3) + "px 0px ",
  },
  dataPicker: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    "& .MuiInputBase-adornedEnd": {
      padding: "8px 8px 0 8px",
    },
  },
  flexGrid: {
    display: "flex",
    flexDirection: "row",
  },
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    "& label + .MuiInput-formControl": {
      marginTop: "16px",
    },
    "& .MuiInput-root": {
      width: "100%",
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "transparent",
      padding: "12px",
    },
  },
  dialogWin: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "200 !important",
    },
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthXl": {
        [theme.breakpoints.down("sm")]: {
          margin: 0,
          width: "100%",
          padding: "0",
          height: "100%",
          maxHeight: "100%",
        },
      },
    },
  },
  dialogBtns: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "20px",
      flexDirection: "column",
    },
  },
}));
export default function RenameCollectionDialog(props) {
  const classes = useStyles();
  const optionsName = ["", "Tidsstyring", "Ukedag", "Pott"];
  let defaultCondition = [
    {
      conditionName: "Ny betingelse",
      option: 0,
      conditionParameter: "and",
      selectedDateFra: new Date(),
      selectedDateTil: new Date(),
      ukeDager: [],
      pott: 0,
      pottSize: 0,
    },
  ];

  let defaultDialogTitle = "";
  let isEditing = false;

  if (props.placementSchedule) {
    isEditing = true;
    defaultCondition = props.placementSchedule.contentoptions;
    defaultDialogTitle = "Rediger innholdsstyring";
  } else {
    isEditing = false;
    defaultDialogTitle = "Endre navn på tittelen";
  }

  const [dialogTitle, setDialogTitle] = React.useState(defaultDialogTitle);
  const [isValidated, setValidated] = React.useState(false);
  const [title, setTitle] = React.useState(props.title);

  function updateTitle() {
    props.updateTitle(title);
    props.handleClose();
  }

  const handleTitle = (name) => (event) => {
    let newValue = event.target.value;
    if (newValue.length < 3) {
      setValidated(false);
    } else {
      setValidated(true);
    }
    setTitle(newValue);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        className={classes.dialogWin}
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleArea}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent className={classes.win}>
          <Grid container spacing={0}>
            <TextField
              id={"filled-titel"}
              label="Tittel"
              className={classes.textField}
              value={title}
              onChange={handleTitle("title")}
              margin="normal"
              variant="filled"
            />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogBtns}>
          <Button
            onClick={props.handleClose}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Avbryt
          </Button>
          <Button
            onClick={updateTitle}
            color="primary"
            autoFocus
            variant="contained"
            className={classes.button}
            disabled={!isValidated}
          >
            ENDRE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
