import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/GradeOutlined";

import clsx from "clsx";
import palette from "../../../theme/palette";
import Button from "@material-ui/core/Button";

import AddPartnerDialog from "../../../components/AddPartnerDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  mainheading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    alignItems: "center",
    display: "flex"
  },
  heading: {
    marginBottom: theme.spacing(3)
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  iconRight: {
    marginRight: "1.2rem"
  },
  paper: {
    width: "100%",
    height: "100%",
    padding: "2.5rem"
  },
  greenPaper: {
    backgroundColor: palette.secondary.light,
    boxShadow: "none"
  },
  contactFormPaper: {
    maxWidth: "71.5rem"
  },
  bigAvatar: {
    marginRight: theme.spacing(3),
    width: 92,
    height: 92
  },
  flexDiv: {
    display: "flex",
    alignItems: "center"
  },
  flexRight: {
    justifyContent: "flex-end"
  },
  flexCol: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    alignSelf: "flex-end",
    marginLeft: theme.spacing(2)
  },
  buttonUpload: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-end",
    color: palette.common.white,
    backgroundColor: palette.secondary.main
  }
}));

function AdminFrontpage(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    heading: "",
    newsheading: "",
    newsbody: "",
    showfeature: true,
    shownews: true
  });

  const [addPartnerOpen, setAddPartnerOpen] = useState(false);

  function handleOpenPartner() {
    setAddPartnerOpen(!addPartnerOpen);
  }

  const [addUserOpen, setAddUserOpen] = useState(false);

  function handleOpenUser() {
    setAddUserOpen(!addUserOpen);
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleCheckboxChange = name => event => {
    setValues({ ...values, [name]: event.target.checked });
  };
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />

      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={0}>
          <Grid container spacing={3} className={classes.centerVert}>
            <Grid item xs={12} align="left">
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.mainheading}
              >
                <StarIcon className={classes.iconRight} /> Feature-modul og
                forsidenyhet
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Paper className={clsx(classes.greenPaper, classes.paper)}>
                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Her har du som administrator muligheten til å legge
                  til/redigere feature-modul og forsidenyheter, i tillegg til å
                  administrere partnere, brukere og retningslinjer.
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} align="left">
              <Paper
                className={clsx(
                  classes.paper,
                  classes.contactFormPaper,
                  classes.flexCol
                )}
              >
                <Grid container spacing={3} className={classes.centerVert}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      align="left"
                      className={clsx(classes.flexDiv, classes.heading)}
                    >
                      Feature-modul
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.depositContext}
                    >
                      Legg til en fremhevet modul på forsiden.
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttonUpload}
                    >
                      LEGG TIL / ENDRE BILDE
                    </Button>
                  </Grid>
                  <Grid item xs={12} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-partner"
                      label="Heading"
                      className={classes.textField}
                      value={values.heading}
                      onChange={handleChange("heading")}
                      margin="normal"
                      variant="filled"
                    ></TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.showfeature}
                          onChange={handleCheckboxChange("showfeature")}
                          value="showfeature"
                        />
                      }
                      label="Skjul Feature-modul"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={clsx(classes.flexDiv, classes.flexRight)}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      OPPDATER
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} align="left">
              <Paper
                className={clsx(
                  classes.paper,
                  classes.contactFormPaper,
                  classes.flexCol
                )}
              >
                <Grid container spacing={3} className={classes.centerVert}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      align="left"
                      className={clsx(classes.flexDiv, classes.heading)}
                    >
                      Administrer forsidenyhet
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.depositContext}
                    >
                      Her kan du legge ut en nyhet på forsiden.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-partner"
                      label="Heading"
                      className={classes.textField}
                      value={values.newsheading}
                      onChange={handleChange("newsheading")}
                      margin="normal"
                      variant="filled"
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-etternavn"
                      label="Mengdetekst"
                      className={classes.textField}
                      value={values.newsbody}
                      onChange={handleChange("newsbody")}
                      margin="normal"
                      variant="filled"
                      multiline
                      rows="4"
                      rowsMax="4"
                    ></TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.shownews}
                          onChange={handleCheckboxChange("shownews")}
                          value="shownews"
                        />
                      }
                      label="Skjul forsidenyhet"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    className={clsx(classes.flexDiv, classes.flexRight)}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      OPPDATER
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <AddPartnerDialog
          open={addPartnerOpen}
          handleClose={handleOpenPartner}
          handleChange={handleChange}
          values={values}
        />
      </Container>
    </main>
  );
}

export default withRouter(AdminFrontpage);
