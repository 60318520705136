/* eslint-disable no-script-url */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import EnhancedMenu from "../../../components/EnhancedMenu";
import Grid from "@material-ui/core/Grid";
import menuItems from "../../../data/link-products";
import palette from "../../../theme/palette";

const useStyles = makeStyles({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
    boxShadow: "none"
  },
  depositContext: {
    margin: "2rem 0"
  },
  button: {
    paddingTop: "9px",
    paddingBottom: "9px"
  },
  textField: {
    padding: "5px"
  }
});

export default function LinksChoseProduct(props) {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = React.useState(null);

  function handleButtonClick(event) {
    setAnchorElement(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorElement(null);
  }

  function handleMenuItemClick(event) {
    props.setValues({
      ...props.values,
      product: event.caption
    });

    setAnchorElement(null);
  }

  return (
    <>
      <Grid item xs={4}>
        <TextField
          id="filled-relatertprodukt"
          label="Velg relatert produkt"
          placeholder="Velg et alternativ"
          className={classes.textField}
          onClick={handleButtonClick}
          value={props.values.product}
          margin="normal"
          variant="filled"
          autoComplete="off"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <EnhancedMenu
        open={Boolean(anchorElement)}
        menuItems={menuItems}
        anchorElement={anchorElement}
        onClose={handleMenuClose}
        onMenuItemClick={handleMenuItemClick}
      />
    </>
  );
}
