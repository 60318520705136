import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    boxShadow: 'none'
  },
  bluePaper: {
    backgroundColor: palette.common.blue
  },
  yellowPaper: {
    backgroundColor: palette.common.yellow,
    maxWidth: 350
  },
  greenPaper: {
    backgroundColor: palette.common.green,
    maxWidth: 350
  }
}));

export default function PaperSheet() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1" component="h2" gutterBottom>
        Paper
      </Typography>
      <Paper className={clsx(classes.root, classes.bluePaper)}>
        <Typography variant="h5" component="h3">
          This is a sheet of paper.
        </Typography>
        <Typography component="p">
          Paper can be used to build surface or other elements for your
          application.
        </Typography>
      </Paper>
      <br />
      <Paper className={clsx(classes.root, classes.yellowPaper)}>
        <Typography variant="h5" component="h3">
          This is a sheet of paper.
        </Typography>
        <Typography component="p">
          Paper can be used to build surface or other elements for your
          application.
        </Typography>
      </Paper>
      <br />
      <Paper className={clsx(classes.root, classes.greenPaper)}>
        <Typography variant="h5" component="h3">
          This is a sheet of paper.
        </Typography>
        <Typography component="p">
          Paper can be used to build surface or other elements for your
          application.
        </Typography>
      </Paper>
    </div>
  );
}
