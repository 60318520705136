import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  win: {
    minWidth: "800px"
  }
}));

export default function AddUserDialog(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          {"Legg til ny bruker"}
        </DialogTitle>
        <DialogContent className={classes.win}>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.flexDiv}>
              <TextField
                id="filled-select-partner"
                select
                label="Velg partner"
                className={classes.textField}
                value={props.values.partner1}
                onChange={props.handleChange("partner1")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="filled"
              >
                {props.partners.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4} className={classes.flexDiv}>
              <TextField
                id="filled-select-partner"
                select
                label="Velg bruker"
                className={classes.textField}
                value={props.values.user}
                onChange={props.handleChange("user")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="filled"
              >
                {props.users.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4} className={classes.flexDiv}></Grid>

            <Grid item xs={4} className={classes.flexDiv}>
              <TextField
                id="filled-select-fornavn"
                label="Fornavn"
                className={classes.textField}
                value={props.values.firstname}
                onChange={props.handleChange("firstname")}
                margin="normal"
                variant="filled"
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.flexDiv}>
              <TextField
                id="filled-select-etternavn"
                label="Etternavn"
                className={classes.textField}
                value={props.values.lastname}
                onChange={props.handleChange("lastname")}
                margin="normal"
                variant="filled"
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.flexDiv}>
              <TextField
                id="filled-select-epost"
                label="Epost"
                className={classes.textField}
                value={props.values.email}
                onChange={props.handleChange("email")}
                margin="normal"
                variant="filled"
              ></TextField>
            </Grid>
            <Grid item xs={8} align="left" className={classes.flexDiv}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Avbryt
          </Button>
          <Button
            onClick={props.handleClose}
            color="primary"
            autoFocus
            variant="contained"
            className={classes.button}
          >
            LAGRE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
