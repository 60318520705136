/* eslint-disable no-script-url */

import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import palette from "../theme/palette";

const useStyles = makeStyles({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
    boxShadow: "none"
  },
  depositContext: {
    margin: " 0"
  },
  button: {
    paddingTop: "9px",
    paddingBottom: "9px"
  }
});

export default function MobileNotSupported(props) {
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.root)}>
      <Typography
        color="textSecondary"
        className={classes.depositContext}
        variant="body2"
      >
        Denne funksjon er ikke tilgjengelig på mobil ennå. Vennligst bruk
        desktop.
      </Typography>
    </Paper>
  );
}
