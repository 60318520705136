import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CopyToClipboard from "react-copy-to-clipboard";

import Typography from "@material-ui/core/Typography";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import EmptyContent from "./EmptyContent";
import AddIcon from "@material-ui/icons/Add";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AlertDialog from "../../../components/AlertDialog";
import update from "immutability-helper";
import Visibility from "@material-ui/icons/Visibility";
import MoreVert from "@material-ui/icons/MoreVert";

import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";

import TableCollectionsDetails from "./TableCollectionsDetails";
import GridCollectionsDetails from "./GridCollectionsDetails";

import palette from "../../../theme/palette";
import { withRouter } from "react-router-dom";
import StatsContext from "../../../context/StatsContext";
import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  nooutline: {
    border: "1px transparent solid",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  menuTop: {
    paddingRight: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  buttonMob: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "7px 10px",
    },
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(1),
  },
  placementlist: {
    marginBottom: "32px",
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary,
  },
  buttonMenuLeft: {
    marginRight: "10px",
  },
  outerPadding: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  buttonLeft: {
    marginLeft: "10px",
  },
  centerVert: {
    alignItems: "center",
  },
  buttonTopMargin: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary,
      },
    },
  },
}))(MenuItem);

function CollectionsHolder(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);
  const isMobile = useMediaQuery("(max-width:767px)");
  const [delItem, setDelItem] = useState(false);

  const [gridView, setGridView] = useState(false);
  const [open, setOpenDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [addRow, setAddRow] = useState(false);
  const [updateRow, setUpdateRow] = useState(false);

  const [deleteURL, setDeleteURL] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpenDialog() {
    setOpenDialog(!open);
  }

  function handleOpenAlert() {
    setAlertOpen(!alertOpen);
  }

  function handleChangeGridView() {
    if (props.isBlocked) return;
    setGridView(!gridView);
  }

  function handleClose() {
    if (props.isBlocked) return;
    setAnchorEl(null);
  }
  function handleCloseDelete(event) {
    event.stopPropagation();
    handleDelete(props.id);
    setAnchorEl(null);
  }

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleRename(event) {
    event.stopPropagation();
    props.renameCollection();
    setAnchorEl(null);
  }
  function doOpenLink(event) {
    event.stopPropagation();
  }

  function saveNewRow(title, url) {
    addNewRow(title, url);
    // setOpenDialog(!open);
    // handleReset();
  }

  function do_addNewRow() {
    if (props.isBlocked) return;
    setGridView(false);
    props.addNewRow();
  }
  function closeWin() {
    setOpenDialog(!open);
    handleReset();
  }

  function stopPropag(event) {
    event.stopPropagation();
    return;
  }

  const [displayCategory, setCategory] = useState("Alle");

  function addNewRow(title, url) {
    if (!url) return;
    const newTitle = title === "" ? "Ny lenke" : title;
    const id = props.values.id ? props.values.id : null;
    var statsObj = {
      3: {
        clicks: 0,
        clicks_trend: 0,
      },
      6: {
        clicks: 0,
        clicks_trend: 0,
      },
      12: {
        clicks: 0,
        clicks_trend: 0,
      },
      24: {
        clicks: 0,
        clicks_trend: 0,
      },
    };

    var newrow = {
      campaign: "Ny",
      placement_type: props.values.placement_type,
      extras: props.values.extras,
      name: "Navn",
      description: "Plasseringstype",
      placement: props.values.placement,
      placement_code: props.values.placement_code,
      placement_source: props.values.placement_source,
      placement_medium: props.values.placement_medium,
      placement_campaign: props.values.placement_campaign,
      title: "Tittel",
      url:
        "https://widget.tippebannere.no/v3/2.0/Spillknapper.aspx?s_spl=1&amp;btn=kongkasino&amp;index=1&amp;s_spillher=true&amp;p_id=100&amp;",
      id: id,
      stats: statsObj,
    };

    props.setLinksData([...props.items, newrow]);
    //setAddRow(newrow);

    //props.showAlert("Lagret!", "success");
  }

  function doDelete(itemId) {
    setDeleteURL(
      "https://nt-ekom-2.herokuapp.com/collection/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
    props.showAlert("Slettet!", "success");
  }

  React.useEffect(() => {
    const deleteData = async () => {
      const result = await axios.delete(deleteURL);
      props.history.push("/collections");
    };
    if (deleteURL != "") {
      deleteData();
    }
  }, [deleteURL]);

  function handleUpdateTitle(id, newTitle) {
    var updatedrow = {
      title: newTitle,
      id: id,
    };
    props.showAlert("Oppdatert!", "success");
    setUpdateRow(updatedrow);
  }

  function handleDelete(id) {
    const delObj = {};
    delObj.id = id;
    setDelItem(delObj);

    handleOpenAlert();
  }

  function confirmDelete() {
    doDelete(props.collectionid);
    handleOpenAlert();
  }

  function updateItemTitle(itemId, title) {
    const items = props.items.map((item, key) => {
      if (item.id === itemId) {
        item.title = title;
      }
      return item;
    });

    props.setLinksData(items);
  }
  function handleReset() {
    props.setValues({
      name: "",
      title: "",
      id: "",
      url: "",
      description: "",
      placement: "",
      placement_id: "",
      placement_type: "",
      placement_source: "",
      placement_medium: "",
      placement_code: "",
      placement_campaign: "",
      product: "",
      extras: "",
      campaign: "",
      isValidated: 0,
      finalURL: "",
      errors: [],
    });
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={0}>
        <Grid
          container
          spacing={0}
          className={clsx(classes.centerVert, classes.outerPadding)}
        >
          {props.title && (
            <>
              <Grid item xs={12} sm={3} md={3} align="left">
                <Typography
                  variant="h1"
                  component="h1"
                  align="left"
                  className={classes.heading}
                >
                  {props.title}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={8} md={8} align="right">
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(classes.nooutline, classes.mobbtn)}
                  onClick={do_addNewRow}
                  disabled={props.isBlocked}
                >
                  LEGG TIL INNHOLD
                </Button>

                <CopyToClipboard
                  onCopy={props.setCopy}
                  text={props.getCollectionURL()}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className={clsx(
                      classes.nooutline,
                      classes.buttonLeft,
                      classes.mobbtn
                    )}
                  >
                    KOPIER KODE
                  </Button>
                </CopyToClipboard>

                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.heading, classes.menuTop)}
                  onClick={props.saveData}
                  disabled={props.isBlocked}
                >
                  Oppdater
                </Button>
              </Grid>
              <Grid item xs={2} sm={1} md={1} align="right">
                <IconButton
                  className={classes.heading}
                  onClick={handleChangeGridView}
                  disabled={props.isBlocked}
                >
                  {!gridView && <Visibility className={classes.listIcon} />}
                  {gridView && (
                    <FormatListBulletedIcon className={classes.listIcon} />
                  )}
                </IconButton>

                <IconButton
                  onClick={handleClick}
                  className={classes.heading}
                  disabled={props.isBlocked}
                >
                  <MoreVert className={classes.listIcon} />
                </IconButton>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  disabled={props.isBlocked}
                >
                  <StyledMenuItem onClick={handleCloseDelete}>
                    <ListItemText primary="Slett" />
                  </StyledMenuItem>
                  <StyledMenuItem onClick={handleRename}>
                    <ListItemText primary="Endre navn" />
                  </StyledMenuItem>
                </StyledMenu>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={12} className={classes.placementlist}>
          {props.items.length === 0 && (
            <>
              <EmptyContent
                txt='Klikk på "Legg til innhold" for å komme i gang eller velg en av
            malene under.'
                templates={props.templates}
                addTemplate={props.addTemplate}
              />
            </>
          )}

          {!gridView && (
            <TableCollectionsDetails
              selectedCat={displayCategory}
              rows={props.items}
              removeRow={props.removeRow}
              handleDelete={handleDelete}
              handleOpenDialog={handleOpenDialog}
              setCollectionData={props.setCollectionData}
              updateItemTitle={updateItemTitle}
              handleUpdateTitle={handleUpdateTitle}
              moveArr={props.moveArr}
              updatePlacementContent={props.updatePlacementContent}
              isBlocked={props.isBlocked}
            />
          )}

          {gridView && (
            <GridCollectionsDetails
              selectedCat={displayCategory}
              rows={props.items}
              handleDelete={handleDelete}
              handleOpenDialog={handleOpenDialog}
              setValues={props.setValues}
              values={props.values}
              setCollectionData={props.setCollectionData}
              updateItemTitle={updateItemTitle}
              handleUpdateTitle={handleUpdateTitle}
              moveArr={props.moveArr}
              changeGrid={props.changeGrid}
              renameCollection={props.renameCollection}
            />
          )}
        </Grid>
      </Grid>

      <AlertDialog
        open={alertOpen}
        handleClose={handleOpenAlert}
        handleConfirm={confirmDelete}
        msg="Er du sikker på at du vil slette?"
      />
    </Container>
  );
}

export default withRouter(CollectionsHolder);
