import React from "react";

// Material components
import { SvgIcon } from "@material-ui/core";

export default function NTIcon(props) {
  return (
    <SvgIcon>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ic/NT"
          transform="translate(-2.000000, -2.000000)"
          fill={props.fillcolor}
        >
          <g id="Group-4" transform="translate(2.000000, 2.000000)">
            <path
              d="M4.08792904,-3.55271368e-14 L14.0886789,10.0002143 L4.08792904,19.9998929 L-1.77635684e-15,15.9124995 L-1.77635684e-15,15.9114282 L5.91282084,10.0002143 L-1.77635684e-15,4.08846467 L-1.77635684e-15,4.08739341 L4.08792904,-3.55271368e-14 Z M2.30642328,5.63697133 L6.13457171,9.46458414 L11.4271329,9.46458414 L2.30642328,5.63697133 Z M0.757916613,15.9119638 L3.74405451,18.899173 L5.07777349,11.592107 L0.757916613,15.9119638 Z M15.912071,-6.62581101e-13 L20,4.08792904 L15.0143549,9.07357415 L10.9269615,4.98510948 L15.912071,-6.62581101e-13 Z M11.6843425,4.98510948 L14.5933496,7.89411664 L15.2318207,1.43816686 L11.6843425,4.98510948 Z M11.6076938,14.3333012 L15.0143013,10.9266937 L19.9999464,15.9118031 L15.9120174,19.9997322 L10.9269079,15.0151583 L11.6076938,14.3333012 Z M12.1058298,14.5930818 L18.5617796,15.2315529 L15.0143013,11.6846103 L12.1058298,14.5930818 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
