import React from "react";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import palette from "../theme/palette";
import { makeStyles } from "@material-ui/core/styles";

import update from "immutability-helper";

import TimeScheduleCondition from "./TimeScheduleCondition";

const useStyles = makeStyles(theme => ({
  win: {
    minWidth: "613px",
    maxWidth: "613px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      maxWidth: "100%"
    }
  },
  cellPadLeft: {
    paddingLeft: theme.spacing(1)
  },
  cellPadRight: {
    paddingRight: theme.spacing(1)
  },
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 8px"
  },
  clockSelect: {
    width: "92px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& .MuiFilledInput-inputSelect": {
      padding: "10px"
    }
  },
  titleArea: {
    backgroundColor: "rgba(216, 216, 216, 0.2)",
    "& .MuiTypography-h6": {
      fontSize: "2.2rem",
      fontWeight: 600
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "76px"
    }
  },
  hourDrop: {
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  button: {
    color: palette.primary.button,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0 0 0 !important"
    }
  },
  blueBtn: {
    color: palette.primary.button
  },
  dividerPad: {
    margin: theme.spacing(3) + "px 0px "
  },
  dataPicker: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    "& .MuiInputBase-adornedEnd": {
      padding: "8px 8px 0 8px"
    }
  },
  flexGrid: {
    display: "flex",
    flexDirection: "row"
  },
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    "& label + .MuiInput-formControl": {
      marginTop: "16px"
    },
    "& .MuiInput-root": {
      width: "100%"
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "transparent",
      padding: "12px"
    }
  },
  dialogWin: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "200 !important"
    },
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthXl": {
        [theme.breakpoints.down("sm")]: {
          margin: 0,
          width: "100%",
          padding: "0",
          height: "100%",
          maxHeight: "100%"
        }
      }
    }
  },
  dialogBtns: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "20px",
      flexDirection: "column"
    }
  }
}));
export default function TimeScheduleDialog(props) {
  const classes = useStyles();
  const optionsName = ["", "Tidsstyring", "Ukedag", "Pott"];
  let defaultCondition = [
    {
      conditionName: "Ny betingelse",
      option: 0,
      conditionParameter: "and",
      selectedDateFra: new Date(),
      selectedDateTil: new Date(),
      ukeDager: [],
      pott: 0,
      pottSize: 0
    }
  ];

  let defaultDialogTitle = "";
  let isEditing = false;

  if (props.placementSchedule) {
    isEditing = true;
    defaultCondition = props.placementSchedule.contentoptions;
    defaultDialogTitle = "Rediger innholdsstyring";
  } else {
    isEditing = false;
    defaultDialogTitle = "Lag ny innholdsstyring";
  }

  const [timeConditions, addtimeConditions] = React.useState(defaultCondition);
  const [dialogTitle, setDialogTitle] = React.useState(defaultDialogTitle);
  const [isValidated, setValidated] = React.useState(false);

  function handleDeleteCondition(event, itemindex) {
    const newContentArray = timeConditions.filter(function(condition, index) {
      return itemindex !== index;
    });
    const newState = update(timeConditions, { $set: newContentArray });
    addtimeConditions(newState);

    if (newState.length < 1) {
      setValidated(false);
    }
  }
  function handleAddCondition(event) {
    let item = {
      conditionName: "Ny betingelse",
      option: 0,
      conditionParameter: "and",
      selectedDateFra: new Date(),
      selectedDateTil: new Date(),
      ukeDager: [],
      pott: 0,
      pottSize: 0
    };

    let addCondition = timeConditions.concat(item);

    addtimeConditions(addCondition);
  }

  function handleChangePott(e, index) {
    const newState = update(timeConditions, {
      [index]: {
        pott: { $set: e.target.value }
      }
    });

    addtimeConditions(newState);
  }

  function handleChangePottSize(e, index) {
    const newState = update(timeConditions, {
      [index]: {
        pottSize: { $set: e.target.value }
      }
    });

    addtimeConditions(newState);
  }

  function handleChangeContent(e, index) {
    const newHeading = optionsName[e.target.value];

    const newState = update(timeConditions, {
      [index]: {
        conditionName: { $set: newHeading },
        option: { $set: e.target.value }
      }
    });

    addtimeConditions(newState);
  }

  const handleChangeWeek = (event, index) => {
    let ukeTime = [];

    event.target.value.forEach(function(item, key) {
      let day = {
        day: item,
        from: "00:00",
        to: "23:59"
      };
      //ukeTime = ukeTime.concat(day);
      let doesExist = timeConditions[index].ukeDager.findIndex(
        checkitem => checkitem.day === item
      );

      if (doesExist === -1) {
        ukeTime = ukeTime.concat(day);
      } else {
        ukeTime = [...ukeTime, timeConditions[index].ukeDager[doesExist]];
      }
    });
    ukeTime.sort((a, b) => (a.day > b.day ? 1 : -1));

    const newState = update(timeConditions, {
      [index]: {
        ukeDager: { $set: ukeTime }
      }
    });
    addtimeConditions(newState);
  };

  function handleChangeTimeFrom(e, updateditem, index) {
    let newValue = e.target.value;
    let newIndex;

    timeConditions[index].ukeDager.map(function(item, i) {
      if (item.day === updateditem.day) {
        newIndex = i;
      }
      return true;
    });

    const newState = update(timeConditions, {
      [index]: {
        ukeDager: {
          [newIndex]: { from: { $set: newValue } }
        }
      }
    });

    addtimeConditions(newState);
  }

  function handleChangeTimeTo(e, updateditem, index) {
    let newValue = e.target.value;
    let newIndex;

    timeConditions[index].ukeDager.map(function(item, i) {
      if (item.day === updateditem.day) {
        newIndex = i;
      }
      return true;
    });

    const newState = update(timeConditions, {
      [index]: {
        ukeDager: {
          [newIndex]: { to: { $set: newValue } }
        }
      }
    });

    addtimeConditions(newState);
  }

  const handleDateChangeFra = (e, index) => {
    let newDayTo = timeConditions[index].selectedDateTil;
    if (newDayTo < e) {
      newDayTo = e;
    }

    let newValue = e;
    const newState = update(timeConditions, {
      [index]: {
        selectedDateFra: { $set: newValue },
        selectedDateTil: { $set: newDayTo }
      }
    });

    addtimeConditions(newState);
  };

  const handleDateChangeTil = (e, index) => {
    let newValue = e;
    const newState = update(timeConditions, {
      [index]: {
        selectedDateTil: { $set: newValue }
      }
    });

    addtimeConditions(newState);
  };

  function handleChange(e, index) {
    let newValue = e.target.value;
    const newState = update(timeConditions, {
      [index]: {
        conditionParameter: { $set: newValue }
      }
    });

    addtimeConditions(newState);
  }

  function saveTimeSchedule() {
    props.saveTimeSchedule(timeConditions, isEditing);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        className={classes.dialogWin}
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleArea}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent className={classes.win}>
          <Grid container spacing={0}>
            {timeConditions.map((e, i) => (
              <TimeScheduleCondition
                key={i}
                index={i}
                values={timeConditions[i]}
                isMainCondition={i}
                handleChangeContent={handleChangeContent}
                handleChangeWeek={handleChangeWeek}
                handleChangeTimeFrom={handleChangeTimeFrom}
                handleChangeTimeTo={handleChangeTimeTo}
                handleDateChangeFra={handleDateChangeFra}
                handleDateChangeTil={handleDateChangeTil}
                handleChange={handleChange}
                handleChangePott={handleChangePott}
                handleChangePottSize={handleChangePottSize}
                deleteCondition={handleDeleteCondition}
                setValidated={setValidated}
              />
            ))}

            {timeConditions.length < 3 && (
              <Grid item xs={12}>
                <Button
                  onClick={handleAddCondition}
                  color="primary"
                  autoFocus
                  className={classes.blueBtn}
                >
                  + LEGG TIL BETINGELSE
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogBtns}>
          <Button
            onClick={props.handleClose}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Avbryt
          </Button>
          <Button
            onClick={saveTimeSchedule}
            color="primary"
            autoFocus
            variant="contained"
            className={classes.button}
            disabled={!isValidated}
          >
            LAGRE OG VELG INNHOLD
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
