import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AdminIcon from '@material-ui/icons/GroupOutlined';

import clsx from 'clsx';
import palette from '../../../theme/palette';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import AddPartnerDialog from '../../../components/AddPartnerDialog';

import AddUserDialog from './components/AddUserDialog';

import MenuItem from '@material-ui/core/MenuItem';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  mainheading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    alignItems: 'center',
    display: 'flex'
  },
  heading: {
    marginBottom: theme.spacing(3)
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  iconRight: {
    marginRight: '1.2rem'
  },
  paper: {
    width: '100%',
    height: '100%',
    padding: '2.5rem'
  },
  greenPaper: {
    backgroundColor: palette.secondary.light,
    boxShadow: 'none'
  },
  contactFormPaper: {
    maxWidth: '71.5rem'
  },
  bigAvatar: {
    marginRight: theme.spacing(3),
    width: 92,
    height: 92
  },
  flexDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  flexRight: {
    justifyContent: 'flex-end'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(2)
  }
}));

const partners = [
  {
    value: 'USD',
    label: 'Norsk tipping'
  },
  {
    value: 'EUR',
    label: 'Per Høj'
  }
];

const users = [
  {
    value: 'USD',
    label: 'Rune Sandnes'
  },
  {
    value: 'EUR',
    label: 'Almir Busevac'
  }
];

function AdminUsers(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    partner1: '',
    partner: '',
    newpartner: '',
    user: '',
    firstname: '',
    lastname: '',
    email: ''
  });

  const [addPartnerOpen, setAddPartnerOpen] = useState(false);

  function handleOpenPartner() {
    setAddPartnerOpen(!addPartnerOpen);
  }

  const [addUserOpen, setAddUserOpen] = useState(false);

  function handleOpenUser() {
    setAddUserOpen(!addUserOpen);
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />

      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={0}>
          <Grid container spacing={3} className={classes.centerVert}>
            <Grid item xs={12} align="left">
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.mainheading}
              >
                <AdminIcon className={classes.iconRight} /> Partner og
                brukeradministrering
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Paper className={clsx(classes.greenPaper, classes.paper)}>
                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Her kan du endre og legge til nye partnere og brukere.
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} align="left">
              <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.centerVert}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      align="left"
                      className={clsx(classes.flexDiv, classes.heading)}
                    >
                      Administrere partnere
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-partner"
                      select
                      label="Velg partner"
                      className={classes.textField}
                      value={values.partner}
                      onChange={handleChange('partner')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="filled"
                    >
                      {partners.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    align="left"
                    className={classes.flexDiv}
                  ></Grid>
                  <Grid
                    item
                    xs={8}
                    align="left"
                    className={classes.flexDiv}
                  ></Grid>
                  <Grid
                    item
                    xs={4}
                    className={clsx(classes.flexDiv, classes.flexRight)}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      SLETT
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenPartner}
              >
                <AddIcon /> LEGG TIL PARTNER
              </Button>{' '}
            </Grid>
            <Grid item xs={12} align="left">
              <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.centerVert}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      align="left"
                      className={clsx(classes.flexDiv, classes.heading)}
                    >
                      Administrer brukere
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-partner"
                      select
                      label="Velg partner"
                      className={classes.textField}
                      value={values.partner1}
                      onChange={handleChange('partner1')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="filled"
                    >
                      {partners.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-partner"
                      select
                      label="Velg bruker"
                      className={classes.textField}
                      value={values.user}
                      onChange={handleChange('user')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="filled"
                    >
                      {users.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDiv}></Grid>

                  <Grid item xs={4} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-fornavn"
                      label="Fornavn"
                      className={classes.textField}
                      value={values.firstname}
                      onChange={handleChange('firstname')}
                      margin="normal"
                      variant="filled"
                    ></TextField>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-etternavn"
                      label="Etternavn"
                      className={classes.textField}
                      value={values.lastname}
                      onChange={handleChange('lastname')}
                      margin="normal"
                      variant="filled"
                    ></TextField>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDiv}>
                    <TextField
                      id="filled-select-epost"
                      label="Epost"
                      className={classes.textField}
                      value={values.email}
                      onChange={handleChange('email')}
                      margin="normal"
                      variant="filled"
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    align="left"
                    className={classes.flexDiv}
                  ></Grid>
                  <Grid
                    item
                    xs={4}
                    className={clsx(classes.flexDiv, classes.flexRight)}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      SLETT
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      LAGRE
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenUser}
              >
                <AddIcon /> LEGG TIL BRUKER
              </Button>{' '}
            </Grid>
          </Grid>
        </Grid>
        <AddPartnerDialog
          open={addPartnerOpen}
          handleClose={handleOpenPartner}
          handleChange={handleChange}
          values={values}
        />
        <AddUserDialog
          open={addUserOpen}
          handleClose={handleOpenUser}
          handleChange={handleChange}
          values={values}
          users={users}
          partners={partners}
        />
      </Container>
    </main>
  );
}

export default withRouter(AdminUsers);
