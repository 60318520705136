/* eslint-disable no-script-url */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  msgPadding: {
    paddingLeft: "24px",
  },
  heading: {
    paddingBottom: "20px",
  },
  iconText: {
    display: "flex",
  },
  iconButton: {
    margin: "0 8px",
  },
  buttonMob: {
    marginRight: "10px",
  },
}));

export default function EmptyContent(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.msgPadding}>
        <Typography
          variant="body1"
          component="h3"
          align="left"
          className={classes.heading}
        >
          <span className={classes.iconText}>{props.txt}</span>{" "}
        </Typography>
      </Grid>
    </Grid>
  );
}
