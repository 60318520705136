import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Creatable from "react-select/creatable";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import { capitalize } from "../common/consts";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(255, 255, 255, 0.24)",
    "& label": {
      color: "#fff"
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none"
    },

    "& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:not(.Mui-disabled):before, & .MuiInput-underline:hover:after ": {
      borderBottom: "1px solid #D8D8D8 !important"
    }
  },

  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    height: "40px",
    "& input, & p": {
      color: "#fff !important"
    },
    "& .css-pgjkqr-Input": {
      color: "#fff",
      "& input": {
        padding: "10px 0 6px 10px !important"
      }
    }
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16,
    padding: "0 0 0 10px"
  },
  placeholder: {
    position: "absolute",
    left: 10,
    bottom: 6,
    fontSize: 16,
    color: "#fff"
  },
  paper: {
    position: "absolute",

    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    zIndex: 2000
  },
  divider: {
    height: theme.spacing(2)
  },
  whiteIcon: {
    color: "#fff",
    fill: "#fff",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "2.4rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: 0,
    userSelect: "none",
    top: "calc(50% - 12px - 8px)",
    right: "0",
    position: "absolute",
    pointerEvents: "none"
  }
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ])
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]).isRequired,
  selectProps: PropTypes.object.isRequired
};

function Option(props) {
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   key: PropTypes.string.isRequired,
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]),

  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any,
  selectProps: PropTypes.object.isRequired
};

function IndicatorsContainer2(props) {
  return (
    <div
      tabIndex="0"
      role="button"
      aria-haspopup="listbox"
      className={clsx(
        "MuiSelect-root",
        "MuiSelect-select",
        "MuiSelect-selectMenu",
        "MuiInputBase-input",
        "MuiFilledInput-input",
        "MuiInputBase-inputSelect",
        "MuiFilledInput-inputSelect",
        "MuiSelect-filled"
      )}
    >
      <svg
        className={props.selectProps.classes.whiteIcon}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        role="presentation"
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </div>
  );
}

IndicatorsContainer2.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

const DropdownIndicator = props => {
  return (
    <div>
      {props.children}
      <svg
        className={props.selectProps.classes.whiteIcon}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        role="presentation"
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </div>
  );
};

const components = {
  Control,
  Menu,
  DropdownIndicator,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export default function AutocompleteCreate(props) {
  const classes = useStyles();
  const theme = useTheme();
  const tags = props.items;
  const suggestions = tags.map(suggestion => ({
    value: suggestion,
    label: capitalize(suggestion)
  }));

  const defaultSingle = [{ label: props.value }].map(suggestion => ({
    value: suggestion.label,
    label: capitalize(suggestion.label)
  }));

  const [single, setSingle] = React.useState(defaultSingle);

  const handleChangeSingle = value => {
    setSingle(value);
    props.handleChangeTag(value.value);
  };

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
        margin: 0,

        "& .MuiInputLabel-root": {
          color: "#fff"
        },
        "& .MuiFilledInput-root": {
          color: "#fff"
        },
        "& .MuiFilledInput-root.Mui-disabled": {
          backgroundColor: "#222"
        },

        "& .MuiInputBase-input.Mui-disabled": {
          color: "#fff"
        },
        "& .MuiFilledInput-underline:before": {
          borderBottom: "1px solid #ccc"
        },
        "& .Mui-disabled.MuiFilledInput-underline:before": {
          borderBottom: "none"
        },
        "& .MuiSelect-root": {
          color: "#fff"
        },
        "& .MuiSelect-icon": {
          color: "#fff"
        }
      }
    })
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Creatable
          key={`my_unique_select_key__${single}`}
          value={single || ""}
          classes={classes}
          styles={selectStyles}
          inputId="react-select-single"
          TextFieldProps={{
            label: "Seksjon",
            InputLabelProps: {
              htmlFor: "react-select-single",
              shrink: true
            }
          }}
          formatCreateLabel={userInput => `Lag ny tag: ${userInput}`}
          options={suggestions}
          components={components}
          onChange={handleChangeSingle}
        />
      </NoSsr>
    </div>
  );
}
