// Material helpers
import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
const defaultTheme = createMuiTheme();
const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200
};

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  mixins: {
    gutters: (styles = {}) => ({
      paddingLeft: defaultTheme.spacing(3),
      paddingRight: defaultTheme.spacing(3),
      ...styles,
      [defaultTheme.breakpoints.up('sm')]: {
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
        ...styles[defaultTheme.breakpoints.up('sm')]
      }
    })
  },
  breakpoints: { values: breakpointValues }
});

export default theme;
