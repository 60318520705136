import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";
import palette from "../../theme/palette";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";
import StatsContext from "../../context/StatsContext";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  mainheading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    alignItems: "center",
    display: "flex"
  },
  heading: {
    marginBottom: theme.spacing(3)
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
      paddingTop: theme.spacing(0)
    }
  },
  iconRight: {
    marginRight: "1.2rem"
  },
  paper: {
    width: "100%",
    height: "100%",
    padding: "2.5rem"
  },
  greenPaper: {
    backgroundColor: palette.secondary.light,
    boxShadow: "none"
  },
  contactFormPaper: {
    maxWidth: "71.5rem"
  },
  bigAvatar: {
    marginRight: theme.spacing(3),
    width: 92,
    height: 92
  },
  flexDiv: {
    display: "flex",
    alignItems: "center"
  },
  flexCol: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    alignSelf: "flex-end"
  }
}));

const timeRange = [
  {
    value: "24",
    label: "Siste 24 timer"
  },
  {
    value: "12",
    label: "Siste 12 timer"
  },
  {
    value: "3",
    label: "Siste 3 timer"
  },
  {
    value: "1",
    label: "Siste time"
  }
];

function SettingsPage(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);
  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert!");
  const [snackVar, setSnackVar] = React.useState("success");

  const [values, setValues] = React.useState({
    name: "",
    timerange: statsRange.state
  });
  function handleClose(event) {
    setOpen(false);
  }

  const handleChange = name => event => {
    statsRange.updateValue(parseInt(event.target.value, 10));
    setValues({ ...values, [name]: event.target.value });
    setOpen(true);
  };
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />

      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={0}>
          <Grid container spacing={3} className={classes.centerVert}>
            <Grid item xs={12} align="left">
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.mainheading}
              >
                <InfoIcon className={classes.iconRight} /> Innstillinger
              </Typography>
            </Grid>
            {/*
            <Grid item xs={12} align="left">
              <Paper className={clsx(classes.greenPaper, classes.paper)}>
                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur lacinia felis metus. Nam dignissim arcu mauris,
                  facilisis luctus erat suscipit sit amet. Praesent nibh mauris,
                  laoreet eget auctor in, ultricies ut odio. Proin sed ex nec
                  libero vehicula ultricies eget ut neque. Curabitur sed
                  scelerisque est. Phasellus mattis nulla in dui suscipit, vel
                  ultricies ligula luctus.
                </Typography>
              </Paper>
            </Grid>
            */}

            <Grid item xs={12} align="left">
              <Paper className={clsx(classes.paper)}>
                <Grid container>
                  <Grid item xs={12} align="left">
                    <Typography
                      variant="h3"
                      component="h3"
                      align="left"
                      className={clsx(classes.flexDiv, classes.heading)}
                    >
                      Innstillinger statistikk
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} align="left">
                    <TextField
                      id="filled-select-timerange"
                      select
                      label="Visningsinnstillinger for statistikk"
                      className={classes.textField}
                      value={statsRange.state}
                      onChange={handleChange("timerange")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="filled"
                    >
                      {timeRange.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}

export default withRouter(SettingsPage);
