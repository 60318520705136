export default [
  {
    key: "1",
    caption: "Sosiale medier betalt",
    subMenuItems: [
      {
        key: "11",
        caption: "Facebook",
        medium: "paid_social",
        source: "Facebook"
      },
      {
        key: "12",
        caption: "Instagram",
        medium: "paid_social",
        source: "Instagram"
      },
      {
        key: "13",
        caption: "Instastory",
        medium: "paid_social",
        source: ""
      },
      {
        key: "14",
        caption: "Snapchat",
        medium: "paid_social",
        source: ""
      },
      {
        key: "15",
        caption: "Twitter",
        medium: "paid_social",
        source: ""
      }
    ]
  },

  {
    key: "more3",
    caption: "Sosiale medier organisk",
    subMenuItems: [
      {
        key: "21",
        caption: "Facebook",
        medium: "social",
        source: "Facebook"
      },
      {
        key: "22",
        caption: "Instagram",
        medium: "social",
        source: "Instagram"
      },
      {
        key: "23",
        caption: "Instastory",
        medium: "social",
        source: ""
      },
      {
        key: "24",
        caption: "Snapchat",
        medium: "social",
        source: ""
      },
      {
        key: "25",
        caption: "Twitter",
        medium: "social",
        source: ""
      }
    ]
  },

  {
    key: "3",
    caption: "Nettavis ",
    subMenuItems: [
      {
        key: "31",
        caption: "Nettaviser markedskomm desktop",
        medium: "display",
        source: "PerHoj"
      },
      {
        key: "32",
        caption: "Nettavis markedskomm mobil/tablet",
        medium: "mobil",
        source: "PerHoj"
      },
      {
        key: "33",
        caption: "Nettavis eKomm",
        medium: "ekomm",
        source: "PerHoj"
      }
    ]
  },
  {
    key: "4",
    caption: "SMS",
    medium: "sms",
    source: "sms"
  },
  {
    key: "5",
    caption: "Nyhetsbrev",
    medium: "email",
    source: "Nyhetsbrev"
  },
  {
    key: "6",
    caption: "Norsk-tipping.no",
    medium: "Norsk-tipping.no",
    source: "Norsk-tipping.no"
  },
  {
    key: "7",
    caption: "Annet (skriv her)",
    medium: "",
    source: ""
  }
];
