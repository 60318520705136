/* eslint-disable no-script-url */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  msgPadding: {
    paddingTop: "60px"
  },
  heading: {
    paddingBottom: "20px"
  },
  iconText: {
    display: "flex"
  }
}));

export default function EmptyLinks(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.msgPadding}>
        <Typography
          variant="h3"
          component="h3"
          align="left"
          className={classes.heading}
        >
          Her er det tomt
        </Typography>

        <Typography
          variant="body1"
          component="h3"
          align="left"
          className={classes.heading}
        >
          <span className={classes.iconText}>
            Klikk på <AddIcon className={classes.iconButton} /> Ny plassering
            for å komme i gang.
          </span>{" "}
        </Typography>
      </Grid>
    </Grid>
  );
}
