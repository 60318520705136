/* eslint-disable no-script-url */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import palette from "../../../theme/palette";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Link";
import FileIcon from "@material-ui/icons/FileCopy";
import ContentIcon from "@material-ui/icons/TextFields";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Menu from "@material-ui/core/Menu";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";

import CopyToClipboard from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper";
import Visibility from "@material-ui/icons/Visibility";

import MoreVert from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import ExpandMore from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
  },
  cardList: {
    borderRadius: 0,
    backgroundColor: palette.common.white,
    "&:last-of-type": {
      borderBottomLeftRadius: "3px",
      borderBottomRightRadius: "3px",
    },
    "&:first-of-type": {
      borderTopLeftRadius: "3px",
      borderTopRightRadius: "3px",
    },
    padding: "0 24px",
    marginTop: "1px",
    "&:hover": {
      backgroundColor: palette.greys.lighter,
    },
  },
  cardListOpen: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "white",
    },
  },
  cardListActions: {
    cursor: "pointer",
  },
  depositContext: {
    margin: "3rem 0",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    pointerEvents: "none",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginRight: theme.spacing(2),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardListIcon: {
    marginRight: "16px",
    color: palette.text.secondary,
    width: 22,
    height: 22,
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 16,
    height: 16,
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  ellipsis: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
  fullwidth: {
    width: "100%",
  },
  mrgRight: {
    marginRight: theme.spacing(2),
  },
  mrgTop: {
    marginTop: theme.spacing(1),
  },
  centAlg: {
    alignSelf: "center",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  wrapURL: {
    wordBreak: "break-all",
  },
  mobHidden: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobPadding: {
    padding: "0 !important",
  },
  mobPaddinLeft: {
    paddingLeft: "0px !important",
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      padding: "3px 0",
    },
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary,
      },
    },
  },
}))(MenuItem);

function ElementItemLenke(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function setCopy() {
    setOpen(true);
  }

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function stopPropag(event) {
    event.stopPropagation();
    return;
  }

  function countDecimals(num) {
    if (!num) return 0;
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1].length || 0;
  }

  function formatStats(nr) {
    if (nr === undefined) return;
    let num = parseFloat(nr);

    if (num > 0 && num < 1) {
      return num.toFixed(3);
    } else if (num > 999) {
      return Math.round(num / 1000) + "K";
    } else {
      if (countDecimals(num) > 0) {
        return num.toFixed(2);
      } else {
        return num;
      }
    }
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleCloseSnack(event) {
    setOpen(false);
  }
  function handleCloseDelete(event) {
    event.stopPropagation();
    console.log(props.id);
    props.handleDelete(props.id);
    setAnchorEl(null);
  }

  function doOpenLink(event) {
    event.stopPropagation();
    handleClose();

    props.setValues({
      ...props.values,
      name: props.name,
      title: props.title,
      url: props.url,
      id: props.id,
      placement: props.placement,
      placement_id: props.placement_id,
      description: props.description,
      placement_type: props.description,
      placement_source: props.placement_source,
      placement_medium: props.placement_source,
      placement_code: props.placement_code,
      placement_campaign: props.placement_source,
      extras: props.extras,
      campaign: props.campaign,
      isValidated: 3,
      finalURL: props.finalURL,
    });

    props.handleOpenDialog();
  }

  const iconType = props.type;
  const [isHover, setIsHover] = useState(false);
  const [expanded, setExpanded] = useState(false);

  function renderArrow(trend) {
    if (trend === 1) {
      return (
        <ArrowUpward
          className={clsx(classes.upStats, classes.cardListIconSmall)}
        />
      );
    } else if (trend === -1) {
      return (
        <ArrowDownward
          className={clsx(classes.downStats, classes.cardListIconSmall)}
        />
      );
    } else {
      return (
        <span className={clsx(classes.downStats, classes.cardListIconSmall)}>
          {}
        </span>
      );
    }
  }

  function handleExpandClick() {
    props.history.push("/collections/" + props.id);
    //setExpanded(!expanded);
  }

  function handleHoverIn() {
    setIsHover(true);
  }
  function handleHoverOut() {
    setIsHover(false);
  }
  const handleTitleChange = (itemid) => (event) => {
    props.updateItemTitle(itemid, event.target.value);
  };

  function showContent(content) {
    if (props.type == 2) return;

    let icon = (
      <IconButton aria-label="Share" className={classes.button}>
        <ContentIcon className={classes.cardListIcon} />
        <Typography variant="subtitle2">{content}:</Typography>
      </IconButton>
    );
    return icon;
  }
  function showCollapsingIcon(icon) {
    if (isHover === false) return;
    let iconGrid = (
      <IconButton
        className={clsx(classes.button, classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        aria-expanded={expanded}
        aria-label="Show more"
      >
        <ExpandMore className={classes.listIcon} />
      </IconButton>
    );

    return iconGrid;
  }

  return (
    <Card
      className={clsx(classes.cardList, {
        [classes.cardListOpen]: expanded,
      })}
      square
    >
      <CardActions
        disableSpacing
        className={classes.cardListActions}
        onMouseOver={handleHoverIn}
        onMouseOut={handleHoverOut}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            onClick={handleExpandClick}
            className={classes.mobPadding}
          >
            <IconButton
              aria-label="Share"
              className={clsx(classes.fullwidth, classes.button)}
            >
              <FolderOutlinedIcon className={classes.cardListIcon} />
              <Typography variant="subtitle2" className={classes.ellipsis}>
                {props.title}
              </Typography>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={1}
            sm={3}
            onClick={handleExpandClick}
            className={classes.mobHidden}
          >
            <IconButton aria-label="Share" className={classes.button}>
              <ContentIcon className={classes.cardListIcon} />
              <Typography variant="subtitle2">{props.numOfWidgets}</Typography>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            onClick={handleExpandClick}
            className={clsx(classes.mobPadding, classes.mobPaddinLeft)}
          >
            <Grid container spacing={0} className={classes.container}>
              <Grid item xs={4} className={classes.iconGridClass} zeroMinWidth>
                {props.ctr !== undefined && (
                  <IconButton
                    aria-label="Share"
                    className={clsx(classes.button, classes.textEllipse)}
                  >
                    <Hidden only={["sm", "md", "lg"]}>
                      <Typography variant="caption" noWrap>
                        CTR:
                      </Typography>
                    </Hidden>
                    {renderArrow(props.ctr_trend)}

                    <Typography variant="subtitle2" className={classes.alRight}>
                      {formatStats(props.ctr)}
                    </Typography>
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={4}
                className={clsx(classes.iconGridClass, classes.tblPad)}
              >
                <IconButton
                  aria-label="Share"
                  className={clsx(classes.button, classes.textEllipse)}
                >
                  <Hidden only={["sm", "md", "lg"]}>
                    <Typography variant="caption" noWrap>
                      Klikk:
                    </Typography>
                  </Hidden>
                  {renderArrow(props.clicks_trend)}

                  <Typography variant="subtitle2" className={classes.alRight}>
                    {formatStats(props.clicks)}
                  </Typography>
                </IconButton>
              </Grid>

              <Grid item xs={4} className={classes.iconGridClass}>
                {props.ctr !== undefined && (
                  <IconButton
                    aria-label="Share"
                    className={clsx(classes.button, classes.textEllipse)}
                  >
                    <Hidden only={["sm", "md", "lg"]}>
                      <Typography variant="caption" noWrap>
                        Vist:
                      </Typography>
                    </Hidden>
                    {renderArrow(props.displays_trend)}

                    <Typography variant="subtitle2" className={classes.alRight}>
                      {formatStats(props.displays)}
                    </Typography>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={1}
            align="right"
            onClick={handleExpandClick}
            className={classes.mobPadding}
          >
            <Grid container spacing={0} align="right">
              <Grid item xs={12} className={classes.mobHidden}>
                <IconButton onClick={handleClick}>
                  <MoreVert className={classes.listIcon} />
                </IconButton>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem onClick={handleCloseDelete}>
                    <ListItemText primary="Slett" />
                  </StyledMenuItem>
                </StyledMenu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
      >
        <MySnackbarContentWrapper
          onClose={handleCloseSnack}
          variant="success"
          message="Lenken er kopiert!"
        />
      </Snackbar>
    </Card>
  );
}
export default withRouter(ElementItemLenke);
