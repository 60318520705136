import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import EmptyLinks from "./EmptyLinks";
import AddIcon from "@material-ui/icons/Add";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import LinksDialog from "./LinksDialog";
import AlertDialog from "../../../components/AlertDialog";
import update from "immutability-helper";

import TableLinks from "./TableLinks";
import palette from "../../../theme/palette";
import { withRouter } from "react-router-dom";
import StatsContext from "../../../context/StatsContext";
import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    marginTop: theme.spacing(2)
  },
  buttonMob: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "7px 10px"
    }
  },
  fixedHeight: {
    height: 240
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1)
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  chip: {
    margin: theme.spacing(1)
  },
  placementlist: {
    marginBottom: "32px"
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary
  },
  buttonMenuLeft: {
    marginRight: "10px"
  },
  outerPadding: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    }
  },
  centerVert: {
    alignItems: "center"
  },
  buttonTopMargin: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "20px"
    }
  },
  buttonBottomMargin: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "20px"
    }
  }
}));

function getSteps() {
  return ["Velg landingsside", "Sporingsvalg", "Vis lenke"];
}

function LinksListHolder(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);
  const isMobile = useMediaQuery("(max-width:767px)");

  const [open, setOpenDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [addRow, setAddRow] = useState(false);
  const [updateRow, setUpdateRow] = useState(false);

  const [deleteURL, setDeleteURL] = useState("");

  useEffect(() => {
    const saveLink = async () => {
      const result = await axios.post(
        "https://nt-ekom-2.herokuapp.com/link/" +
          "?session_key=" +
          statsRange.sid,
        {
          ...addRow
        }
      );

      let results = result.data;
      let finalURL = "";

      if (addRow.url.indexOf("&utm_term=ekoml") === -1) {
        finalURL = addRow.url + "&utm_term=ekoml_" + results.link_id;
      } else {
        finalURL = addRow.url;
      }
      var newrow = { ...addRow, id: results.link_id, url: finalURL };
      props.setValues({
        ...props.values,
        id: results.link_id,
        finalURL: finalURL
      });

      let updatedPositionKey;
      let updatedPosition = props.items.filter(function(item, key) {
        if (item.id === results.link_id) {
          updatedPositionKey = key;
          return item;
        }
      });

      const updatedItem = update(props.items, {
        [updatedPositionKey]: { $set: newrow }
      });
      if (updatedPositionKey) {
        props.setLinksData(updatedItem);
      } else {
        props.setLinksData([...props.items, newrow]);
      }
    };
    if (addRow) {
      saveLink();
      setAddRow(false);
    }
  }, [addRow]);

  useEffect(() => {
    const saveLink = async id => {
      const result = await axios.put(
        "https://nt-ekom-2.herokuapp.com/link/" +
          id +
          "?session_key=" +
          statsRange.sid,
        {
          ...updateRow
        }
      );

      let results = result.data;
    };
    if (updateRow) {
      saveLink(updateRow.id);
      setAddRow(false);
    }
  }, [updateRow, statsRange.sid]);

  function handleOpenDialog() {
    setOpenDialog(!open);
  }

  function handleOpenAlert() {
    setAlertOpen(!alertOpen);
  }

  function handleCloseDialog() {
    setOpenDialog(!open);
    handleReset();
  }

  function saveNewRow(title, url) {
    console.log("SAVING2", title, url);

    addNewRow(title, url);
    // setOpenDialog(!open);
    // handleReset();
  }

  function closeWin() {
    setOpenDialog(!open);
    handleReset();
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [displayCategory, setCategory] = useState("Alle");

  function handleNext() {
    if (steps.length - 1 === activeStep) {
      closeWin();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  function addNewRow(title, url) {
    if (!url) return;
    const newTitle = title === "" ? "Ny lenke" : title;
    const id = props.values.id ? props.values.id : null;
    var statsObj = {
      3: {
        clicks: 0,
        clicks_trend: 0
      },
      6: {
        clicks: 0,
        clicks_trend: 0
      },
      12: {
        clicks: 0,
        clicks_trend: 0
      },
      24: {
        clicks: 0,
        clicks_trend: 0
      }
    };

    var newrow = {
      campaign: props.values.campaign,
      placement_type: props.values.placement_type,
      extras: props.values.extras,
      name: props.values.name,
      description: props.values.placement_type,
      placement: props.values.placement,
      placement_code: props.values.placement_code,
      placement_source: props.values.placement_source,
      placement_medium: props.values.placement_medium,
      placement_campaign: props.values.placement_campaign,
      title: newTitle,
      url: url,
      id: id,
      stats: statsObj
    };

    setAddRow(newrow);

    props.showAlert("Lagret!", "success");
  }

  function doDelete(itemId) {
    setDeleteURL(
      "https://nt-ekom-2.herokuapp.com/link/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
    props.showAlert("Slettet!", "success");
  }

  React.useEffect(() => {
    const deleteData = async () => {
      const result = await axios.delete(deleteURL);
    };
    if (deleteURL != "") {
      deleteData();
    }
  }, [deleteURL]);

  function handleUpdateTitle(id, newTitle) {
    var updatedrow = {
      title: newTitle,
      id: id
    };
    props.showAlert("Oppdatert!", "success");
    setUpdateRow(updatedrow);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleDelete(itemId) {
    props.setValues({
      ...props.values,
      itemId: itemId
    });

    handleOpenAlert();
  }

  function confirmDelete() {
    const items = props.items.filter(item => item.id !== props.values.itemId);
    doDelete(props.values.itemId);

    props.setLinksData(items);
    handleOpenAlert();
  }

  function updateItemTitle(itemId, title) {
    const items = props.items.map((item, key) => {
      if (item.id === itemId) {
        item.title = title;
      }
      return item;
    });

    props.setLinksData(items);
  }
  function handleReset() {
    setActiveStep(0);
    props.setValues({
      name: "",
      title: "",
      id: "",
      url: "",
      description: "",
      placement: "",
      placement_id: "",
      placement_type: "",
      placement_source: "",
      placement_medium: "",
      placement_code: "",
      placement_campaign: "",
      product: "",
      extras: "",
      campaign: "",
      isValidated: 0,
      finalURL: "",
      errors: []
    });
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={0}>
        <Grid
          container
          spacing={3}
          className={clsx(classes.centerVert, classes.outerPadding)}
        >
          {props.items.length > 0 && !isMobile && (
            <Grid item xs={12} align="left">
              <Typography
                variant="h1"
                component="h1"
                align="left"
                className={classes.heading}
              >
                Lenker
              </Typography>
            </Grid>
          )}
          {/*<Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <MenuSort />
            <IconButton>
              <MoreVert className={classes.bigIcon} />
            </IconButton>
          </Grid>
          */}
        </Grid>
        {props.items.length < 1 && <EmptyLinks />}
        <Grid item xs={12} align="center" className={classes.buttonBottomMargin}>
          <Button
            variant="outlined"
            onClick={handleOpenDialog}
            color="primary"
            className={classes.buttonMob}
          >
            <AddIcon /> NY LENKE
          </Button>{" "}
        </Grid>
        <Grid item xs={12} className={classes.placementlist}>
          <TableLinks
            selectedCat={displayCategory}
            rows={props.items}
            handleDelete={handleDelete}
            handleOpenDialog={handleOpenDialog}
            setValues={props.setValues}
            values={props.values}
            setLinksData={props.setLinksData}
            updateItemTitle={updateItemTitle}
            handleUpdateTitle={handleUpdateTitle}
          />
        </Grid>

      </Grid>
      <LinksDialog
        isOpen={open}
        handleClose={handleCloseDialog}
        handleNext={handleNext}
        handleBack={handleBack}
        handleReset={handleReset}
        saveNewRow={saveNewRow}
        activeStep={activeStep}
        steps={steps}
        values={props.values}
        setValues={props.setValues}
      />
      <AlertDialog
        open={alertOpen}
        handleClose={handleOpenAlert}
        handleConfirm={confirmDelete}
        msg="Er du sikker på at du vil slette?"
      />
    </Container>
  );
}

export default withRouter(LinksListHolder);
