import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";
import MenuSort from "../../components/MenuSort";
import AlertDialog from "../../components/AlertDialog";

import { Link } from "react-router-dom";
import EmptyPlacements from "./components/EmptyPlacements";
import TablePlacements from "./components/TablePlacements";
import StatsContext from "../../context/StatsContext";
import FormSelectMobile from "../../components/FormSelectMobile";
import palette from "../../theme/palette";
import { withRouter } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    marginTop: theme.spacing(2)
  },
  buttonMob: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "7px 10px"
    }
  },
  fixedHeight: {
    height: 240
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1)
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  chip: {
    textTransform: "capitalize",
    margin: theme.spacing(1)
  },
  placementlist: {
    margin: "32px 0"
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary
  },
  outerPadding: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    }
  },
  rowMargin: {
    marginTop: "32px"
  },
  buttonTopMargin: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "20px"
    }
  },
  iconButton: {
    color: "rgba(0,0,0,0.54)"
  },
  menuSort: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px"
    }
  }
}));

const styles = {
  iconStyles: {
    verticalAlign: "middle"
  },
  smallIcon: {
    verticalAlign: "middle",
    width: 16,
    height: 16
  }
};

function Placements(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;
  const [page, setPage] = useState(0);
  const [deleteItemId, setDeleteItemId] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const theme = {
    spacing: 8
  };

  const [placementItems, updatePlacementItems] = useState(props.items);

  const [displayCategory, setCategory] = useState("Alle");
  const [sort, setSort] = useState(null);

  let i = 0;

  var occurences = props.items.reduce(function(r, row) {
    r[row.tag] = ++r[row.tag] || 1;
    return r;
  }, {});

  var result = Object.keys(occurences).map(function(key) {
    return { key: key, value: occurences[key] };
  });

  const filterItems = [
    {
      id: 0,
      category: "Alle",
      nr: props.items.length
    }
  ];

  for (const item of result) {
    i = i + 1;

    filterItems.push({
      id: i,
      category: item.key,
      nr: item.value
    });
  }
  const [open, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");

  const [alertOpen, setAlertOpen] = useState(false);

  function handleOpenAlert() {
    setAlertOpen(!alertOpen);
  }
  function handleOpenDialog() {
    setOpenDialog(!open);
  }
  function handleDelete(itemId) {
    setDeleteItemId(itemId);
    handleOpenAlert();
  }

  function confirmDelete() {
    const items = placementItems.filter(item => item.id !== deleteItemId);

    updatePlacementItems(items);

    props.doDelete(deleteItemId);

    handleOpenAlert();
  }

  function sortPlacementBy(itemKey, order) {
    const newOrder = placementItems.sort(function(a, b) {
      var keyA = a[itemKey],
        keyB = b[itemKey];
      // Compare the 2 dates
      if (keyA < keyB) return -1 * order;
      if (keyA > keyB) return 1 * order;
      return 0;
    });

    updatePlacementItems(newOrder);
    setSort(itemKey);
  }

  function sortPlacementByContent(order) {
    if (!order) order = 1;
    const newOrder = placementItems.sort(function(a, b) {
      var keyA = a.content[0].name,
        keyB = b.content[0].name;
      // Compare the 2 dates
      if (keyA < keyB) return -1 * order;
      if (keyA > keyB) return 1 * order;
      return 0;
    });

    updatePlacementItems(newOrder);
    setSort("content");
  }
  function sortPlacementByStats(itemKey) {
    const order = 1;
    const newOrder = placementItems.sort(function(a, b) {
      var keyA = a.stats[statsInterval]
          ? a.stats[statsInterval][itemKey]
          : -100,
        keyB = b.stats[statsInterval] ? b.stats[statsInterval][itemKey] : -100;
      // Compare the 2 dates
      if (keyA > keyB) return -1 * order;
      if (keyA < keyB) return 1 * order;
      return 0;
    });

    updatePlacementItems(newOrder);
    setSort(itemKey);
  }

  const filterCategory = cat => {
    setCategory(cat);
    updateFilters(props.items, cat);
    props.history.push("/placements/" + cat);
  };

  const updateFilters = (items, cat) =>
    updatePlacementItems(
      items.filter(item => cat === item.tag || cat === "Alle")
    );

  const filterCategories = (categories, setCategory) =>
    categories.map(cat => (
      <Chip
        key={cat.id}
        label={cat.category + " " + cat.nr}
        className={classes.chip}
        color={displayCategory === cat.category ? "primary" : "secondary"}
        onClick={() => filterCategory(cat.category)}
      />
    ));

  const filterCategoriesMobile = (categories, setCategory) => (
    <FormSelectMobile
      onChange={handleMobileFilterChange}
      items={categories}
      label={"Vis plasseringer"}
      selectedValue={displayCategory}
      isDisabled={false}
      isStart={true}
    />
  );

  const handleMobileFilterChange = event => {
    filterCategory(event.currentTarget.value);
    console.log(event.currentTarget.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={0}>
        <Grid item xs={12} align="center" className={classes.outerPadding}>
          {props.items.length < 1 && <EmptyPlacements />}
          {props.items.length > 0 && (
            <Grid container>
              {/* Favorite placements */}
              <Grid item xs={10} align="center">
                {!isMobile && filterCategories(filterItems, filterCategory)}
                {isMobile &&
                  filterCategoriesMobile(filterItems, filterCategory)}
              </Grid>

              <Grid
                item
                xs={2}
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <MenuSort
                  sortPlassering={sortPlacementBy}
                  sortContent={sortPlacementByContent}
                  sortStats={sortPlacementByStats}
                  className={classes.menuSort}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {props.items.length > 0 && (
          <Grid item xs={12} className={classes.placementlist}>
            <TablePlacements
              selectedCat={displayCategory}
              rows={placementItems}
              sort={sort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              page={page}
              handleDelete={handleDelete}
              handleOpenDialog={handleOpenDialog}
              addToFavorites={props.addToFavorites}
            />
          </Grid>
        )}
        <Grid item xs={12} align="center" className={classes.buttonTopMargin}>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={{ pathname: "/newplacement" }}
            className={classes.buttonMob}
          >
            <AddIcon className={classes.iconButton} /> Ny plassering
          </Button>{" "}
        </Grid>
      </Grid>
      <AlertDialog
        open={alertOpen}
        handleClose={handleOpenAlert}
        handleConfirm={confirmDelete}
        msg={"Er du sikkert at du vil slette?"}
      />
    </Container>
  );
}

export default withRouter(Placements);
