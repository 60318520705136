import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MySnackbarContentWrapper from '../MySnackbarContentWrapper';

const useStyles2 = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

export default function CustomizedSnackbars() {
  const classes = useStyles2();
  const [open, setOpen] = React.useState(false);
  const [variant, setVariant] = React.useState('success');
  const [message, setMessage] = React.useState('');

  function handleClick(variant, message) {
    setMessage(message);
    setVariant(variant);
    setOpen(true);
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  return (
    <div>
      <Typography variant="h1" component="h2" gutterBottom>
        Snackbars
      </Typography>
      <Button
        variant="outlined"
        className={classes.margin}
        onClick={handleClick.bind(this, 'success', 'Lenken er kopiert!')}
      >
        Open success snackbar
      </Button>
      <Button
        variant="outlined"
        className={classes.margin}
        onClick={handleClick.bind(this, 'error', 'Det har skjed noe.')}
      >
        Open error snackbar
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    </div>
  );
}
