/* eslint-disable no-script-url */

import React, { Fragment } from "react";

import ElementItemCollectionsDetails from "./ElementItemCollectionsDetails";
import StatsContext from "../../../context/StatsContext";

export default function TableTippetipsDetails(props) {
  const rows = props.rows;
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;

  return (
    <Fragment>
      {rows.map((row, index) => {
        const statObj = row.stats ? row.stats[statsInterval] : null;

        const sClicks = statObj ? statObj.clicks : 0;
        const sClicksTrend = statObj ? statObj.clicks_trend : "";

        const displays = statObj ? statObj.views : 0;
        const displays_trend = statObj ? statObj.views_trend : "";
        const ctr = statObj ? statObj.ctr : 0;
        const ctr_trend = statObj ? statObj.ctr_trend : "";

        return (
          <ElementItemCollectionsDetails
            key={index}
            id={row.contentid}
            index={index}
            clicks={sClicks}
            clicks_trend={sClicksTrend}
            displays={displays}
            displays_trend={displays_trend}
            ctr={ctr}
            ctr_trend={ctr_trend}
            url={row.url}
            total={props.rows.length}
            moveArr={props.moveArr}
            title={row.name}
            placement={row.placement}
            name={row.name}
            description={row.description}
            campaign={row.campaign}
            extras={row.extras}
            content={row}
            removeRow={props.removeRow}
            handleDelete={props.handleDelete}
            handleOpenDialog={props.handleOpenDialog}
            setValues={props.setValues}
            values={props.values}
            setLinkItem={props.setCollectionData}
            updateItemTitle={props.updateItemTitle}
            handleUpdateTitle={props.handleUpdateTitle}
            updatePlacementContent={props.updatePlacementContent}
            isBlocked={props.isBlocked}
          />
        );
      })}
    </Fragment>
  );
}
