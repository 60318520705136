// Palette
import palette from '../palette';

export default {
  root: {
    fontSize: '1.4rem',
    color: palette.common.black,
    backgroundColor: 'white'
  }
};
