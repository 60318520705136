// Palette
import palette from '../palette';

export default {
  root: {
    padding: '10px 16px'
  },
  colorPrimary: {
    color: palette.greys.dark,
    '&.Mui-checked': {
      color: palette.primary.dark
    },
    '&.Mui-checked:hover': {
      backgroundColor: palette.primary.dark
    }
  },
  colorSecondary: {
    color: palette.greys.dark,
    '&.Mui-checked': {
      color: palette.primary.dark
    },
    '&.Mui-checked:hover': {
      backgroundColor: palette.primary.dark
    }
  }
};
