export default [
  {
    key: "1",
    caption: "Forsiden",
    url: "",
    code: "forsiden"
  },
  {
    key: "2",
    caption: "Lotteri",
    subMenuItems: [
      {
        key: "21",
        caption: "Lobby",
        url: "lotteri",
        code: "lotteri"
      },
      {
        key: "22",
        caption: "Lotto",
        url: "lotteri/lotto",
        code: "lotto"
      },
      {
        key: "23",
        caption: "Vikinglotto",
        url: "lotteri/vikinglotto",
        code: "lotteri/vikinglotto"
      },
      {
        key: "24",
        caption: "Eurojackpot",
        url: "lotteri/eurojackpot",
        code: "eurojackpot"
      },
      {
        key: "25",
        caption: "Extra",
        url: "lotteri/extra",
        code: "extra"
      },
      {
        key: "26",
        caption: "Joker",
        url: "lotteri/joker",
        code: "joker"
      },
      {
        key: "27",
        caption: "Keno",
        url: "lotteri/keno",
        code: "keno"
      },
      {
        key: "28",
        caption: "Nabolaget",
        url: "lotteri/nabolaget",
        code: "nabolaget"
      },
      {
        key: "29",
        caption: "Andelsbank",
        url: "lotteri/andelsbank",
        code: "andelsbank"
      }
    ]
  },

  {
    key: "more3",
    caption: "Sport",
    subMenuItems: [
      {
        key: "31",
        caption: "Lobby",
        url: "sport",
        code: "sport"
      },
      {
        key: "32",
        caption: "Langoddsen",
        subMenuItems: [
          {
            key: "321",
            caption: "Hele oddsprogrammet",
            url: "sport/oddsen",
            code: "langoddsen"
          },
          {
            key: "322",
            caption: "Velg kamp",
            url: "",
            code: ""
          }
        ]
      },
      {
        key: "33",
        caption: "Liveoddsen",
        url: "sport/liveoddsen",
        code: "liveoddsen"
      },
      {
        key: "34",
        caption: "Tipping",
        url: "sport/tipping",
        code: "tipping"
      },
      {
        key: "35",
        caption: "Oddsbomben",
        url: "sport/oddsbomben",
        code: "oddsbomben"
      },
      {
        key: "36",
        caption: "Vinneroddsen",
        url: "sport/vinneroddsen",
        code: "vinneroddsen"
      }
    ]
  },
  {
    key: "5",
    caption: "Kongkasino",
    url: "kongkasino",
    code: "kongkasino"
  },
  {
    key: "6",
    caption: "Flax",
    url: "flax",
    code: "flax"
  },
  {
    key: "76",
    caption: "Bingoria",
    url: "bingoria",
    code: "bingoria"
  },
  {
    key: "8",
    caption: "Kom i gang",
    url: "kom-i-gang",
    code: "kom-i-gang"
  },
  {
    key: "9",
    caption: "Mormerke",
    subMenuItems: [
      {
        key: "91",
        caption: "Grasrotandelen",
        url: "grasrotandelen",
        code: "grasrotandelen"
      },
      {
        key: "92",
        caption: "Drømmetanken",
        url: "drommetanken",
        code: "drommetanken"
      },
      {
        key: "93",
        caption: "Vinnerøyeblikk",
        url: "http://www.vinneroyeblikk.no/",
        code: "vinneroyeblikk"
      },
      {
        key: "94",
        caption: "Stilling ledig",
        url:
          "https://candidate.hr-manager.net/Vacancies/List.aspx?customer=norsktipping",
        code: "stilling-ledig"
      },
      {
        key: "95",
        caption: "Profil",
        url: "/",
        code: "profil"
      },
      {
        key: "96",
        caption: "Drømmestipend",
        url: "https://www.drommestipendet.no/",
        code: "drommestipendet"
      },
      {
        key: "97",
        caption: "Anleggsåpninger",
        url: "/",
        code: "anleggsapninger"
      }
    ]
  },
  {
    key: "100",
    caption: "Annet",
    url: "",
    code: "annet"
  }
];
