/* eslint-disable no-script-url */

import React, { Fragment } from "react";

import ElementItemLenke from "../../../components/ElementItemLenke";
import StatsContext from "../../../context/StatsContext";

export default function TableLinks(props) {
  const rows = props.rows;
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;

  return (
    <Fragment>
      {rows.map((row, index) => {
        const statObj = row.stats[statsInterval];

        const sClicks = statObj ? statObj.clicks : 0;
        const sClicksTrend = statObj ? statObj.clicks_trend : "";
        const finalURL =
          row.url.indexOf("&utm_term=ekoml") !== -1
            ? row.url
            : row.url + "&utm_term=ekoml_" + row.id;
        return (
          <ElementItemLenke
            key={index}
            id={row.id}
            clicks={sClicks}
            clicks_trend={sClicksTrend}
            url={finalURL}
            title={row.title}
            placement={row.placement}
            name={row.name}
            description={row.description}
            campaign={row.campaign}
            extras={row.extras}
            placement_id={row.placement_id}
            placement_type={row.placement_type}
            placement_source={row.placement_source}
            placement_medium={row.placement_medium}
            placement_code={row.placement_code}
            placement_campaign={row.placement_campaign}
            finalURL={row.finalURL}
            handleDelete={props.handleDelete}
            handleOpenDialog={props.handleOpenDialog}
            setValues={props.setValues}
            values={props.values}
            setLinkItem={props.setLinksData}
            updateItemTitle={props.updateItemTitle}
            handleUpdateTitle={props.handleUpdateTitle}
          />
        );
      })}
    </Fragment>
  );
}
