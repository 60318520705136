/* eslint-disable no-script-url */

import React from "react";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckMark from "../images/mark.svg";

import FormControl from "@material-ui/core/FormControl";

import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "3px",
    minWidth: "176px",
  },
  input: {
    display: "none",
  },

  checkboxBudget: {
    position: "absolute",
    left: "-9999px",
    width: "0",
    height: "0",
    visibility: "hidden",
    "&:checked + label,&:not(:checked) + label": {
      position: "relative",
      display: "inline-block",
      padding: "8px 14px 8px 0px",
      minWidth: "176px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "1px",
      margin: "0 auto",
      marginLeft: "5px",
      marginRight: "5px",
      marginBottom: "10px",
      textAlign: "center",
      borderRadius: "3px",
      overflow: "hidden",
      cursor: "pointer",
      textTransform: "uppercase",
      color: "#222222",
      border: "1px solid #0069E2",
    },
    "&:not(:checked) + label": {
      border: "1px solid #999999",
    },
    "&:not(:checked) + label img": {
      visibility: "hidden",
    },
    "&:checked + label": {
      backgroundColor: "transparent",
    },
    "&:checked + label::before,&:not(:checked) + label::before": {
      position: "absolute",
      content: "",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      borderRadius: "4px",
      backgroundImage: "linear-gradient(138deg, red, yellow)",
      zIndex: "-1",
    },
    "&:checked + label span,&:not(:checked) + label span": {
      position: "relative",
      display: "block",
    },
    "&:checked + label span::before,&:not(:checked) + label span::before": {
      position: "absolute",
      content: "attr(data-hover)",
      top: "0",
      left: "0",
      width: "100%",
      overflow: "hidden",
    },
    "&:not(:checked) + label span::before": {
      maxHeight: "0",
    },
    "&:checked + label span::before": {
      maxHeight: "100%",
    },
  },
  checkMark: {
    marginRight: "5px",
  },
  checkboxCoupon: {
    position: "relative",
    display: "inline-block",
    padding: "8px 14px 8px 0px",
    minWidth: "176px",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
    letterSpacing: "1px",
    margin: "0 auto",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
    textAlign: "center",
    borderRadius: "3px",
    overflow: "hidden",
    cursor: "pointer",
    textTransform: "uppercase",
    color: "#222222",
    border: "1px solid #0069E2",
    "&:not(:checked)": {
      border: "1px solid #999999",
    },
  },
}));

export default function FormRadio(props) {
  const classes = useStyles();
  const item = props.item;
  let selectedValue = props.value;
  const handleChange = (event) => {
    console.log("AAAA");
  };
  return (
    <Grid item xs={12} key={item.name}>
      <div>
        {item.items &&
          item.items.map((option) => (
            <span key={option.name}>
              <input
                className={classes.checkboxBudget}
                type="radio"
                name="feed"
                id={"filled-" + option.key}
                value={option.key}
                onChange={props.onChange(item.paramname)}
                checked={option.key === selectedValue}
              />
              <label
                className="for-checkboxBudget"
                htmlFor={"filled-" + option.key}
              >
                <span>
                  <img
                    src={CheckMark}
                    className={classes.checkMark}
                    alt="check"
                  />
                  {option.label}
                </span>
              </label>
            </span>
          ))}
      </div>
    </Grid>
  );
}
