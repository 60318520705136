// Palette
import palette from '../palette';

export default {
  root: {
    fontSize: '1.4rem',
    color: palette.common.black
  },
  underline: {
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid ' + palette.common.blue
    },
    '&:hover:after': {
      borderBottom: '2px solid ' + palette.common.blue
    }
  }
};
