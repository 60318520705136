/* eslint-disable no-script-url */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import palette from "../theme/palette";

import FormCheckbox from "./FormCheckbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import TemplateIcon from "@material-ui/icons/Folder";
import ContentIcon from "@material-ui/icons/TextFields";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
  },

  container: {
    height: "100%",
  },
  iconGridClass: {
    display: "flex",
    alignItems: "center",
  },
  depositContext: {
    margin: "3rem 0",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  inactiveIcon: {
    color: palette.text.primary,
  },
  cardListIconButtons: {
    width: 22,
    height: 22,
  },
  cardListIcon: {
    marginRight: "24px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSchedule: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 16,
    height: 16,
  },
  cardListActions: {
    [theme.breakpoints.down("xs")]: {
      padding: "12px 0",
    },
    borderBottom: "1px solid #C0C0C0",
  },
  downStats: {
    color: palette.error.main,
  },
  upStats: {
    color: palette.secondary.main,
  },
  textEllipse: {
    justifyContent: "flex-start",
    width: "100%",
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      padding: "3px 0",
    },
  },
  alRight: {
    textAlign: "right",
    color: "#222222",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: "2.4rem",
  },
  tblPad: {
    padding: "0 8px",
  },
  scheduleIcon: {
    textAlign: "right",
    paddingRight: "8px",
  },
  hubMargRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  textField: {
    margin: "0 10px 0 0",
    maxWidth: "40px",
    "& input": {
      padding: "12px 0px 10px",
      textAlign: "center",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.secondary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.text.primary,
      },
    },
  },
}))(MenuItem);

function ElementItemTippetips(props) {
  const classes = useStyles();

  let ef_h = props.couponMode === "folketips" ? props.folke_h : props.ekspert_h;
  let ef_u = props.couponMode === "folketips" ? props.folke_u : props.ekspert_u;
  let ef_b = props.couponMode === "folketips" ? props.folke_b : props.ekspert_b;

  let HDefault = false;
  let UDefault = false;
  let BDefault = false;

  let AutoHDefault = false;
  let AutoUDefault = false;
  let AutoBDefault = false;
  function parseHUB(sel) {
    if (sel === 1) {
      HDefault = true;
    }
    if (sel === 2) {
      UDefault = true;
    }
    if (sel === 4) {
      BDefault = true;
    }
    if (sel === 3) {
      HDefault = true;
      UDefault = true;
    }
    if (sel === 6) {
      UDefault = true;
      BDefault = true;
    }
    if (sel === 5) {
      HDefault = true;
      BDefault = true;
    }
    if (sel === 7) {
      HDefault = true;
      UDefault = true;
      BDefault = true;
    }
  }
  parseHUB(props.selection);

  const [isHChecked, setHChecked] = useState(HDefault);
  const [isUChecked, setUChecked] = useState(UDefault);
  const [isBChecked, setBChecked] = useState(BDefault);

  const [isAutoHChecked, setAutoHChecked] = useState(AutoHDefault);
  const [isAutoUChecked, setAutoUChecked] = useState(AutoUDefault);
  const [isAutoBChecked, setAutoBChecked] = useState(AutoBDefault);

  function handleHChange(event) {
    setHChecked(!isHChecked);
  }
  function handleUChange(event) {
    setUChecked(!isUChecked);
  }
  function handleBChange(event) {
    setBChecked(!isBChecked);
  }
  function convertToInt(s) {
    return parseInt(s, 10);
  }
  function parseHUBAuto(hodd, uodd, bodd) {
    let h = convertToInt(hodd);
    let u = convertToInt(uodd);
    let b = convertToInt(bodd);
    if (h > u + b || (h > u && h > b)) {
      updateHUB(1);
      return;
    }
    if (u > h + b || (u > h && u > b)) {
      updateHUB(2);
      return;
    }
    if (b > h + u || (b > h && b > u)) {
      updateHUB(4);
      return;
    }
    updateHUB(7);
    console.log(h, u, b);
    return;
  }

  function updateHUB(sel) {
    console.log("HUB", sel);
    if (sel === 1) {
      setAutoHChecked(true);
      setAutoUChecked(false);
      setAutoBChecked(false);
    }
    if (sel === 2) {
      setAutoHChecked(false);
      setAutoUChecked(true);
      setAutoBChecked(false);
    }
    if (sel === 4) {
      setAutoHChecked(false);
      setAutoUChecked(false);
      setAutoBChecked(true);
    }
    if (sel === 3) {
      setAutoHChecked(true);
      setAutoUChecked(true);
      setAutoBChecked(false);
    }
    if (sel === 6) {
      setAutoHChecked(false);
      setAutoUChecked(true);
      setAutoBChecked(true);
    }
    if (sel === 5) {
      setAutoHChecked(true);
      setAutoUChecked(false);
      setAutoBChecked(true);
    }
    if (sel === 7) {
      setAutoHChecked(true);
      setAutoUChecked(true);
      setAutoBChecked(true);
    }
  }

  function updateAutoHUB(sel) {
    console.log("HUB", sel);
    if (sel === 1) {
      setHChecked(true);
      setUChecked(false);
      setBChecked(false);
    }
    if (sel === 2) {
      setHChecked(false);
      setUChecked(true);
      setBChecked(false);
    }
    if (sel === 4) {
      setHChecked(false);
      setUChecked(false);
      setBChecked(true);
    }
    if (sel === 3) {
      setHChecked(true);
      setUChecked(true);
      setBChecked(false);
    }
    if (sel === 6) {
      setHChecked(false);
      setUChecked(true);
      setBChecked(true);
    }
    if (sel === 5) {
      setHChecked(true);
      setUChecked(false);
      setBChecked(true);
    }
    if (sel === 7) {
      setHChecked(true);
      setUChecked(true);
      setBChecked(true);
    }
  }

  useEffect(() => {
    var newselection = 0;
    if (isHChecked === true) {
      newselection += 1;
    }
    if (isUChecked === true) {
      newselection += 2;
    }
    if (isBChecked === true) {
      newselection += 4;
    }
    if (props.couponMode === "manuelt") {
      props.updateSelection(newselection, props.index);
    }
  }, [isBChecked, isUChecked, isHChecked]);

  useEffect(() => {
    if (props.couponMode === "folketips") {
      // parseHUBAuto(props.folke_h, props.folke_u, props.folke_b);
    } else if (props.couponMode === "eksperttips") {
      // parseHUBAuto(props.ekspert_h, props.ekspert_u, props.ekspert_b);
    } else {
      console.log("AAAA", props.couponMode, props.couponSelection);
      updateHUB(props.couponMode, props.couponSelection);
    }
  }, [props.couponMode, props.origselection]);

  function getHChecked() {
    if (props.couponMode === "manuelt") {
      return isHChecked;
    } else {
      return isAutoHChecked;
    }
  }
  function getUChecked() {
    if (props.couponMode === "manuelt") {
      return isUChecked;
    } else {
      return isAutoUChecked;
    }
  }
  function getBChecked() {
    if (props.couponMode === "manuelt") {
      return isBChecked;
    } else {
      return isAutoBChecked;
    }
  }

  function handleClick() {}

  return (
    <Grid container className={classes.cardListActions}>
      <Grid
        item
        xs={6}
        sm={6}
        onClick={handleClick}
        className={classes.iconGridClass}
      >
        <Grid container>
          <Grid item xs={2}>
            <IconButton
              disableRipple
              disableFocusRipple
              aria-label="Share"
              className={clsx(classes.button, classes.textEllipse)}
            >
              {props.index + 1}
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <IconButton
              disableRipple
              disableFocusRipple
              aria-label="Share"
              className={clsx(classes.button, classes.textEllipse)}
            >
              <Typography variant="subtitle2" noWrap>
                {props.name}
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
        onClick={handleClick}
        zeroMinWidth
        className={classes.iconGridClass}
      >
        <Grid container>
          <Grid item xs={3}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              key={"b" + props.index}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getHChecked()}
                      onChange={handleHChange}
                      value={false}
                      disabled={props.couponMode === "manuelt" ? false : true}
                    />
                  }
                  label={""}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              key={"b" + props.index}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getUChecked()}
                      onChange={handleUChange}
                      value={false}
                      disabled={props.couponMode === "manuelt" ? false : true}
                    />
                  }
                  label={""}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              key={"b" + props.index}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getBChecked()}
                      onChange={handleBChange}
                      value={false}
                      disabled={props.couponMode === "manuelt" ? false : true}
                    />
                  }
                  label={""}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        onClick={handleClick}
        className={classes.iconGridClass}
      >
        <Grid container spacing={0} className={classes.container}>
          <Grid
            item
            xs={9}
            className={clsx(classes.hubMargRight, classes.inactiveIcon)}
          >
            <TextField
              id={"filled-hp" + props.index}
              label={""}
              className={classes.textField}
              value={ef_h}
              onChange={handleClick}
              margin="normal"
              variant="filled"
              disabled={false}
            />
            <TextField
              id={"filled-up" + props.index}
              label={""}
              className={classes.textField}
              value={ef_u}
              onChange={handleClick}
              margin="normal"
              variant="filled"
              disabled={false}
            />

            <TextField
              id={"filled-bp" + props.index}
              label={""}
              className={classes.textField}
              value={ef_b}
              onChange={handleClick}
              margin="normal"
              variant="filled"
              disabled={false}
            />
          </Grid>
          <Grid item xs={3} className={classes.iconGridClass}>
            = 100%
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(ElementItemTippetips);
