/* eslint-disable no-script-url */

import React, { Fragment } from "react";

import ElementItemCollections from "./ElementItemCollections";
import StatsContext from "../../../context/StatsContext";

export default function TableLinks(props) {
  const rows = props.rows;
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;

  return (
    <Fragment>
      {rows.map((row, index) => {
        const statObj = row.stats && row.stats[statsInterval];

        let sClicks = statObj ? statObj.clicks : 0;
        let sClicksTrend = statObj ? statObj.clicks_trend : "";

        let displays = statObj ? statObj.views : 0;
        let displays_trend = statObj ? statObj.views_trend : "";
        let ctr = statObj ? statObj.ctr : 0;
        let ctr_trend = statObj ? statObj.ctr_trend : "";

        sClicks = sClicks ? sClicks : 0;
        sClicksTrend = sClicksTrend ? sClicksTrend : 0;
        displays = displays ? displays : 0;
        displays_trend = displays_trend ? displays_trend : 0;
        ctr = ctr ? ctr : 0;
        ctr_trend = ctr_trend ? ctr_trend : 0;

        const numOfWidgets =
          row.content.length !== 1
            ? row.content.length + " elementer"
            : row.content.length + " element";
        return (
          <ElementItemCollections
            key={index}
            id={row.id}
            numOfWidgets={numOfWidgets}
            clicks={sClicks}
            clicks_trend={sClicksTrend}
            displays={displays}
            displays_trend={displays_trend}
            ctr={ctr}
            ctr_trend={ctr_trend}
            title={row.name}
            name={row.name}
            handleDelete={props.handleDelete}
            handleOpenDialog={props.handleOpenDialog}
            setLinkItem={props.setLinksData}
            updateItemTitle={props.updateItemTitle}
            handleUpdateTitle={props.handleUpdateTitle}
          />
        );
      })}
    </Fragment>
  );
}
